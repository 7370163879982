import { Box, Grid, GridItem, Text } from '@chakra-ui/react';

import { ErrorBoundary } from '@sentry/react';
import FileIcon from '../../components/FileIcon.js';
import ReactMarkdown from 'react-markdown';
import RenderError from '../../components/RenderError.js';
import { ResultV1V2Abstraction } from '../../utils/resultsV1V2Conversion.js';
import { decodeName } from 'common-ts';
import { fetchApi } from '../../utils/useApi.js';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import { useBoundStore } from '../../store/useBoundStore.js';

type SearchResultProps = {
  result: ResultV1V2Abstraction;
  iFrameId: string | null;
};

export default function SearchResult({ result, iFrameId }: SearchResultProps) {
  const supabase = useBoundStore((state) => state.supabase);

  async function handlePresignedUrl() {
    // TODO: This is temporary and needs to be changed once the sharepoint integration is done
    let bucketId: string | undefined;

    if (result.fileId) {
      const { data: bucketRes } = await supabase
        .from('file_info')
        .select('bucket_id')
        .eq('file_id', result.fileId);

      bucketId = bucketRes?.[0]?.bucket_id;
    }
    if (result.documentId) {
      const { data: bucketRes } = await supabase
        .from('document_view')
        .select('bucket_id')
        .eq('document_id', result.documentId);

      bucketId = bucketRes?.[0]?.bucket_id ?? undefined;
    }

    if (!bucketId) {
      return;
    }

    if (iFrameId) {
      const { data } = await fetchApi(
        supabase,
        '/iframe',
        '/presigned_url',
        {
          bucketId,
          filename: result.filename,
          iFrameId,
          method: 'POST',
        },
        true
      );
      if (data && data.signedUrl) {
        window.open(data.signedUrl, '_blank');
      }
      return;
    }
    if (!result.filename) return;
    const { data, error } = await supabase.storage
      .from(bucketId)
      .createSignedUrl(result.filename, 60);
    if (error) {
      console.error('Error generating URL:', error);
    } else if (data.signedUrl) {
      window.open(data.signedUrl, '_blank');
    }
  }

  return (
    <Grid
      templateRows={['repeat(5, auto)', null, 'repeat(2, auto)']}
      templateColumns={['repeat(1, 1fr)', null, 'repeat(5, 1fr)']}
      gap={[0, 0, 4]}
      className="border-maia-border border-b"
    >
      <GridItem
        rowSpan={[1, 1, 2]}
        colSpan={1}
        gridRowStart={1}
        gridColumnStart={1}
      >
        <Box
          className={`transition-translate flex h-full w-full cursor-pointer items-center justify-center p-4 duration-200 hover:-translate-y-2 lg:p-4`}
          color={'teal.700'}
          onClick={handlePresignedUrl}
        >
          <div className="flex flex-col items-center">
            <FileIcon
              name={result.filename}
              className="text-chakra-gray-500 fill-current p-3 text-xl"
            />
            <Text
              className="text-maia-text-dark font-medium"
              noOfLines={3}
              maxW={[null, null, 28]}
            >
              {decodeName(result.filename)}
            </Text>
          </div>
        </Box>
      </GridItem>
      <GridItem
        className="min-w-0"
        rowSpan={[9, 9, 2]}
        colSpan={[1, 1, 4]}
        gridRowStart={[2, 2, 1]}
        gridColumnStart={[1, 1, 2]}
      >
        <ErrorBoundary fallback={<RenderError content={result.content} />}>
          <ReactMarkdown
            className="prose text-maia-text-dark min-w-0 max-w-none break-words p-4 text-sm"
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[rehypeRaw as any]}
          >
            {result.content}
          </ReactMarkdown>
        </ErrorBoundary>
      </GridItem>
    </Grid>
  );
}
