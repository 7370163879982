import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormLabel,
  FormLabelProps,
  IconButton,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  useDisclosure,
  useMergeRefs,
} from '@chakra-ui/react';
import { MutableRefObject, useRef } from 'react';
import { faEye, faEyeLowVision } from '@fortawesome/pro-light-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type PasswordFieldProps = {
  props?: FormControlProps;
  fieldHeader: string;
  formLabelProps?: FormLabelProps;
  inputProps?: InputProps;
  inputRef?: MutableRefObject<HTMLInputElement | undefined>;
  errorMessage?: string;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  tabIndex?: number;
};

function PasswordField(props: PasswordFieldProps) {
  const { isOpen, onToggle } = useDisclosure();
  const inputRef = useRef<HTMLInputElement>(null);

  const mergeRef = useMergeRefs(inputRef, props.inputRef);
  const onClickReveal = () => {
    onToggle();
    if (inputRef.current) {
      inputRef.current.focus({ preventScroll: true });
    }
  };

  return (
    <FormControl {...props.props}>
      <FormLabel htmlFor="password" {...props.formLabelProps}>
        {props.fieldHeader}
      </FormLabel>
      <InputGroup>
        <InputRightElement>
          <IconButton
            variant="text"
            aria-label={isOpen ? 'Mask password' : 'Reveal password'}
            icon={
              isOpen ? (
                <FontAwesomeIcon icon={faEye} />
              ) : (
                <FontAwesomeIcon icon={faEyeLowVision} />
              )
            }
            onClick={onClickReveal}
          />
        </InputRightElement>
        <Input
          id="password"
          ref={mergeRef}
          name="password"
          type={isOpen ? 'text' : 'password'}
          autoComplete="current-password"
          required
          onKeyDown={props.onKeyDown}
          tabIndex={props.tabIndex}
          {...props.inputProps}
        />
      </InputGroup>
      {props.errorMessage && (
        <FormErrorMessage>{props.errorMessage}</FormErrorMessage>
      )}
    </FormControl>
  );
}

export default PasswordField;
