import {
  Button,
  Divider,
  HStack,
  IconButton,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  PinInput,
  PinInputField,
  Skeleton,
  Tooltip,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { captureException } from '@sentry/react';
import { faClone } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import { useToastManagerHook } from '../../../../general/useToastManagerHook';
import { useBoundStore } from '../../../../store/useBoundStore.js';

type TotpModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

type Totp = {
  qr_code: string;
  secret: string;
  uri: string;
};

export default function TotpModal({ isOpen, onClose }: TotpModalProps) {
  const { t } = useTranslation();
  const supabase = useBoundStore((state) => state.supabase);
  const { showToast } = useToastManagerHook();

  const confirmButtonRef = useRef<HTMLButtonElement>(null);

  const [factorId, setFactorId] = useState<string>('');
  const [totp, setTotp] = useState<Totp>();
  const [code, setCode] = useState<string>('');
  const [copyTooltipString, setCopyTooltipString] = useState(t('general.copy'));

  const handleCopy = () => {
    if (!totp?.secret) return;

    navigator.clipboard.writeText(totp.secret);

    setCopyTooltipString(t('general.copied'));
    setTimeout(() => setCopyTooltipString(t('general.copy')), 1000);
  };
  const setup2FA = async () => {
    const { data, error } = await supabase.auth.mfa.enroll({
      factorType: 'totp',
      friendlyName: 'MAIA',
    });

    if (error) {
      console.error(error.message);
      return;
    }

    // Use the id to create a challenge.
    // The challenge can be verified by entering the code generated from the authenticator app.
    // The code will be generated upon scanning the qr_code or entering the secret into the authenticator app.
    const { id, totp } = data;

    setFactorId(id);
    setTotp(totp);
  };
  const cancel2FASetup = async () => {
    if (!factorId) return;
    const { error } = await supabase.auth.mfa.unenroll({
      factorId,
    });
    if (error) {
      console.error(error.message);
      return;
    }
  };
  const handleModalClose = async () => {
    await cancel2FASetup();
    onClose();
  };
  const handle2FASetupConfirmation = async () => {
    const { data, error } = await supabase.auth.mfa.challengeAndVerify({
      factorId,
      code,
    });
    if (error || !data) {
      if (error.status === 400) {
        showToast({
          title: t('userSettings.security.invalidCodeError'),
          status: 'error',
        });
      } else {
        captureException(error);
        showToast({
          title: t('userSettings.security.totpModal.error'),
          status: 'error',
        });
        handleModalClose();
      }
      return;
    } else {
      showToast({
        title: t('userSettings.security.totpModal.success'),
        status: 'success',
      });
      onClose();
    }
  };

  useEffect(() => {
    setup2FA();

    // workaround for pin input enter keypress
    // pin input does not have a onKeyPress/up/down event
    const handleEnterKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && confirmButtonRef.current) {
        confirmButtonRef.current.click();
      }
    };
    document.addEventListener('keypress', handleEnterKeyPress);
    return () => {
      document.removeEventListener('keypress', handleEnterKeyPress);
    };
  }, []);

  return (
    <Modal isOpen={isOpen} onClose={handleModalClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <div className="font-medium">
            {t('userSettings.security.totpModal.title')}
          </div>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody className="flex w-full flex-col items-center gap-y-4">
          <div className="mb-4">
            {t('userSettings.security.totpModal.subtitle')}
          </div>
          <Skeleton
            isLoaded={!!totp?.qr_code}
            startColor="maia-gray.300"
            endColor="maia-gray.400"
          >
            <Image boxSize="100px" src={totp?.qr_code} alt="qr code" />
          </Skeleton>

          <div className="flex w-full flex-col items-center gap-y-2">
            <span className="text-xs font-medium">
              {t(
                'userSettings.security.totpModal.enterAuthenticationCodePrompt'
              )}
            </span>
            <HStack>
              <PinInput
                variant={'flushed'}
                focusBorderColor="maia-purple.500"
                onChange={(code) => {
                  setCode(code);
                }}
                otp
                autoFocus
              >
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
              </PinInput>
            </HStack>
          </div>
          <div className="bg-maia-purple-100 flex w-full flex-col items-center gap-y-2 rounded-md p-4 font-medium">
            <div>
              <span className="mr-1">
                {t('userSettings.security.totpModal.scanNotWorking')}
              </span>
              <span className="font-normal">
                {t('userSettings.security.totpModal.enterManually')}
              </span>
            </div>
            <div className="flex items-center gap-x-1">
              <Skeleton
                isLoaded={!!totp?.secret}
                startColor="maia-gray.300"
                endColor="maia-gray.400"
              >
                <span>{totp?.secret}</span>
              </Skeleton>
              {totp?.secret && (
                <Tooltip
                  label={copyTooltipString}
                  closeDelay={500}
                  fontSize={'xs'}
                  placement="top"
                >
                  <IconButton
                    variant={'ghost'}
                    size={'xs'}
                    aria-label={t('general.copy')}
                    className="hover:bg-maia-purple-200 text-maia-gray-600 hover:text-maia-gray-700"
                    icon={
                      <FontAwesomeIcon
                        icon={faClone}
                        className="fill-current"
                      />
                    }
                    onClick={handleCopy}
                  />
                </Tooltip>
              )}
            </div>
          </div>
        </ModalBody>
        <Divider />
        <ModalFooter gap={2}>
          <Button
            size={'sm'}
            className="font-medium"
            variant="outline"
            onClick={handleModalClose}
          >
            {t('userSettings.security.totpModal.secondaryButton')}
          </Button>
          <Button
            ref={confirmButtonRef}
            colorScheme="maia-purple"
            size={'sm'}
            onClick={handle2FASetupConfirmation}
            isDisabled={code.length < 6}
          >
            {t('userSettings.security.totpModal.primaryButton')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
