import './i18n';
import './index.css';

import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import React, { Suspense } from 'react';

import App from './App.js';
import ReactDOM from 'react-dom/client';
import { themeExtension } from './styles/chakraThemeExtension.js';

const theme = extendTheme(themeExtension);

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <Suspense fallback={<div>Loading...</div>}>
        <App />
      </Suspense>
    </ChakraProvider>
  </React.StrictMode>
);
