import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
} from '@chakra-ui/react';
import { t } from 'i18next';

export type ConformDeleteFileModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  fileCount: number;
};

/**
 * ConfirmDeleteFileModal displays a modal asking for confirmation to delete files.
 *
 * @param isOpen - Determines if the modal is open.
 * @param onClose - Function to call when closing the modal.
 * @param onConfirm - Function to call when confirmation is made.
 * @param fileCount - Number of files to be deleted.
 */
export const ConfirmDeleteFileModal = ({
  isOpen,
  onClose,
  onConfirm,
  fileCount,
}: ConformDeleteFileModalProps) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>{t('deleteFileModal.title')}</ModalHeader>
      <ModalBody>
        {fileCount > 1
          ? t('deleteFileModal.confirmTextMultipleFiles', {
              fileCount,
            })
          : t('deleteFileModal.confirmTextSingleFile')}
      </ModalBody>
      <ModalFooter>
        <div className="flex items-center gap-2">
          <Button
            className="text-maia-text-dark font-medium"
            onClick={onClose}
            variant="outline"
          >
            {t('general.cancelButton')}
          </Button>
          <Button
            className="bg-maia-support-red text-maia-text-light font-medium"
            onClick={onConfirm}
          >
            {t('general.deleteButton')}
          </Button>
        </div>
      </ModalFooter>
    </ModalContent>
  </Modal>
);
