import {
  Alert,
  AlertIcon,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from '@chakra-ui/react';
import { ValidationError, object, ref, string } from 'yup';
import { useMemo, useRef, useState } from 'react';

import { USER_SETTINGS_TOAST_ID } from './userSettingsToastId.js';
import { captureException } from '@sentry/react';
import { useTranslation } from 'react-i18next';
import { useToastManagerHook } from '../../general/useToastManagerHook.js';
import { useBoundStore } from '../../store/useBoundStore.js';

type FormError = {
  email?: string;
  repeatEmail?: string;
};

type UpdateEmailProps = {
  onClose: () => void;
};

function UpdateEmail({ onClose }: UpdateEmailProps) {
  const { t } = useTranslation();
  const userInfo = useBoundStore((state) => state.userInfo);
  const supabase = useBoundStore((state) => state.supabase);
  const { showToast } = useToastManagerHook();

  const email = useRef(userInfo?.email ?? '');
  const repeatEmail = useRef('');
  const [formError, setFormError] = useState<FormError>();
  const [loading, setLoading] = useState(false);

  const formSchema = useMemo(() => {
    return object().shape({
      email: string()
        .required(`${t('loginView.emptyMail')}`)
        .email(`${t('loginView.mailNotValid')}`),
      repeatEmail: string()
        .required(`${t('loginView.emptyMail')}`)
        .email(`${t('loginView.mailNotValid')}`)
        .oneOf([ref('email')], t('userSettings.emailMismatch')),
    });
  }, [t]);

  async function handleSave() {
    setLoading(true);

    try {
      await formSchema.validate(
        {
          email: email.current,
          repeatEmail: repeatEmail.current,
        },
        {
          abortEarly: false,
        }
      );
    } catch (error) {
      if (error instanceof ValidationError) {
        const formError: FormError = {};
        error.inner.forEach((element) => {
          formError[element.path as keyof FormError] = element.message;
        });
        setFormError(formError);
        setLoading(false);
        return;
      }
    }

    const updatedUserData = {
      email: email.current,
    };

    if (!userInfo?.userId) return;

    try {
      const result = await supabase?.auth.updateUser(updatedUserData);

      if (result.error) {
        throw new Error(result.error.message);
      }
      showToast({
        id: USER_SETTINGS_TOAST_ID,
        title: t('userSettings.updateMailSuccess'),
        status: 'success',
      });
    } catch (err) {
      if (err instanceof Error) {
        captureException(err);
        showToast({
          id: USER_SETTINGS_TOAST_ID,
          title: t('userSettings.saveError'),
          description: err.message,
          status: 'error',
        });
      }
    }

    setLoading(false);
    onClose();
  }

  return (
    <div className="flex flex-col gap-4">
      <Alert status="info" variant="left-accent">
        <AlertIcon />
        {t('userSettings.updateMailInfo')}
      </Alert>
      <FormControl isInvalid={!!formError?.email}>
        <div>
          <FormLabel>{t('userSettings.email')}</FormLabel>
          <Input
            type="email"
            backgroundColor={'white'}
            placeholder={t('userSettings.email')}
            defaultValue={email.current}
            onChange={(e) => {
              email.current = e.target.value;
            }}
          />
        </div>
        {!!formError?.email && (
          <FormErrorMessage>{formError.email}</FormErrorMessage>
        )}
      </FormControl>
      <FormControl isInvalid={!!formError?.repeatEmail}>
        <div>
          <FormLabel>{t('userSettings.repeatEmail')}</FormLabel>
          <Input
            type="email"
            backgroundColor={'white'}
            placeholder={t('userSettings.email')}
            onChange={(e) => {
              repeatEmail.current = e.target.value;
            }}
          />
        </div>
        {!!formError?.repeatEmail && (
          <FormErrorMessage>{formError.repeatEmail}</FormErrorMessage>
        )}
      </FormControl>
      <div className="flex justify-end gap-2">
        <Button
          size="sm"
          variant="outline"
          onClick={onClose}
          isLoading={loading}
        >
          {t('userSettings.auth.cancel')}
        </Button>
        <Button
          size="sm"
          colorScheme="maia-purple"
          onClick={handleSave}
          isLoading={loading}
        >
          {t('userSettings.saveButton')}
        </Button>
      </div>
    </div>
  );
}

export default UpdateEmail;
