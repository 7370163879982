export var AvailableModelEnum;
(function (AvailableModelEnum) {
    AvailableModelEnum["GPT_3_5_TURBO_16K"] = "gpt-3.5-turbo-16k";
    AvailableModelEnum["GPT_4"] = "gpt-4";
})(AvailableModelEnum || (AvailableModelEnum = {}));
const availableModelForPlan = new Map([
    ['FREE', []],
    ['BASIC', [AvailableModelEnum.GPT_3_5_TURBO_16K, AvailableModelEnum.GPT_4]],
    [
        'PROFESSIONAL',
        [AvailableModelEnum.GPT_3_5_TURBO_16K, AvailableModelEnum.GPT_4],
    ],
    [
        'ENTERPRISE',
        [AvailableModelEnum.GPT_3_5_TURBO_16K, AvailableModelEnum.GPT_4],
    ],
]);
export function isModelAvailableForPlan(plan, model) {
    const allowedModels = availableModelForPlan.get(plan);
    if (!allowedModels) {
        return false;
    }
    return allowedModels.includes(model);
}
