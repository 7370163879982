type LogoPureProps = {
  size?: number;
  className?: string;
};

function LogoPure({ size, className }: LogoPureProps) {
  return (
    <svg
      className={className}
      style={{ height: size }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 479.04 256.66"
    >
      <g>
        <g>
          <path d="m87.49,4.61L1.18,156.87c-1.58,2.79-1.58,6.22.02,9l49.44,86.22c3.5,6.11,12.32,6.09,15.79-.04L201.62,13.57c3.43-6.06-.94-13.57-7.91-13.57h-98.32c-3.27,0-6.29,1.76-7.91,4.61Z" />
          <path d="m259.42,4.61l-86.31,152.26c-1.58,2.79-1.58,6.22.02,9l49.44,86.22c3.5,6.11,12.32,6.09,15.79-.04L373.55,13.57c3.43-6.06-.94-13.57-7.91-13.57h-98.32c-3.27,0-6.29,1.76-7.91,4.61Z" />
          <path d="m336.62,118.01l75.98,134.05c3.47,6.12,12.29,6.15,15.79.04l49.44-86.22c1.6-2.79,1.61-6.21.02-9l-27.11-47.82c-1.61-2.85-4.63-4.61-7.91-4.61h-98.32c-6.96,0-11.34,7.51-7.91,13.57Z" />
        </g>
      </g>
    </svg>
  );
}

export default LogoPure;
