import { Text, TextProps } from '@chakra-ui/react';

import { twMerge } from 'tailwind-merge';

type SettingsSectionProps = TextProps;

function SettingsSection(props: SettingsSectionProps) {
  const { className, ...rest } = props;

  return (
    <Text className={twMerge('text-md font-semibold', className)} {...rest} />
  );
}

export default SettingsSection;
