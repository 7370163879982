import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Link,
  Text,
} from '@chakra-ui/react';
import { ValidationError, boolean, object, ref, string } from 'yup';
import { useEffect, useMemo, useRef, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PasswordField from '../../components/PasswordField.js';
import { ROUTES } from 'common-ts';
import { captureException } from '@sentry/react';
import { faPaperPlane } from '@fortawesome/pro-duotone-svg-icons';
import { t } from 'i18next';
import { useBoundStore } from '../../store/useBoundStore.js';

type RegisterError = {
  email?: string;
  newPassword?: string;
  repeatPassword?: string;
  general?: string;
  agreeToTerms?: string;
};

type SignupFormProps = {
  redirectToSignin: () => void;
};

export default function SignupForm({ redirectToSignin }: SignupFormProps) {
  const supaBaseClient = useBoundStore((state) => state.supabase);

  const regEmail = useRef('');
  const newPassword = useRef('');
  const repeatPassword = useRef('');
  const [agreeToTerms, setAgreeToTerms] = useState(false);

  const [registerError, setRegisterError] = useState<RegisterError>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [showVerificationPrompt, setShowVerificationPrompt] = useState(false);

  const registerSchema = useMemo(() => {
    return object().shape({
      email: string()
        .required(`${t('loginView.emptyMail')}`)
        .email(`${t('loginView.mailNotValid')}`), // optional and should be valid email if provided
      newPassword: string()
        .required(`${t('loginView.emptyPw')}`)
        .min(8, `${t('loginView.passwordTooShortError')}`), // optional and no specific validation if provided
      repeatPassword: string().oneOf(
        [ref('newPassword')],
        `${t('loginView.passwordMustMatchError')}`
      ),
      agreeToTerms: boolean().isTrue(`${t('loginView.agreeToTermsError')}`),
    });
  }, [t]);

  async function handleRegister(
    email: string,
    newPassword: string,
    repeatPassword: string
  ) {
    setLoading(true);
    setRegisterError({});
    try {
      await registerSchema.validate(
        {
          email,
          newPassword,
          repeatPassword,
          agreeToTerms,
        },
        {
          abortEarly: false,
        }
      );
    } catch (error) {
      if (error instanceof ValidationError) {
        const regErr: RegisterError = {};
        error.inner.forEach((element) => {
          regErr[element.path as keyof RegisterError] = element.message;
        });
        setRegisterError(regErr);
        setLoading(false);
        return;
      }
    }
    if (!supaBaseClient) {
      setRegisterError({
        general: `${t(`loginView.clientError`)}`,
      });
    } else {
      try {
        const registerResponse = await supaBaseClient.auth.signUp({
          email,
          password: newPassword,
          options: {
            emailRedirectTo: `${
              import.meta.env.VITE_MAIA_APP_PUBLIC_URL
            }${ROUTES.AUTH.SIGN_IN.buildPath({}, { emailConfirmed: true })}`,
          },
        });

        if (registerResponse.error) {
          // TODO: Find a better way to handle various errors
          //  But therefore research has to happen to identify the important once
          if (
            registerResponse.error.message
              .toLowerCase()
              .includes('sign up with domain is blocked')
          ) {
            setRegisterError({
              general: t('loginView.domainBlocked'),
            });
          } else {
            setRegisterError({
              general: registerResponse.error.message,
            });
          }
          setLoading(false);
          return;
        }

        supaBaseClient.auth.startAutoRefresh();

        setShowVerificationPrompt(true);
      } catch (error) {
        captureException(error);
        setRegisterError({
          general: `${t('general.reloadError')}`,
        });
      }
    }
    setLoading(false);
  }

  useEffect(() => {
    if (showVerificationPrompt) {
      setTimeout(() => {
        setShowVerificationPrompt(false);
        redirectToSignin();
      }, 10000);
    }
  }, [showVerificationPrompt]);
  return (
    <div>
      {showVerificationPrompt ? (
        <div className="my-8 flex flex-col items-center space-y-8 px-8">
          <Text fontSize={'2xl'}>
            {t('loginView.emailVerificationPrompt.title')}
          </Text>
          <Text color={'blue.400'}>
            <FontAwesomeIcon
              className="h-16 w-16 stroke-current"
              icon={faPaperPlane}
            />
          </Text>
          <Text
            className="text-center"
            onClick={() => setShowVerificationPrompt((prev) => !prev)}
          >
            {t('loginView.emailVerificationPrompt.text')}
          </Text>
        </div>
      ) : (
        <>
          {registerError.general && (
            <Alert status="error">
              <AlertIcon />
              <AlertDescription>{registerError.general}</AlertDescription>
            </Alert>
          )}
          <FormControl isInvalid={!!registerError.email}>
            <FormLabel htmlFor="email">Email</FormLabel>
            <Input
              id="email"
              type="email"
              onChange={(v) => (regEmail.current = v.currentTarget.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleRegister(
                    regEmail.current,
                    newPassword.current,
                    repeatPassword.current
                  );
                }
              }}
              tabIndex={1}
            />
            {registerError.email && (
              <FormErrorMessage>{t('loginView.emptyMail')}</FormErrorMessage>
            )}
          </FormControl>
          <PasswordField
            props={{
              className: 'pt-2',
              isInvalid: !!registerError.newPassword,
            }}
            errorMessage={registerError.newPassword}
            fieldHeader={t('loginView.password')}
            inputProps={{
              onChange: (v) => (newPassword.current = v.currentTarget.value),
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleRegister(
                  regEmail.current,
                  newPassword.current,
                  repeatPassword.current
                );
              }
            }}
            tabIndex={2}
          />
          <PasswordField
            props={{
              className: 'pt-2',
              isInvalid: !!registerError.repeatPassword,
            }}
            errorMessage={registerError.repeatPassword}
            fieldHeader={t('loginView.repeatPw')}
            inputProps={{
              onChange: (v) => (repeatPassword.current = v.currentTarget.value),
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleRegister(
                  regEmail.current,
                  newPassword.current,
                  repeatPassword.current
                );
              }
            }}
            tabIndex={3}
          />
          <FormControl isInvalid={!!registerError.agreeToTerms}>
            <Checkbox
              className="pt-6"
              size="sm"
              onChange={(e) => setAgreeToTerms(e.target.checked)}
              tabIndex={4}
            >
              <span>{t('loginView.agreeToMaiasTerms')} </span>
              <Link
                href="https://www.iubenda.com/nutzungsbedingungen/26153837"
                color="blue.500"
                isExternal
              >
                {t('loginView.termsLinkText')}
              </Link>
            </Checkbox>
            {registerError.agreeToTerms && (
              <FormErrorMessage>{registerError.agreeToTerms}</FormErrorMessage>
            )}
          </FormControl>
          <div className="flex items-center justify-between pt-6">
            <div />
            {loading ? (
              <CircularProgress isIndeterminate />
            ) : (
              <Button
                className="bg-maia-accent text-maia-text-light"
                onClick={() =>
                  handleRegister(
                    regEmail.current,
                    newPassword.current,
                    repeatPassword.current
                  )
                }
                tabIndex={5}
              >
                {t('loginView.register')}
              </Button>
            )}
          </div>
        </>
      )}
    </div>
  );
}
