import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';

import { ROUTES } from 'common-ts';
import SigninForm from './SigninForm.js';
import SignupForm from './SignupForm.js';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTypedSearchParams } from 'react-router-typesafe-routes/dom';

export enum LoginViewTabEnum {
  LOGIN = 0,
  REGISTER = 1,
}

type LoginViewProps = {
  defaultTab?: LoginViewTabEnum;
};

function LoginView({ defaultTab }: LoginViewProps) {
  const [searchParams] = useTypedSearchParams(ROUTES.AUTH.SIGN_IN);
  const { t } = useTranslation();
  const [index, setIndex] = useState(defaultTab ?? 0);
  const navigate = useNavigate();
  return (
    <>
      <Tabs
        className="w-full"
        index={index}
        onChange={(index) => {
          setIndex(index);
          navigate(
            index === LoginViewTabEnum.LOGIN
              ? ROUTES.AUTH.SIGN_IN.buildPath(
                  {},
                  searchParams.inviteCode
                    ? { inviteCode: searchParams.inviteCode }
                    : undefined
                )
              : ROUTES.AUTH.SIGN_UP.buildPath(
                  {},
                  searchParams.inviteCode
                    ? { inviteCode: searchParams.inviteCode }
                    : undefined
                )
          );
        }}
      >
        <TabList>
          <Tab className="text-sm">{t('loginView.login')}</Tab>
          <Tab className="text-sm">{t('loginView.register')}</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <SigninForm />
          </TabPanel>
          <TabPanel>
            <SignupForm redirectToSignin={() => setIndex(0)} />
          </TabPanel>
        </TabPanels>
      </Tabs>
      {/* <LoginWithSingleSignOn />*/}
    </>
  );
}

export default LoginView;
