import { Button } from '@chakra-ui/react';
import { ValidationError, object, ref, string } from 'yup';
import { useMemo, useRef, useState } from 'react';

import PasswordField from '../../components/PasswordField.js';
import { USER_SETTINGS_TOAST_ID } from './userSettingsToastId.js';
import { captureException } from '@sentry/react';
import { useTranslation } from 'react-i18next';
import { useToastManagerHook } from '../../general/useToastManagerHook.js';
import { useBoundStore } from '../../store/useBoundStore.js';

type FormError = {
  password?: string;
  repeatpassword?: string;
};

type UpdatePasswordProps = {
  onClose: () => void;
};

function UpdatePassword({ onClose }: UpdatePasswordProps) {
  const { t } = useTranslation();
  const userInfo = useBoundStore((state) => state.userInfo);
  const supabase = useBoundStore((state) => state.supabase);
  const { showToast } = useToastManagerHook();

  const password = useRef('');
  const repeatpassword = useRef('');
  const [formError, setFormError] = useState<FormError>();
  const [loading, setLoading] = useState(false);

  const formSchema = useMemo(() => {
    return object().shape({
      password: string()
        .required(`${t('loginView.emptyPw')}`)
        .min(8, `${t('loginView.passwordTooShortError')}`),
      repeatpassword: string().oneOf(
        [ref('password')],
        `${t('loginView.passwordMustMatchError')}`
      ),
    });
  }, [t]);

  async function handleSave() {
    setLoading(true);

    try {
      await formSchema.validate(
        {
          password: password.current,
          repeatpassword: repeatpassword.current,
        },
        {
          abortEarly: false,
        }
      );
    } catch (error) {
      if (error instanceof ValidationError) {
        const formError: FormError = {};
        error.inner.forEach((element) => {
          formError[element.path as keyof FormError] = element.message;
        });
        setFormError(formError);
        setLoading(false);
        return;
      }
    }

    const updatedUserData = {
      password: password.current,
    };

    if (!userInfo?.userId) return;

    try {
      const result = await supabase?.auth.updateUser(updatedUserData);

      if (result.error) {
        throw new Error(result.error.message);
      }
      showToast({
        id: USER_SETTINGS_TOAST_ID,
        title: t('userSettings.saveSuccess'),
        status: 'success',
      });
    } catch (err) {
      if (err instanceof Error) {
        captureException(err);
        showToast({
          id: USER_SETTINGS_TOAST_ID,
          title: t('userSettings.saveError'),
          description: err.message,
          status: 'error',
        });
      }
    }

    setLoading(false);
    onClose();
  }

  return (
    <div className="flex flex-col gap-4">
      <PasswordField
        props={{ isInvalid: !!formError?.password }}
        fieldHeader={t('userSettings.password')}
        formLabelProps={{ className: 'text-maia-gray-700' }}
        inputProps={{
          backgroundColor: 'white',
          placeholder: t('userSettings.password'),
          defaultValue: password.current,
          onChange: (e) => {
            password.current = e.target.value;
          },
          autoFocus: true,
        }}
        errorMessage={formError?.password}
      />
      <PasswordField
        props={{ isInvalid: !!formError?.repeatpassword }}
        fieldHeader={t('userSettings.password')}
        formLabelProps={{ className: 'text-maia-gray-700' }}
        inputProps={{
          backgroundColor: 'white',
          placeholder: t('userSettings.password'),
          defaultValue: repeatpassword.current,
          onChange: (e) => {
            repeatpassword.current = e.target.value;
          },
        }}
        errorMessage={formError?.repeatpassword}
      />
      <div className="flex justify-end gap-2">
        <Button
          size="sm"
          variant="outline"
          className="text-maia-text-dark"
          onClick={onClose}
          isLoading={loading}
        >
          {t('userSettings.auth.cancel')}
        </Button>
        <Button
          size="sm"
          className="text-maia-text-light"
          colorScheme="maia-purple"
          onClick={handleSave}
          isLoading={loading}
        >
          {t('userSettings.saveButton')}
        </Button>
      </div>
    </div>
  );
}

export default UpdatePassword;
