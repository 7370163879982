import {
  Button,
  Divider,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { faArrowUpRight, faUsers } from '@fortawesome/pro-regular-svg-icons';
import { useEffect, useState } from 'react';

import AccessControl from '../../components/AccessControl';
import BuyAdaPagesButton from '../../components/ada/BuyAdaPagesButton';
import ContentHeader from '../../components/ContentHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import MaiaFullGridWidthContent from '../../components/layout/MaiaFullGridWidthContent';
import MaiaMainGrid from '../../components/layout/MaiaMainGrid';
import { ROUTES } from 'common-ts';
import SettingsSection from '../settings/SettingsSection';
import { captureException } from '@sentry/react';
import { fetchApi } from '../../utils/useApi';
import { handleWorkspaceRenew } from '../../utils/handleSubscription';
import { useBoundStore } from '../../store/useBoundStore';
import { useToastManagerHook } from '../../general/useToastManagerHook';
import { useTranslation } from 'react-i18next';

const PROFESSIONAL_PRICE = 39;
const BASIC_PRICE = 19;

const numFormat = new Intl.NumberFormat(undefined, {
  style: 'currency',
  currency: 'EUR',
});

function LicenseManagement() {
  const supabase = useBoundStore((state) => state.supabase);
  const stripe = useBoundStore((state) => state.stripe);
  const stripeLoading = useBoundStore((state) => state.stripeLoading);
  const isInFreeTrial = useBoundStore((state) => state.isInFreeTrial);

  const { t } = useTranslation();
  const workspaceId = useBoundStore((state) => state.workspaceId);
  const workspaceLicenseType = useBoundStore(
    (state) => state.workspaceLicenseType
  );
  const workspaceInactive = useBoundStore((state) => state.workspaceInactive);
  const { showToast } = useToastManagerHook();

  const [portalLoading, setPortalLoading] = useState(false);
  const [checkoutLoading, setCheckoutLoading] = useState(false);

  const [currentNumSeats, setCurrentNumSeats] = useState(0);
  const [desiredNumSeats, setDesiredNumSeats] = useState(0);
  const [workspaceName, setWorkspaceName] = useState('');

  useEffect(() => {
    async function fetchWorkspaceInfo() {
      const { data, error } = await supabase
        .from('workspace')
        .select()
        .eq('id', workspaceId);

      if (error || !data?.[0]) {
        captureException(
          error ??
            'Error fetching workspace info, but supabase did not return an error'
        );
        showToast({ title: t('general.reloadError'), status: 'error' });
        return;
      }

      setCurrentNumSeats(data[0].total_seats);
      setDesiredNumSeats(data[0].total_seats);
      setWorkspaceName(data[0].name);
    }

    fetchWorkspaceInfo();
  }, []);

  const price = isInFreeTrial
    ? 0
    : workspaceLicenseType === 'PROFESSIONAL'
      ? PROFESSIONAL_PRICE
      : workspaceLicenseType === 'BASIC'
        ? BASIC_PRICE
        : 0;

  return (
    <MaiaMainGrid>
      <MaiaFullGridWidthContent className="flex flex-col gap-4">
        <ContentHeader
          headingLabel={t('userSettings.menu.license')}
          icon={faUsers}
          buttons={[
            <Button
              colorScheme={'maia-accent'}
              leftIcon={<FontAwesomeIcon icon={faArrowUpRight} />}
              as={Link}
              to={ROUTES.HOME.PRICING.buildPath({ workspaceId })}
            >
              {t('workspaceSettings.licenseManagement.linkToPricingButton')}
            </Button>,
          ]}
          iconLabel={workspaceName}
        />
        <SettingsSection>
          {t('workspaceSettings.licenseManagement.title')}
        </SettingsSection>
        <TableContainer className="border-maia-border rounded-xl border">
          <Table variant="simple">
            <Thead>
              <Tr className="font-semibold">
                <Th textColor={'messenger.900'}>
                  {t('workspaceSettings.licenseManagement.plan')}
                </Th>
                <Th>{t('workspaceSettings.licenseManagement.price')}</Th>
                <Th>{t('workspaceSettings.licenseManagement.seats')}</Th>
                <Th>
                  {t('workspaceSettings.licenseManagement.nextPriceEstimate')}
                </Th>
              </Tr>
            </Thead>
            <Tbody className="text-maia-text-dark ">
              <Tr>
                <Td className="font-medium">
                  {workspaceLicenseType
                    ? workspaceLicenseType.charAt(0).toUpperCase() +
                      workspaceLicenseType.slice(1).toLowerCase()
                    : '-'}
                  <br />
                  {isInFreeTrial ? (
                    <span className="text-xs font-normal">
                      {`${t('workspaceSettings.licenseManagement.freeTrial')} ${Math.ceil((new Date(isInFreeTrial).getTime() - new Date().getTime()) / 1000 / 60 / 60 / 24)} ${t('workspaceSettings.licenseManagement.daysLeft')}`}
                    </span>
                  ) : null}
                </Td>
                <Td>{numFormat.format(price)}</Td>
                <Td>{currentNumSeats}</Td>
                <Td>{numFormat.format(price * desiredNumSeats)}</Td>
              </Tr>
              <Tr>
                <Td className="font-medium">
                  {t('workspaceSettings.licenseManagement.total')}
                </Td>
                <Td />
                <Td />
                <Td>
                  {numFormat.format(price * desiredNumSeats)}
                  <br />
                  {`(${t('workspaceSettings.licenseManagement.excludingTax')})`}
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
        {!!workspaceInactive && (
          <Button
            isLoading={checkoutLoading || stripeLoading}
            className="self-start"
            colorScheme="maia-accent"
            onClick={() => {
              setCheckoutLoading(true);
              handleWorkspaceRenew(workspaceId, stripe!, supabase).then(() => {
                setCheckoutLoading(false);
              });
            }}
          >
            {t('workspaceSettings.licenseManagement.renewSubscription')}
          </Button>
        )}
        <AccessControl allowedLicenses={['PROFESSIONAL']}>
          <BuyAdaPagesButton
            className="self-start"
            redirectTo={ROUTES.HOME.SETTINGS.WORKSPACE.LICENSE.buildPath({
              workspaceId,
            })}
            withInfoTooltip
          />
        </AccessControl>
        <div className="flex items-center gap-1">
          <Button
            variant={'outline'}
            onClick={async () => {
              setPortalLoading(true);
              const portalRes = await fetchApi(
                supabase,
                '/payment',
                '/load_portal',
                {
                  method: 'POST',
                  returnUrl: window.location.href,
                  workspaceId,
                }
              );
              setPortalLoading(false);
              if (portalRes.success) {
                window.location.assign(portalRes.data.url);
              } else {
                showToast({
                  title: t('general.tryAgainError'),
                  status: 'error',
                });
              }
            }}
          >
            {t('workspaceSettings.licenseManagement.stripeLink')}
          </Button>
          {portalLoading && <Spinner size={'sm'} />}
        </div>
        <Divider />
        <div className="text-xs">
          {t('workspaceSettings.licenseManagement.invoiceInfo')}{' '}
          <a className="underline" href="mailto:info@prodlane.io">
            info@prodlane.io
          </a>
        </div>
      </MaiaFullGridWidthContent>
    </MaiaMainGrid>
  );
}

export default LicenseManagement;
