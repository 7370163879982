import { useEffect, useState } from 'react';

import AuthenticatorAppMFA from './AuthenticatorAppMFA';
import ContentHeader from '../../../components/ContentHeader';
import { Divider } from '@chakra-ui/react';
import EmailMFA from './EmailMFA';
import MaiaFullGridWidthContent from '../../../components/layout/MaiaFullGridWidthContent';
import MaiaMainGrid from '../../../components/layout/MaiaMainGrid';
import SettingsSection from '../../settings/SettingsSection';
import { faUser } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import { useBoundStore } from '../../../store/useBoundStore.js';

export default function SecuritySetting() {
  const { t } = useTranslation();
  const userInfo = useBoundStore((state) => state.userInfo);

  const [mfaCheck, setMfaCheck] = useState<boolean>(false);

  useEffect(() => {
    if (mfaCheck) setMfaCheck(false);
  }, [mfaCheck]);
  return (
    <MaiaMainGrid>
      <MaiaFullGridWidthContent className="flex flex-col gap-4">
        <ContentHeader
          headingLabel={t('userSettings.security.title')}
          buttons={[]}
          icon={faUser}
          iconLabel={
            userInfo?.firstName
              ? `${userInfo.firstName} ${userInfo.lastName ?? ''}`
              : userInfo?.email ?? ''
          }
        />
        <SettingsSection>{t('userSettings.security.2FAtitle')}</SettingsSection>
        <div className="mb-4">{t('userSettings.security.subtitle')}</div>
        {/* <div className="my-4 font-medium">
          {t('userSettings.security.twoFactorMethods')}
        </div> */}
        <AuthenticatorAppMFA setMfaCheck={(val) => setMfaCheck(val)} />
        <EmailMFA setMfaCheck={(val) => setMfaCheck(val)} />
        <Divider />
      </MaiaFullGridWidthContent>
    </MaiaMainGrid>
  );
}
