import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

type MaiaFullGridWidthContentProps = {
  className?: string;
  children?: ReactNode;
};

function MaiaFullGridWidthContent({
  className,
  children,
}: MaiaFullGridWidthContentProps) {
  return (
    <div
      className={twMerge(
        'col-span-11 col-start-1 xl:col-span-9 xl:col-start-2',
        className
      )}
    >
      {children}
    </div>
  );
}

export default MaiaFullGridWidthContent;
