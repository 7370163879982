import {
  NavLink,
  Navigate,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';
import { useEffect, useState } from 'react';

import AccessControl from '../../components/AccessControl';
import GeneralSettings from '../workspaceSettings/GeneralSettings';
import LicenseManagement from '../workspaceSettings/LicenseManagement';
import ListItem from '../../components/ListItem';
import MaiaLayout from '../../components/MaiaLayout';
import MaiaMenuPanel from '../../components/menu/MaiaMenuPanel';
import PersonalSettings from '../userSettings/PersonalSettings';
import { ROUTES } from 'common-ts';
import SecuritySetting from '../userSettings/securitySettings/SecuritySetting';
import { Text } from '@chakra-ui/react';
import UserManagement from '../workspaceSettings/UserManagement';
import { useBoundStore } from '../../store/useBoundStore.js';
import { useTranslation } from 'react-i18next';

function Settings() {
  const location = useLocation();
  const { t } = useTranslation();
  const workspaceUserType = useBoundStore((state) => state.workspaceUserType);
  const isStripeCustomer = useBoundStore((state) => state.isStripeCustomer);

  const [sidePanelOpen, setSidePanelOpen] = useState(false);

  useEffect(() => {
    setSidePanelOpen(false);
  }, [location]);

  return (
    <>
      <MaiaLayout
        sidePanelContent={
          <MaiaMenuPanel selectedNavigation="SETTINGS">
            <div className="flex flex-col gap-2 px-4 pt-3">
              {/* ------------------------------------------------ Account ------------------------------------------------ */}
              <Text className="font-semibold">Account</Text>
              <NavLink
                to={ROUTES.HOME.SETTINGS.$.ACCOUNT.PERSONAL.buildRelativePath(
                  {}
                )}
              >
                <ListItem
                  selected={location.pathname.includes(
                    ROUTES.HOME.SETTINGS.$.ACCOUNT.PERSONAL.relativePath
                  )}
                >
                  {t('userSettings.menu.personal')}
                </ListItem>
              </NavLink>
              <AccessControl
                allowedLicenses={['BASIC', 'PROFESSIONAL', 'ENTERPRISE']}
              >
                <NavLink
                  to={ROUTES.HOME.SETTINGS.$.ACCOUNT.SECURITY.buildRelativePath(
                    {}
                  )}
                >
                  <ListItem
                    selected={location.pathname.includes(
                      ROUTES.HOME.SETTINGS.$.ACCOUNT.SECURITY.relativePath
                    )}
                  >
                    {t('userSettings.menu.security')}
                  </ListItem>
                </NavLink>
              </AccessControl>
              {/* ------------------------------------------------ Workspace ------------------------------------------------ */}
              <AccessControl
                allowedLicenses={['BASIC', 'PROFESSIONAL', 'ENTERPRISE']}
                allowedRoles={['OWNER', 'ADMIN']}
              >
                <Text className="text-sm font-semibold">Workspace</Text>
                <NavLink
                  to={ROUTES.HOME.SETTINGS.$.WORKSPACE.GENERAL.buildRelativePath(
                    {}
                  )}
                >
                  <ListItem
                    selected={location.pathname.includes(
                      ROUTES.HOME.SETTINGS.$.WORKSPACE.GENERAL.relativePath
                    )}
                  >
                    {t('workspaceSettings.menu.generalSettings')}
                  </ListItem>
                </NavLink>
                <NavLink
                  to={ROUTES.HOME.SETTINGS.$.WORKSPACE.USER_MANAGEMENT.buildRelativePath(
                    {}
                  )}
                >
                  <ListItem
                    selected={location.pathname.includes(
                      ROUTES.HOME.SETTINGS.$.WORKSPACE.USER_MANAGEMENT
                        .relativePath
                    )}
                  >
                    {t('workspaceSettings.menu.userManagement')}
                  </ListItem>
                </NavLink>
                {isStripeCustomer ? (
                  <NavLink
                    to={ROUTES.HOME.SETTINGS.$.WORKSPACE.LICENSE.buildRelativePath(
                      {}
                    )}
                  >
                    <ListItem
                      selected={location.pathname.includes(
                        ROUTES.HOME.SETTINGS.$.WORKSPACE.LICENSE.relativePath
                      )}
                    >
                      {t('workspaceSettings.menu.license')}
                    </ListItem>
                  </NavLink>
                ) : null}
              </AccessControl>
            </div>
          </MaiaMenuPanel>
        }
        mainPanelContent={
          <Routes>
            <Route
              path={ROUTES.HOME.SETTINGS.$.ACCOUNT.PERSONAL.relativePath}
              element={<PersonalSettings />}
            />
            <Route
              path={ROUTES.HOME.SETTINGS.$.ACCOUNT.SECURITY.relativePath}
              element={<SecuritySetting />}
            />
            {workspaceUserType === 'OWNER' || workspaceUserType === 'ADMIN' ? (
              <>
                <Route
                  path={ROUTES.HOME.SETTINGS.$.WORKSPACE.GENERAL.relativePath}
                  element={<GeneralSettings />}
                />
                <Route
                  path={
                    ROUTES.HOME.SETTINGS.$.WORKSPACE.USER_MANAGEMENT
                      .relativePath
                  }
                  element={<UserManagement />}
                />
                {isStripeCustomer ? (
                  <Route
                    path={ROUTES.HOME.SETTINGS.$.WORKSPACE.LICENSE.relativePath}
                    element={<LicenseManagement />}
                  />
                ) : null}
              </>
            ) : null}
            <Route
              path=""
              element={
                <Navigate
                  to={ROUTES.HOME.SETTINGS.$.ACCOUNT.PERSONAL.buildRelativePath(
                    {}
                  )}
                  replace
                />
              }
            />
          </Routes>
        }
        sidePanelOpenMobile={sidePanelOpen}
        onOpenSidePanelMobile={() => setSidePanelOpen(true)}
        onCloseSidePanelMobile={() => setSidePanelOpen(false)}
      />
    </>
  );
}

export default Settings;
