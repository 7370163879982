import {
  Avatar,
  Button,
  Popover,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
} from '@chakra-ui/react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { faCaretUp } from '@fortawesome/pro-solid-svg-icons';
import i18n from '../i18n.js';
import { useState } from 'react';

export const supportedLanguages = [
  { short: 'en', full: 'English' },
  { short: 'de', full: 'Deutsch' },
] as const;

function ChangeLanguage() {
  const [currentLanguage, setCurrentLanguage] = useState(
    supportedLanguages.find(
      (supportedLang) => supportedLang.short === i18n.language.split('-')[0]
    )?.short ?? 'en'
  );
  const { isOpen, onOpen, onClose } = useDisclosure();

  const setLanguage = (lang: (typeof supportedLanguages)[number]['short']) => {
    if (lang === currentLanguage) return;
    setCurrentLanguage(lang);
    i18n.changeLanguage(lang);
  };

  return (
    <Popover matchWidth isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
      <PopoverTrigger>
        <Button className="p-0" colorScheme="whiteAlpha">
          <Avatar size={'xs'} src={`/lang_${currentLanguage}.png`} />
          <FontAwesomeIcon
            icon={isOpen ? faCaretUp : faCaretDown}
            className="text-maia-accent pl-1"
          />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-full">
        {supportedLanguages.map((lang, index) => (
          <Button
            key={index}
            colorScheme={`${
              lang.short === currentLanguage ? 'maia-purple' : 'whiteAlpha'
            }`}
            className="w-full"
            onClick={() => {
              setLanguage(lang.short);
              onClose();
            }}
          >
            <Avatar size={'xs'} src={`/lang_${lang.short}.png`} />
          </Button>
        ))}
      </PopoverContent>
    </Popover>
  );
}

export default ChangeLanguage;
