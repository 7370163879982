import { SupabaseClient, createClient } from '@supabase/supabase-js';

import { Database } from 'common-ts';
import { StateCreator } from 'zustand';

type SupabaseState = {
  supabase: SupabaseClient<Database, 'public'>;
};

export type SupabaseSlice = SupabaseState;

const supabaseUrl = import.meta.env.VITE_MAIA_SUPABASE_API_PUBLIC_URL;
const anonKey = import.meta.env.VITE_ANON_KEY;
if (!supabaseUrl) {
  throw new Error(
    'Initializing Supabase provider failed - VITE_MAIA_SUPABASE_API_PUBLIC_URL not set'
  );
}
if (!anonKey) {
  throw new Error(
    'Initializing Supabase provider failed - VITE_ANON_KEY not set'
  );
}
const supabase: SupabaseClient<Database, 'public'> = createClient(
  supabaseUrl,
  anonKey
);

export const createSupabaseSlice: StateCreator<SupabaseSlice> = () => ({
  supabase,
});
