import { Outlet, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { Image } from '@chakra-ui/react';
import LogoLettering from '../../components/Logo/LogoLettering.js';
import { captureException } from '@sentry/react';
import i18n from '../../i18n.js';
import { navigateToDefaultWorkspace } from '../../utils/getDefaultWorkspace.js';
import { useBoundStore } from '../../store/useBoundStore.js';
import { useToastManagerHook } from '../../general/useToastManagerHook.js';
import { useTranslation } from 'react-i18next';

export default function LoginContainer() {
  const { t } = useTranslation();
  const supabase = useBoundStore((state) => state.supabase);
  const navigate = useNavigate();
  const { showToast } = useToastManagerHook();

  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

  const checkUserSession = async () => {
    try {
      const { data } = await supabase.auth.getSession();

      if (data.session)
        navigateToDefaultWorkspace({
          navigateFn: navigate,
          showToast,
          supabase,
          translationFn: t,
        });
    } catch (e: any) {
      captureException(e);
      console.error(e.message);
    }
  };

  useEffect(() => {
    i18n.changeLanguage(currentLanguage);
  }, [currentLanguage]);

  useEffect(() => {
    checkUserSession();
  }, []);

  const toggleLanguage = () => {
    const newLang = currentLanguage === 'en' ? 'de' : 'en';
    setCurrentLanguage(newLang);
    i18n.changeLanguage(newLang);
  };

  return (
    <div
      className={`relative flex h-screen w-screen flex-col items-center gap-8 px-8 py-4`}
    >
      <button
        className={`absolute right-4 top-4 text-xl md:text-2xl`}
        onClick={toggleLanguage}
      >
        {i18n.language === 'en' ? '🇬🇧' : '🇩🇪'}
      </button>
      <div
        className={`flex w-full flex-grow flex-col items-center justify-center gap-14 md:w-[428px]`}
      >
        <LogoLettering />
        <Outlet />
      </div>
      <div
        className={`flex w-full items-center justify-end gap-4 font-light text-[#446977] md:text-base`}
      >
        <div className="text-sm font-medium">Brought to you by</div>
        <a href="https://prodlane.io/" target="_blank" rel="noreferrer">
          <Image
            height={[4, 4, 6]}
            src="/Lettering_Hori_Transparent.png"
            alt="logo"
          />
        </a>
      </div>
    </div>
  );
}
