import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { Database } from 'common-ts';
import { useTranslation } from 'react-i18next';

type EditUserModalProps = {
  user:
    | Database['public']['Views']['workspace_users_admin_view']['Row']
    | undefined;
  isOpen: boolean;
  onClose: () => void;
  onUpdate: (
    userId: string,
    userType: Database['public']['Enums']['workspace_user_type']
  ) => void;
};

function EditUserModal({
  user,
  isOpen,
  onClose,
  onUpdate,
}: EditUserModalProps) {
  const { t } = useTranslation();

  // const fullNameRef = useRef('');
  const [role, setRole] = useState<
    Database['public']['Enums']['workspace_user_type']
  >(user?.user_type ?? 'USER');

  useEffect(() => {
    setRole(user?.user_type ?? 'USER');
  }, [user]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('workspaceSettings.editUserModal.title')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody className="flex flex-col gap-4">
          <FormControl>
            <FormLabel>
              {t('workspaceSettings.editUserModal.userNameLabel')}
            </FormLabel>
            <Input
              isDisabled
              value={
                user?.first_name
                  ? `${user.first_name} ${user.last_name ?? ''}`
                  : '-'
              }
            />
          </FormControl>
          <FormControl>
            <FormLabel>
              {t('workspaceSettings.editUserModal.emailLabel')}
            </FormLabel>
            <Input isDisabled value={user?.email ?? ''} />
          </FormControl>
          <FormControl>
            <FormLabel>
              {t('workspaceSettings.editUserModal.userTypeLabel')}
            </FormLabel>
            <Select
              size={'sm'}
              onChange={(e) => {
                setRole(
                  e.target
                    .value as Database['public']['Enums']['workspace_user_type']
                );
              }}
              value={role}
            >
              <option value={'USER'}>User</option>
              <option value={'ADMIN'}>Admin</option>
            </Select>
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <div className="flex items-center gap-2">
            <Button
              variant="outline"
              className="text-maia-text-dark font-medium"
              onClick={onClose}
            >
              {t('general.cancelButton')}
            </Button>
            <Button
              className="text-maia-text-light font-medium"
              colorScheme="maia-accent"
              isDisabled={!user}
              mr={3}
              onClick={() => {
                onUpdate(user!.user_id!, role);
                onClose();
              }}
            >
              {t('general.updateButtons')}
            </Button>
          </div>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default EditUserModal;
