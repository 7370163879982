import {
  faBuilding,
  faUser,
  faUsers,
} from '@fortawesome/pro-regular-svg-icons';

import { Database } from 'common-ts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { twMerge } from 'tailwind-merge';

type WorkspaceIconProps = {
  licenseType: Database['public']['Enums']['license_tier'];
  className?: string;
};

function WorkspaceIcon({ licenseType, className }: WorkspaceIconProps) {
  return (
    <div
      className={twMerge(
        'bg-maia-blue-100 flex h-11 w-11 flex-shrink-0 items-center justify-center rounded-full border',
        className
      )}
    >
      <FontAwesomeIcon
        icon={
          licenseType === 'ENTERPRISE'
            ? faBuilding
            : licenseType === 'PROFESSIONAL'
              ? faUsers
              : faUser
        }
        className="text-maia-accent"
        size="lg"
      />
    </div>
  );
}

export default WorkspaceIcon;
