import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
} from '@chakra-ui/react';

import { fetchApi } from '../../utils/useApi';
import { t } from 'i18next';
import { useBoundStore } from '../../store/useBoundStore';
import { useState } from 'react';
import { useToastManagerHook } from '../useToastManagerHook';

type TrialAboutToEndModalProps = {
  isOpen: boolean;
  trialEndTimestamp: number;
  onClose: () => void;
};

function TrialAboutToEndModal({
  isOpen,
  trialEndTimestamp,
  onClose,
}: TrialAboutToEndModalProps) {
  const supabase = useBoundStore((state) => state.supabase);
  const workspaceId = useBoundStore((state) => state.workspaceId);

  const { showToast } = useToastManagerHook();

  const [portalLoading, setPortalLoading] = useState(false);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('general.trialAboutToEndModal.title')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody className="flex flex-col gap-4">
          <div>
            {t('general.trialAboutToEndModal.body')}{' '}
            {new Date(trialEndTimestamp).toLocaleDateString()}
            <br />
            {t('general.trialAboutToEndModal.bodyAfterDate')}
          </div>
          <div className="flex items-center gap-1">
            <Button
              variant={'outline'}
              colorScheme="maia-accent"
              onClick={async () => {
                setPortalLoading(true);
                const portalRes = await fetchApi(
                  supabase,
                  '/payment',
                  '/load_portal',
                  {
                    method: 'POST',
                    returnUrl: window.location.href,
                    workspaceId,
                  }
                );
                setPortalLoading(false);
                if (portalRes.success) {
                  window.location.assign(portalRes.data.url);
                } else {
                  showToast({
                    title: t('general.tryAgainError'),
                    status: 'error',
                  });
                }
              }}
            >
              {t('workspaceSettings.licenseManagement.stripeLink')}
            </Button>
            {portalLoading && <Spinner size={'sm'} />}
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default TrialAboutToEndModal;
