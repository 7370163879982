import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  IconButton,
} from '@chakra-ui/react';

import FileIcon from '../../components/FileIcon.js';
import FilePreviewIconButton from './FilePreviewIconButton.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactMarkdown from 'react-markdown';
import { ResultV1V2Abstraction } from '../../utils/resultsV1V2Conversion.js';
import { faEye } from '@fortawesome/pro-light-svg-icons';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import { createRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from '@sentry/react';
import RenderError from '../../components/RenderError.js';

type ChatSnippetResultType = {
  result: ResultV1V2Abstraction[];
  focusInlineReference: number;
  clearInlineReference: () => void;
};

const extractFirstPageNumber = (pageRange: string | undefined): number => {
  if (!pageRange) return 0;

  const match = pageRange.match(/^\d+/);
  return match ? parseInt(match[0], 10) : 0;
};

function ChatSnippetResult({
  result,
  focusInlineReference,
  clearInlineReference,
}: ChatSnippetResultType) {
  const accordionRef = createRef<HTMLDivElement>();
  const [index, setIndex] = useState<number | number[]>(-1);
  const { t } = useTranslation();
  useEffect(() => {
    let timerId: NodeJS.Timeout;

    if (focusInlineReference >= 0 && accordionRef.current) {
      setIndex(focusInlineReference);

      const accordionItem = accordionRef.current.children[focusInlineReference];
      if (accordionItem && index === focusInlineReference) {
        // timeout is necessary to wait for the accordion to opening/closing animation
        timerId = setTimeout(() => {
          accordionItem.scrollIntoView({
            behavior: 'smooth',
          });
          clearInlineReference();
        }, 300);
      }
    }

    return () => clearTimeout(timerId);
  }, [focusInlineReference, index]);

  return (
    <Accordion
      allowToggle
      index={index}
      onChange={setIndex}
      className="flex flex-col gap-y-2"
      ref={accordionRef}
    >
      {result.map((snippet, index) => (
        <AccordionItem
          key={index}
          className="border-maia-border w-full gap-[10px] rounded border px-0 py-0 md:px-4 md:py-3"
        >
          <AccordionButton className="flex items-center justify-between font-bold">
            <FileIcon
              className="text-chakra-gray-400 mr-3 font-black"
              name={snippet.filename}
            />
            <div className="flex min-w-0 grow items-center text-sm">
              <div className="flex max-w-full flex-col items-start">
                <div className="flex max-w-full items-center truncate">
                  <span className="text-maia-text-dark mr-1 font-medium">
                    [{index + 1}]
                  </span>
                  <div className="text-maia-text-dark truncate font-medium leading-[26px]">
                    {snippet.filename}
                  </div>
                </div>
                {snippet.pageNumbers.length > 0 &&
                  snippet.filename.endsWith('.pdf') && (
                    <div className="text-chakra-gray-500 max-w-full truncate text-xs font-medium">
                      {`${snippet.pageNumbers.length === 1 && !snippet.pageNumbers[0]?.includes('-') ? t('chat.page') : t('chat.pages')}: ${snippet.pageNumbers.join(', ')}`}
                    </div>
                  )}
              </div>
            </div>
            <div className="flex content-center">
              <FilePreviewIconButton
                fileId={snippet.fileId}
                documentId={snippet.documentId}
                fileName={snippet.filename}
                startingPage={extractFirstPageNumber(snippet.pageNumbers[0])}
              />
              <IconButton
                icon={<FontAwesomeIcon icon={faEye} />}
                aria-label={`View ${snippet.filename}`}
                variant={'ghost'}
                className="text-maia-text-dark font-medium"
              />
            </div>
          </AccordionButton>
          <AccordionPanel>
            <ErrorBoundary fallback={<RenderError content={snippet.content} />}>
              <ReactMarkdown
                className="prose min-w-0 max-w-none text-sm"
                remarkPlugins={[remarkGfm]}
                rehypePlugins={[rehypeRaw as any]}
              >
                {snippet.content}
              </ReactMarkdown>
            </ErrorBoundary>
          </AccordionPanel>
        </AccordionItem>
      ))}
    </Accordion>
  );
}

export default ChatSnippetResult;
