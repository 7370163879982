import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';

import { useTranslation } from 'react-i18next';

type Disable2FAModalProps = {
  title: string;
  subtitle: string;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

export default function Disable2FAModal({
  title,
  subtitle,
  isOpen,
  onConfirm,
  onClose,
}: Disable2FAModalProps) {
  const { t } = useTranslation();

  const handleConfirm = async () => {
    await onConfirm();
    onClose();
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent className="text-maia-gray-700">
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{subtitle}</ModalBody>
        <ModalFooter gap={2}>
          <Button
            className="text-maia-text-dark font-medium"
            variant="outline"
            onClick={onClose}
            size={'sm'}
          >
            {t('userSettings.security.disable2FAModal.secondaryButton')}
          </Button>
          <Button
            className="text-maia-text-light bg-maia-support-red"
            mr={3}
            onClick={handleConfirm}
            size={'sm'}
          >
            {t('userSettings.security.disable2FAModal.primaryButton')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
