import { getLlmModel, setLlmModel } from '../utils/localStorage.js';
import { useEffect, useState } from 'react';

import { AvailableModelEnum } from 'common-ts';
import { Select } from '@chakra-ui/react';
import { useBoundStore } from '../store/useBoundStore.js';

const supportedModels = ['GPT-3.5', 'GPT-4'] as const;

type SupportedModelType = (typeof supportedModels)[number];

type ChangeLlmProps = {
  onChange?: () => void;
};

export function ChangeLlm({ onChange }: ChangeLlmProps) {
  const workspaceLicenseType = useBoundStore(
    (state) => state.workspaceLicenseType
  );

  const [model, setModel] = useState<SupportedModelType>(
    getLlmModel(workspaceLicenseType ?? undefined) === AvailableModelEnum.GPT_4
      ? 'GPT-4'
      : 'GPT-3.5'
  );
  const [availableModels, setAvailableModels] = useState<SupportedModelType[]>([
    'GPT-3.5',
  ]);

  const updateAvailableModels = () => {
    if (workspaceLicenseType !== 'FREE') {
      setAvailableModels([...availableModels, 'GPT-4']);
    }
  };

  useEffect(() => {
    updateAvailableModels();
  }, []);

  function persistGpt(model: SupportedModelType) {
    setLlmModel(
      model === 'GPT-4'
        ? AvailableModelEnum.GPT_4
        : AvailableModelEnum.GPT_3_5_TURBO_16K
    );
    setModel(model);
    onChange?.();
  }

  return (
    <>
      <Select
        maxWidth="fit-content"
        variant="unstyled"
        className="text-maia-gray-900 text-sm font-normal"
        onChange={(e) => {
          persistGpt(e.target.value as SupportedModelType);
        }}
        defaultValue={model}
      >
        {supportedModels.map((model) => (
          <option key={model} value={model}>
            {model}
          </option>
        ))}
      </Select>
    </>
  );
}
