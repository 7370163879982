import { StateCreator } from 'zustand';
import { SupabaseSlice } from './createSupabaseSlice';
import { setIsOnboarded as utilsSetIsOnboarded } from '../../utils/onboarding.js';

type UserState = {
  userIsMarker: boolean;
  isOnboarded: boolean | undefined;
  userInfo:
    | {
        userId: string;
        email: string;
        firstName: string | undefined;
        lastName: string | undefined;
      }
    | undefined;
  accessToken: string | undefined;
  sentRequests: number;
};

type UserAction = {
  optimisticUpdateSentRequests: (amount: number) => void;
  setIsOnboarded: (isOnboarded: boolean) => Promise<void>;
};

export type UserInfoSlice = UserState & UserAction;

export const initialUserState: UserState = {
  userIsMarker: false,
  isOnboarded: undefined,
  userInfo: undefined,
  accessToken: undefined,
  sentRequests: 0,
};

export const createUserInfoSlice: StateCreator<
  SupabaseSlice & UserInfoSlice,
  [],
  [],
  UserInfoSlice
> = (set, get) => ({
  ...initialUserState,
  optimisticUpdateSentRequests: (amount) => {
    set((state) => ({ sentRequests: state.sentRequests + amount }));
  },
  setIsOnboarded: async (isOnboarded) => {
    await utilsSetIsOnboarded(get().supabase, isOnboarded);
    set({ isOnboarded });
  },
});
