import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Logo from '../../components/Logo/Logo';
import Lottie from 'lottie-react';
import { faStopCircle } from '@fortawesome/pro-regular-svg-icons';
import infinityLoadingAnimation from './loadingInfinity.json';
import { useTranslation } from 'react-i18next';

type MessageLoadingIndicatorProps = {
  powerMode?: boolean;
  chatInputSelected: boolean;
  handleAbortAnswerGeneration: () => void;
};

function MessageLoadingIndicator({
  powerMode,
  chatInputSelected,
  handleAbortAnswerGeneration,
}: MessageLoadingIndicatorProps) {
  const { t } = useTranslation();

  return (
    <div className="bg-maia-purple-200 flex h-11 flex-shrink-0 items-center gap-5 self-start rounded-xl px-6">
      <Logo size={20} />
      <div>{powerMode ? t('chat.powerModeQuery') : t('chat.loading')}</div>
      {powerMode && (
        <Lottie
          animationData={infinityLoadingAnimation}
          className="-m-8 h-full"
        />
      )}
      <div
        className="bg-maia-blue-400 flex h-5 cursor-pointer select-none items-center rounded-full px-2 text-xs font-semibold"
        onClick={handleAbortAnswerGeneration}
      >
        {chatInputSelected ? 'Esc' : <FontAwesomeIcon icon={faStopCircle} />}
      </div>
    </div>
  );
}

export default MessageLoadingIndicator;
