import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';

import React from 'react';
import { useTranslation } from 'react-i18next';

type ConfirmDeletePromptCollectionModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

/**
 * Modal to confirm the deletion of a prompt collection.
 * @param isOpen - Whether the modal is open.
 * @param onClose - Callback when the modal is closed or the deletion is cancelled.
 * @param onConfirm - Callback when the deletion is confirmed.
 */
function ConfirmDeletePromptCollectionModal({
  isOpen,
  onClose,
  onConfirm,
}: ConfirmDeletePromptCollectionModalProps) {
  const { t } = useTranslation();
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {t('promptLibrary.confirmDeletePromptCollectionDialogHeader')}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {t('promptLibrary.confirmDeletePromptCollectionDialogBody')}
        </ModalBody>
        <ModalFooter gap={3}>
          <Button variant="outline" onClick={onClose}>
            {t('general.cancelButton')}
          </Button>
          <Button colorScheme="red" onClick={onConfirm}>
            {t('general.deleteButton')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ConfirmDeletePromptCollectionModal;
