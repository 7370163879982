import { FeatureFlags } from '../general/featureFlags';
import { ReactNode } from 'react';
import { useBoundStore } from '../store/useBoundStore';

type FeatureProps = {
  name: keyof FeatureFlags;
  children: ReactNode;
};

/**
 * Wrap any node in this Feature component with the appropriate feature name.
 * That node will only be displayed if the feature is enabled for the tenant.
 */
function Feature({ name, children }: FeatureProps) {
  const featureFlags = useBoundStore((state) => state.featureFlags);

  if (featureFlags?.[name]) {
    return children;
  } else return null;
}

export default Feature;
