import { Database } from 'common-ts';
import {
  Divider,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import {
  faArrowUpLeftFromCircle,
  faBars,
  faChevronDown,
} from '@fortawesome/pro-regular-svg-icons';
import { useEffect, useRef, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UpgradeSubscriptionButton from '../UpgradeSubscriptionButton';
import WorkspaceIcon from './WorkspaceIcon.js';
import { WorkspaceListItem } from './WorkspaceListItem';
import { logout } from '../../utils/handleLogout.js';
import { twMerge } from 'tailwind-merge';
import { useBoundStore } from '../../store/useBoundStore.js';
import { useNavigate } from 'react-router-dom';
import { useToastManagerHook } from '../../general/useToastManagerHook.js';
import { useTranslation } from 'react-i18next';

type UserInfoProps = {
  onMenuButtonClick?: () => void;
  className?: string;
};

export type AvailableWorkspace = {
  id: Database['public']['Tables']['workspace']['Row']['id'];
  name: string;
  licenseType: Database['public']['Tables']['workspace']['Row']['license_type'];
  memberCount: number;
  isInactive: boolean | null;
  isUserDisabled: boolean | null;
  userType: Database['public']['Tables']['workspace_user']['Row']['user_type'];
  userId?: string;
};

function WorkspaceSwitcher({ onMenuButtonClick, className }: UserInfoProps) {
  const { t } = useTranslation();
  const userInfo = useBoundStore((state) => state.userInfo);
  const workspaceId = useBoundStore((state) => state.workspaceId);
  const supabase = useBoundStore((state) => state.supabase);
  const resetToInitialState = useBoundStore(
    (state) => state.resetToInitialState
  );
  const navigate = useNavigate();
  const [availableWorkspaces, setAvailableWorkspaces] = useState<
    AvailableWorkspace[] | undefined
  >(undefined);
  const selectedWorkspaceMenuItemRef = useRef<HTMLButtonElement>(null);

  const { showToast } = useToastManagerHook();

  const handleLogout = () => {
    resetToInitialState();
    logout(supabase, navigate);
  };

  async function fetchAvailableWorkspaces(userId: string) {
    const { data, error } = await supabase
      .from('workspace')
      .select(
        'id, license_type, name, inactive, workspace_user(user_id, user_type, disabled)'
      );

    if (error) {
      showToast({ title: t('general.tryAgainError'), status: 'error' });
      console.error(
        `Error fetching available workspaces for user ${userInfo?.userId}.`
      );
      setAvailableWorkspaces(undefined);
      return;
    }

    const { data: countData, error: countError } = await supabase
      .from('workspace_user_count')
      .select('workspace_id, user_count');

    if (countError) {
      showToast({ title: t('general.tryAgainError'), status: 'error' });
      console.error(
        `Error fetching workspace user count for workspace ${workspaceId}.`
      );
      setAvailableWorkspaces(undefined);
      return;
    }

    // Transforming data to match the AvailableWorkspace type
    const workspacesWithCounts = data?.map((workspace) => {
      const matchingCount = countData?.find(
        (count) => count.workspace_id === workspace.id
      ) || { user_count: 0 };

      // Parse inactive date to check if workspace is inactive
      let isInactive = false;
      if (workspace.inactive) {
        const inactiveDate = new Date(workspace.inactive);
        const currentDate = new Date();
        isInactive = inactiveDate < currentDate;
      }

      // Parse disabled date to check if user is disabled
      let isUserDisabled = false;
      const disabledString = workspace.workspace_user.find(
        (w) => w.user_id === userId
      )?.disabled;
      if (disabledString) {
        isUserDisabled = Date.parse(disabledString) < Date.now();
      }

      // Find the user type of the current user in the workspace
      const userType = workspace.workspace_user.find(
        (w) => w.user_id === userId
      )?.user_type;

      if (!userType) {
        console.error(
          `User type of user ${userInfo?.userId} in workspace ${workspace.id} not found.`
        );
      }

      return {
        id: workspace.id,
        name: workspace.name,
        licenseType: workspace.license_type,
        memberCount: matchingCount.user_count ?? 0,
        isInactive,
        isUserDisabled,
        userType: userType ?? 'USER',
        userId,
      };
    });

    setAvailableWorkspaces(workspacesWithCounts ?? undefined);
  }

  useEffect(() => {
    const initialize = async () => {
      if (userInfo?.userId && !availableWorkspaces) {
        await fetchAvailableWorkspaces(userInfo.userId);
      }
    };

    initialize();
  }, [userInfo]);

  return (
    <div
      className={twMerge(
        'flex w-full items-center justify-between p-4',
        className
      )}
    >
      <div className="flex min-w-0 items-center gap-2">
        <IconButton
          className="md:hidden"
          size="sm"
          aria-label={t('general.menuButton')}
          icon={<FontAwesomeIcon icon={faBars} />}
          variant={'ghost'}
          colorScheme="maia-purple"
          onClick={onMenuButtonClick}
        />
        <WorkspaceIcon
          licenseType={
            availableWorkspaces?.find(
              (workspace) => workspace.id === workspaceId
            )?.licenseType ?? 'FREE'
          }
        />
        <div className="flex min-w-0 flex-col">
          <div className="truncate font-semibold">
            {availableWorkspaces?.find(
              (workspace) => workspace.id === workspaceId
            )?.name ?? ''}
          </div>
          <div className="truncate text-xs font-medium text-gray-500">
            {userInfo?.firstName
              ? `${userInfo.firstName} ${userInfo.lastName ?? ''}`
              : userInfo?.email}
          </div>
          <div className="pt-1">
            <UpgradeSubscriptionButton size={'xs'} colorScheme="maia-accent">
              {t('general.upgradeButton')}
            </UpgradeSubscriptionButton>
          </div>
        </div>
      </div>
      <div className="z-[2] flex max-w-96 items-center gap-3">
        <Menu
          placement="bottom-end"
          initialFocusRef={selectedWorkspaceMenuItemRef}
        >
          {({ isOpen }) => (
            <>
              <MenuButton
                className="text-maia-text-dark text-md"
                size={'sm'}
                as={IconButton}
                icon={
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    className={`transition-transform duration-200 ease-in-out ${isOpen ? 'rotate-180' : 'rotate-0'}`}
                  />
                }
                variant="ghost"
              />
              <MenuList className="max-w-96">
                {availableWorkspaces?.map((workspace) => {
                  return (
                    <WorkspaceListItem
                      key={workspace.id}
                      workspace={workspace}
                      isSelected={workspaceId === workspace.id}
                    />
                  );
                })}
                <Divider className="my-1" />

                <MenuItem
                  className="text-maia-text-dark hover:bg-maia-blue-100 pl-4 text-sm"
                  icon={
                    <FontAwesomeIcon icon={faArrowUpLeftFromCircle} size="lg" />
                  }
                  onClick={handleLogout}
                >
                  {t('general.logoutButton')}
                </MenuItem>
              </MenuList>
            </>
          )}
        </Menu>
      </div>
    </div>
  );
}

export default WorkspaceSwitcher;
