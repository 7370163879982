import { Stripe, loadStripe } from '@stripe/stripe-js';

import { StateCreator } from 'zustand';

type StripeState = {
  stripeLoading: boolean;
  stripe: Stripe | null;
};

type StripeAction = {
  initStripe: () => Promise<void>;
};

export type StripeSlice = StripeState & StripeAction;

export const initialStripeState: StripeState = {
  stripeLoading: false,
  stripe: null,
};

export const createStripeSlice: StateCreator<StripeSlice> = (set) => ({
  ...initialStripeState,
  initStripe: async () => {
    set({ stripeLoading: true });

    const stripePublicKey = import.meta.env.VITE_MAIA_STRIPE_PUBLISHABLE_KEY;
    if (!stripePublicKey) {
      throw new Error(
        'Initializing Stripe provider failed - VITE_MAIA_STRIPE_PUBLISHABLE_KEY not set'
      );
    }

    const stripe = await loadStripe(
      import.meta.env.VITE_MAIA_STRIPE_PUBLISHABLE_KEY
    );
    set({ stripe, stripeLoading: false });
  },
});
