import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  HStack,
} from '@chakra-ui/react';
import { useRef } from 'react';
import UpgradeSubscriptionButton from './UpgradeSubscriptionButton';
import { t } from 'i18next';

type MaiaAlertDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  AlertDialogHeaderText: string;
  AlertDialogBodyText: string;
};

function MaiaAlertDialogWithUpgradeButton({
  isOpen,
  onClose,
  AlertDialogHeaderText,
  AlertDialogBodyText,
}: MaiaAlertDialogProps) {
  const cancelRef = useRef(null);

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      onEsc={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {AlertDialogHeaderText}
          </AlertDialogHeader>
          <AlertDialogBody>{AlertDialogBodyText}</AlertDialogBody>
          <AlertDialogFooter>
            <HStack spacing={2}>
              <UpgradeSubscriptionButton flexShrink={0} size={'md'}>
                {t('general.upgradeButton')}
              </UpgradeSubscriptionButton>
              <Button ref={cancelRef} onClick={onClose}>
                {t('general.closeButtons')}
              </Button>
            </HStack>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}

export default MaiaAlertDialogWithUpgradeButton;
