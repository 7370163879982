import { Button, ButtonProps, Tooltip } from '@chakra-ui/react';

import { handleBuyAdaPages } from '../../utils/handleSubscription';
import { useBoundStore } from '../../store/useBoundStore';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

type BuyAdaPagesButtonProps = {
  redirectTo: string;
  withInfoTooltip?: boolean;
} & ButtonProps;

function BuyAdaPagesButton(props: BuyAdaPagesButtonProps) {
  const { t } = useTranslation();

  const { redirectTo, withInfoTooltip, ...rest } = props;

  const supabase = useBoundStore((state) => state.supabase);
  const stripeLoading = useBoundStore((state) => state.stripeLoading);
  const stripe = useBoundStore((state) => state.stripe);
  const workspaceId = useBoundStore((state) => state.workspaceId);

  const [redirectLoading, setRedirectLoading] = useState(false);

  const stripeFail = !stripeLoading && !stripe;

  return (
    <Tooltip
      isDisabled={!stripeFail && !withInfoTooltip}
      label={
        stripeFail
          ? t('general.stripe.connectToStripeFailed')
          : t('components.ada.additionalPagesTooltip')
      }
    >
      <Button
        isLoading={stripeLoading || redirectLoading}
        isDisabled={stripeFail}
        onClick={() => {
          setRedirectLoading(true);
          handleBuyAdaPages(workspaceId, stripe!, supabase, redirectTo).then(
            () => setRedirectLoading(false)
          );
        }}
        colorScheme="maia-purple"
        {...rest}
      >
        {t('components.ada.buyAdaPages')}
      </Button>
    </Tooltip>
  );
}

export default BuyAdaPagesButton;
