import {
  ChatClient,
  ExtractionModesSelectedMessage,
  FileInfoOverflowMessage,
  SnippetContextCutoffMessage,
  WholeFileDiscardedMessage,
} from 'common-ts';
import { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@chakra-ui/react';
import { faEllipsis } from '@fortawesome/pro-solid-svg-icons';
import { twMerge } from 'tailwind-merge';

type PowerModeLogsProps = {
  className?: string;
  latestMessage: ChatClient | undefined;
};

type PowerModeLogMessage =
  | ExtractionModesSelectedMessage
  | FileInfoOverflowMessage
  | SnippetContextCutoffMessage
  | WholeFileDiscardedMessage;

function PowerModeLogs({ className, latestMessage }: PowerModeLogsProps) {
  const [logs, setLogs] = useState<
    { time: Date; logMessage: PowerModeLogMessage }[]
  >([]);

  const [showLogs, setShowLogs] = useState(false);

  useEffect(() => {
    if (
      latestMessage &&
      [
        'POWER_MODE_EXTRACTIONS_STARTED',
        'POWER_MODE_FILE_INFO_OVERFLOW',
        'POWER_MODE_SNIPPET_CONTEXT_CUTOFF',
        'POWER_MODE_WHOLE_FILE_DISCARDED',
        'POWER_MODE_PART_OF_DOC_DISCARDED',
        'POWER_MODE_DOC_SUMMARY_DISCARDED',
      ].includes(latestMessage.type)
    )
      setLogs((prev) => {
        return [
          ...prev,
          {
            time: new Date(),
            logMessage: latestMessage as PowerModeLogMessage,
          },
        ];
      });
  }, [latestMessage]);

  return (
    <div
      className={twMerge(
        'flex flex-col gap-4 rounded border-gray-100 bg-white p-4 shadow-md',
        className
      )}
    >
      <div className="flex justify-end">
        <Tooltip label="Power mode logs">
          <FontAwesomeIcon
            icon={faEllipsis}
            className="cursor-pointer"
            onClick={() => setShowLogs(!showLogs)}
          />
        </Tooltip>
      </div>
      <div
        className={`flex flex-col gap-4 overflow-auto ${
          showLogs ? '' : 'hidden'
        }`}
      >
        {logs.map((log) => {
          return (
            <div className="flex flex-col gap-4 rounded-sm border border-gray-300">
              <div className="text-xs">{log.time.toLocaleTimeString()}</div>
              <div className="flex flex-col gap-2">
                {log.logMessage.type === 'POWER_MODE_EXTRACTIONS_STARTED'
                  ? log.logMessage.extractions.map((extraction) => {
                      return (
                        <div className="flex flex-col gap-1 rounded bg-gray-200">
                          <div>Mode: {extraction.mode}</div>
                          <div>Request: {extraction.request}</div>
                          <div>Files: {extraction.fileNames.join(', ')}</div>
                        </div>
                      );
                    })
                  : JSON.stringify(log.logMessage)}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default PowerModeLogs;
