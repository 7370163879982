import { useEffect, useState } from 'react';

import { Button } from '@chakra-ui/react';
import CollectionAccordion from './CollectionAccordion.js';
import { Divider } from '@chakra-ui/react';
import { ExtendedBucket } from '../../@types/extendedTypes.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { twMerge } from 'tailwind-merge';
import { useBoundStore } from '../../store/useBoundStore.js';
import { useTranslation } from 'react-i18next';

type CollectionsOverviewProps = {
  className?: string;
  collections: ExtendedBucket[];
  selectedCollection: ExtendedBucket | undefined;
  onCollectionSelect: (collection?: ExtendedBucket) => void;
  onCreateDataCollectionClick?: () => void;
};

function CollectionsOverview({
  className,
  collections,
  selectedCollection,
  onCollectionSelect,
  onCreateDataCollectionClick,
}: CollectionsOverviewProps) {
  const { t } = useTranslation();
  const supabase = useBoundStore((state) => state.supabase);
  const workspaceId = useBoundStore((state) => state.workspaceId);

  const [totalFiles, setTotalFiles] = useState<
    { own: number; shared: number; public: number } | undefined
  >(undefined);

  useEffect(() => {
    supabase
      .from('file_info')
      .select('user_id,bucket_id')
      .eq('workspace_id', workspaceId)
      .then((result) => {
        if (result.data) {
          let ownCount = 0;
          let sharedCount = 0;
          let publicWorkspaceCount = 0;
          result.data.forEach((fileInfo) => {
            const containingCollection = collections.find(
              (collection) => collection.id === fileInfo.bucket_id
            );
            if (containingCollection?.is_public_for_workspace) {
              publicWorkspaceCount += 1;
            } else if (containingCollection?.is_shared_to_user) {
              sharedCount += 1;
            } else {
              ownCount += 1;
            }
          });

          setTotalFiles({
            own: ownCount,
            shared: sharedCount,
            public: publicWorkspaceCount,
          });
        }
      });
  }, [collections]);

  const ownCollections: ExtendedBucket[] = [];
  const sharedCollections: ExtendedBucket[] = [];
  const publicWorkspaceCollections: ExtendedBucket[] = [];
  collections.forEach((collection) => {
    if (collection.is_public_for_workspace) {
      publicWorkspaceCollections.push(collection);
    } else if (collection.is_shared_to_user) {
      sharedCollections.push(collection);
    } else {
      ownCollections.push(collection);
    }
  });

  const [ownCollectionsExpanded, setOwnCollectionsExpanded] = useState(false);
  const [sharedCollectionsExpanded, setSharedCollectionsExpanded] =
    useState(false);
  const [workspaceCollectionsExpanded, setWorkspaceCollectionsExpanded] =
    useState(false);

  useEffect(() => {
    // Expand the correct accordion based on the selected collection
    if (selectedCollection) {
      if (selectedCollection.is_public_for_workspace) {
        setWorkspaceCollectionsExpanded(true);
      } else if (selectedCollection.is_shared_to_user) {
        setSharedCollectionsExpanded(true);
      } else {
        setOwnCollectionsExpanded(true);
      }
    }
  }, [selectedCollection]);

  return (
    <div
      className={twMerge('flex min-h-0 flex-col gap-2 px-5 py-3', className)}
    >
      <Button
        className="text-maia-text-dark mb-1 flex-shrink-0 text-sm"
        size="md"
        leftIcon={
          <FontAwesomeIcon className="text-maia-accent" icon={faPlus} />
        }
        onClick={onCreateDataCollectionClick}
      >
        {t('fileManagerPanel.addCollectionButton')}
      </Button>
      <Divider />
      <div className="-mr-5 flex flex-col gap-2 overflow-y-auto pr-5">
        {/* ------------------------------------------------ Public workspace collections ------------------------------------------------ */}
        {publicWorkspaceCollections.length ? (
          <CollectionAccordion
            title={t('fileManagerPanel.publicWorkspaceCollections')}
            collections={publicWorkspaceCollections}
            expanded={workspaceCollectionsExpanded}
            onCollectionSelect={onCollectionSelect}
            onExpandToggle={() =>
              setWorkspaceCollectionsExpanded(!workspaceCollectionsExpanded)
            }
            selectedCollection={selectedCollection}
            totalFiles={totalFiles?.public ?? 0}
          />
        ) : null}
        {/* ------------------------------------------------ Own collections ------------------------------------------------ */}
        {ownCollections.length ? (
          <CollectionAccordion
            title={t('fileManagerPanel.yourCollections')}
            collections={ownCollections}
            expanded={ownCollectionsExpanded}
            onCollectionSelect={onCollectionSelect}
            onExpandToggle={() =>
              setOwnCollectionsExpanded(!ownCollectionsExpanded)
            }
            selectedCollection={selectedCollection}
            totalFiles={totalFiles?.own ?? 0}
          />
        ) : null}
        {/* ------------------------------------------------ Shared collections ------------------------------------------------ */}
        {sharedCollections.length ? (
          <CollectionAccordion
            title={t('fileManagerPanel.sharedWithYou')}
            collections={sharedCollections}
            expanded={sharedCollectionsExpanded}
            onCollectionSelect={onCollectionSelect}
            onExpandToggle={() =>
              setSharedCollectionsExpanded(!sharedCollectionsExpanded)
            }
            selectedCollection={selectedCollection}
            totalFiles={totalFiles?.shared ?? 0}
          />
        ) : null}
      </div>
    </div>
  );
}

export default CollectionsOverview;
