import { Button, Divider, FormLabel, Input, Text } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';

import ContentHeader from '../../components/ContentHeader.js';
import DeleteModal from '../../components/DeleteModal.js';
import LanguageSetting from './LanguageSetting.js';
import MaiaFullGridWidthContent from '../../components/layout/MaiaFullGridWidthContent.js';
import MaiaMainGrid from '../../components/layout/MaiaMainGrid.js';
import { ROUTES } from 'common-ts';
import SettingsSection from '../settings/SettingsSection.js';
import { USER_SETTINGS_TOAST_ID } from './userSettingsToastId.js';
import UpdateEmail from './UpdateEmail.js';
import UpdatePassword from './UpdatePassword.js';
import { captureException } from '@sentry/react';
import { faUser } from '@fortawesome/pro-regular-svg-icons';
import { fetchApi } from '../../utils/useApi.js';
import { useBoundStore } from '../../store/useBoundStore.js';
import { useNavigate } from 'react-router-dom';
import { useToastManagerHook } from '../../general/useToastManagerHook.js';
import { useTranslation } from 'react-i18next';
import { useTypedSearchParams } from 'react-router-typesafe-routes/dom';

export function PersonalSettings() {
  const { t } = useTranslation();
  const userInfo = useBoundStore((state) => state.userInfo);
  const updateWorkspaceInfo = useBoundStore(
    (state) => state.updateWorkspaceState
  );
  const workspaceId = useBoundStore((state) => state.workspaceId);
  const supabase = useBoundStore((state) => state.supabase);
  const navigate = useNavigate();
  const { showToast } = useToastManagerHook();

  const [{ resetPassword, setPassword }] = useTypedSearchParams(
    ROUTES.HOME.SETTINGS.ACCOUNT.PERSONAL
  );

  const [isLoading, setIsLoading] = useState(false);
  const firstName = useRef(userInfo?.firstName);
  const lastName = useRef(userInfo?.lastName);

  const [showUpdateEmail, setShowUpdateEmail] = useState(false);
  const [showUpdatePassword, setShowUpdatePassword] = useState(
    !!resetPassword || !!setPassword
  );
  const [deleteAccountModal, setDeleteAccountModal] = useState<
    string | undefined
  >(undefined);

  async function checkExpiredInviteLink() {
    const session = await supabase.auth.getSession();
    if (!session?.data.session) {
      // if user is invited but the link is not valid anymore
      if (setPassword) {
        showToast({
          id: USER_SETTINGS_TOAST_ID,
          title: t('userSettings.linkExpired'),
          status: 'warning',
        });
      }

      navigate(ROUTES.AUTH.SIGN_IN.buildPath({}));
      return;
    }
  }

  useEffect(() => {
    checkExpiredInviteLink();
  }, []);

  async function handleSave() {
    setIsLoading(true);
    const updatedUserSettingsData = {
      first_name: firstName.current,
      last_name: lastName.current,
    };

    if (!userInfo?.userId) return;

    try {
      const result = await supabase
        ?.from('user_setting')
        .update(updatedUserSettingsData)
        .eq('user_id', userInfo.userId);

      if (result.error) {
        throw new Error(result.error.message);
      }
      showToast({
        id: USER_SETTINGS_TOAST_ID,
        title: t('userSettings.saveSuccess'),
        status: 'success',
      });
    } catch (err) {
      if (err instanceof Error) {
        captureException(err);
        showToast({
          id: USER_SETTINGS_TOAST_ID,
          title: t('userSettings.saveError'),
          description: err.message,
          status: 'error',
        });
      }
    }

    updateWorkspaceInfo(workspaceId);
    setIsLoading(false);
  }

  return (
    <MaiaMainGrid>
      <MaiaFullGridWidthContent className="flex flex-col gap-4">
        <ContentHeader
          headingLabel={t('userSettings.menu.personal')}
          icon={faUser}
          buttons={[
            <Button
              size="sm"
              className="self-end"
              colorScheme="maia-accent"
              onClick={handleSave}
              isLoading={isLoading}
            >
              {t('userSettings.saveButton')}
            </Button>,
          ]}
          iconLabel={
            userInfo?.firstName
              ? `${userInfo.firstName} ${userInfo.lastName ?? ''}`
              : userInfo?.email ?? ''
          }
        />
        <div>
          <FormLabel>{t('userSettings.firstName')}</FormLabel>
          <Input
            maxLength={128}
            backgroundColor={'white'}
            placeholder={t('userSettings.firstName')}
            defaultValue={firstName.current}
            onChange={(e) => {
              firstName.current = e.target.value;
            }}
          />
        </div>
        <div>
          <FormLabel>{t('userSettings.lastName')}</FormLabel>
          <Input
            maxLength={128}
            backgroundColor={'white'}
            placeholder={t('userSettings.lastName')}
            defaultValue={lastName.current}
            onChange={(e) => {
              lastName.current = e.target.value;
            }}
          />
        </div>
        <div>
          <FormLabel>{t('userSettings.email')}</FormLabel>
          <Text fontSize={'md'}>{userInfo?.email}</Text>
        </div>
        {/* ------------------------------------------------ Update Email / PW ------------------------------------------------ */}
        {showUpdateEmail ? (
          <UpdateEmail onClose={() => setShowUpdateEmail(false)} />
        ) : showUpdatePassword ? (
          <UpdatePassword onClose={() => setShowUpdatePassword(false)} />
        ) : (
          <div className="flex gap-2">
            <Button
              variant={'outline'}
              className="text-maia-text-dark font-medium"
              onClick={() => setShowUpdateEmail(true)}
            >
              {t('userSettings.auth.changeMail')}
            </Button>
            <Button
              variant={'outline'}
              className="text-maia-text-dark font-medium"
              onClick={() => setShowUpdatePassword(true)}
            >
              {t('userSettings.auth.changePw')}
            </Button>
          </div>
        )}
        <Divider />
        <SettingsSection>{t('userSettings.languageHeader')}</SettingsSection>
        <div>
          <FormLabel>{t('userSettings.defaultLanguage')}</FormLabel>
          <LanguageSetting />
        </div>
        <Divider />
        <SettingsSection>{t('userSettings.dangerZone')}</SettingsSection>
        <Button
          className="self-start"
          colorScheme="red"
          onClick={() => {
            if (userInfo?.email) {
              setDeleteAccountModal(userInfo.email);
            }
          }}
        >
          {t('userSettings.accountDeletionTitle')}
        </Button>

        <DeleteModal
          title={t('userSettings.accountDeletionTitle')}
          subtitle={t('userSettings.accountDeletionSubtitle')}
          saveWord={deleteAccountModal}
          isOpen={!!deleteAccountModal}
          onConfirm={async (saveWord) => {
            if (saveWord && userInfo?.userId) {
              const result = await fetchApi(supabase, '/user', '/delete', {
                method: 'POST',
                email: saveWord,
              });
              if (!result.success) {
                if (result.status === 516) {
                  showToast({
                    id: USER_SETTINGS_TOAST_ID,
                    title: t('userSettings.workspaceOwnerError'),
                    status: 'error',
                    duration: 10000,
                  });
                } else {
                  showToast({
                    id: USER_SETTINGS_TOAST_ID,
                    title: t('general.tryAgainError'),
                    status: 'error',
                  });
                }
              } else {
                showToast({
                  id: USER_SETTINGS_TOAST_ID,
                  title: t('userSettings.accountDeletedMessage'),
                  status: 'success',
                });
                setTimeout(() => {
                  supabase?.auth.signOut().then(() => {
                    navigate(ROUTES.AUTH.SIGN_IN.buildPath({}));
                  });
                }, 1000);
              }
            }
          }}
          onClose={() => setDeleteAccountModal(undefined)}
        />
      </MaiaFullGridWidthContent>
    </MaiaMainGrid>
  );
}

export default PersonalSettings;
