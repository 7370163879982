import { SupabaseClient } from '@supabase/supabase-js';

export { getIsOnboarded, setIsOnboarded };

/**
 * Retrieves the onboarding status of the user from the database.
 *
 * @param client - The Supabase client instance.
 * @returns A promise that resolves to a boolean value indicating whether the
 * user has completed onboarding.
 */
async function getIsOnboarded(client: SupabaseClient): Promise<boolean> {
  const { data: user_setting, error } = await client
    .from('user_setting')
    .select('is_onboarded');
  if (error || !user_setting || user_setting.length === 0) {
    return false;
  } else {
    return user_setting[0]!.is_onboarded;
  }
}

/**
 * Sets the onboarding status for the user in the database.
 *
 * @param client - The Supabase client instance.
 * @param isOnboarded - A boolean value indicating whether the user has completed onboarding.
 * @returns A promise that resolves to an Error object if there's an error during
 * the update, or null if the update is successful.
 */
async function setIsOnboarded(
  client: SupabaseClient,
  isOnboarded: boolean
): Promise<Error | null> {
  const session = await client.auth.getSession();
  const userId = session.data?.session?.user.id;
  const { error } = await client
    .from('user_setting')
    .update({ is_onboarded: isOnboarded })
    .eq('user_id', userId);
  if (error) {
    return new Error(error.message);
  }

  return null;
}
