import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Textarea,
} from '@chakra-ui/react';
import { useEffect, useRef } from 'react';

import { useTranslation } from 'react-i18next';

type CorrectAnswerModalProps = {
  title: string;
  subtitle: string;
  answer: string;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (answer: string) => void;
};

function CorrectAnswerModal({
  title,
  subtitle,
  answer,
  isOpen,
  onClose,
  onConfirm,
}: CorrectAnswerModalProps) {
  const { t } = useTranslation();
  const cancelRef = useRef(null);
  const value = useRef<string>(answer);
  useEffect(() => {
    value.current = answer;
  }, [answer]);
  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent className="max-w-3xl">
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {title}
          </AlertDialogHeader>
          <AlertDialogBody>
            {subtitle}
            <Textarea
              defaultValue={answer}
              onChange={(e) => (value.current = e.currentTarget.value)}
            />
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose} variant="outline">
              {t('general.cancelButton')}
            </Button>
            <Button
              colorScheme="green"
              onClick={() => onConfirm(value.current)}
              ml={3}
            >
              {t('general.addTest')}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}

export default CorrectAnswerModal;
