/**
 * Determines the hierarchical level of a given license type. Higher levels indicate more advanced or inclusive licenses.
 *
 * @param license The license type from the predefined set in `licenseTier`.
 * @returns Returns a numerical level associated with the license type.
 *
 * @example
 * getPlanLevel('PROFESSIONAL'); // returns 3
 */
export function getPlanLevel(license) {
    switch (license) {
        case 'FREE':
            return 0;
        case 'BASIC':
            return 1;
        case 'PROFESSIONAL':
            return 2;
        case 'ENTERPRISE':
            return 3;
    }
}
