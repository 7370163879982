import { Button, ButtonProps, Tooltip } from '@chakra-ui/react';

import FileUploadModal from './FileUploadModal.js';
import MaiaAlertDialogWithUpgradeButton from '../../components/MaiaAlertDialogWithUpgradeButton.js';
import { TempFileObject } from './FileRow.js';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

type FileUploadButtonProps = {
  isDisabled?: boolean;
  buttonProps?: ButtonProps;
  collectionId: string;
  usedStorageMb: number;
  storageLimitMb: number;
  existingFileNames: string[];
  onFilesStartedUploading?: (files: TempFileObject[]) => void;
  onFilesFinishedUploading?: () => void;
};

function FileUploadButton({
  isDisabled,
  buttonProps,
  collectionId,
  usedStorageMb,
  storageLimitMb,
  existingFileNames,
  onFilesStartedUploading,
  onFilesFinishedUploading,
}: FileUploadButtonProps) {
  const { t } = useTranslation();

  const [upgradeModalOpen, setUpgradeModalOpen] = useState<
    'storage' | 'multiUpload' | undefined
  >(undefined);
  const [uploadModalOpen, setUploadModalOpen] = useState(false);

  return (
    <>
      <Tooltip
        label={isDisabled ? t('fileManagerPanel.disabledReason') : ''}
        openDelay={500}
      >
        <Button
          className="h-8"
          isDisabled={isDisabled}
          colorScheme="maia-purple"
          onClick={() => setUploadModalOpen(true)}
          {...buttonProps}
        >
          {t('fileManagerPanel.uploadFileButton')}
        </Button>
      </Tooltip>
      <MaiaAlertDialogWithUpgradeButton
        isOpen={!!upgradeModalOpen}
        onClose={() => setUpgradeModalOpen(undefined)}
        AlertDialogHeaderText={
          upgradeModalOpen === 'multiUpload'
            ? t('fileManagerPanel.multiUploadAttempt')
            : t('fileManagerPanel.storageLimitReached')
        }
        AlertDialogBodyText={
          upgradeModalOpen === 'multiUpload'
            ? t('fileManagerPanel.multiUploadAttemptBody')
            : t('fileManagerPanel.storageLimitReachedBody')
        }
      />
      <FileUploadModal
        isOpen={uploadModalOpen}
        onClose={() => setUploadModalOpen(false)}
        collectionId={collectionId}
        usedStorageMb={usedStorageMb}
        storageLimitMb={storageLimitMb}
        existingFileNames={existingFileNames}
        onFilesStartedUploading={(files) => {
          setUploadModalOpen(false);
          onFilesStartedUploading?.(files);
        }}
        onFilesFinishedUploading={onFilesFinishedUploading}
        onFreeStorageLimitReached={() => setUpgradeModalOpen('storage')}
        onFreeUserMultiFileAttempt={() => setUpgradeModalOpen('multiUpload')}
      />
    </>
  );
}

export default FileUploadButton;
