import { useCallback, useEffect, useRef, useState } from 'react';

import { MAX_SOCKET_RECONNECTS } from './socket';
import { Socket } from 'socket.io-client';
import { flushSync } from 'react-dom';

export function useSocketIo<
  ServerToClientMessages = any,
  ClientToServerMessages = any,
>({
  socket,
  onConnect: onConnectParam,
  onReconnectStop,
}: {
  socket: Socket;
  onConnect?: () => void;
  onReconnectStop?: () => void;
}) {
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [lastMessage, setLastMessage] = useState<
    ServerToClientMessages | undefined
  >(undefined);
  const reconnectAttempts = useRef(0);

  const sendMessage = useCallback(
    (message: ClientToServerMessages) => {
      socket.send(message);
    },
    [socket]
  );

  function onConnect() {
    setIsConnected(true);

    onConnectParam?.();
  }

  function onDisconnect() {
    setIsConnected(false);
  }

  function onMessage(value: ServerToClientMessages) {
    flushSync(() => {
      setLastMessage(value);
    });
  }

  function onConnectError() {
    reconnectAttempts.current += 1;
    if (reconnectAttempts.current > MAX_SOCKET_RECONNECTS) {
      onReconnectStop?.();
    }
  }

  useEffect(() => {
    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('message', onMessage);
    socket.on('connect_error', onConnectError);

    socket.connect();
    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.off('message', onMessage);
      socket.off('connect_error', onConnectError);
      socket.disconnect();
    };
  }, []);

  return { isConnected, lastMessage, sendMessage };
}
