import { Checkbox, CheckboxProps } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

type CheckboxOnChangeStateWrapProps = Omit<CheckboxProps, 'onChange'> & {
  onChange: (checked: boolean) => Promise<boolean>;
};

function CheckboxOnChangeStateWrap(props: CheckboxOnChangeStateWrapProps) {
  const [checked, setChecked] = useState(!!props.isChecked);
  const [indeterminateChecked, setIndeterminateChecked] = useState(false);

  useEffect(() => {
    setChecked(!!props.isChecked);
  }, [props.isChecked]);
  return (
    <Checkbox
      {...props}
      isChecked={checked}
      isIndeterminate={indeterminateChecked}
      onChange={async () => {
        setChecked(!checked);
        setIndeterminateChecked(true);
        const reallyChecked = await props.onChange(!checked);
        setIndeterminateChecked(false);
        setChecked(reallyChecked);
      }}
    />
  );
}

export default CheckboxOnChangeStateWrap;
