export var LayoutItemType;
(function (LayoutItemType) {
    LayoutItemType["Title"] = "Title";
    LayoutItemType["SectionHeading"] = "SectionHeading";
    LayoutItemType["Paragraph"] = "Paragraph";
    LayoutItemType["Table"] = "Table";
    LayoutItemType["PageHeader"] = "PageHeader";
    LayoutItemType["PageFooter"] = "PageFooter";
    LayoutItemType["Footnote"] = "Footnote";
})(LayoutItemType || (LayoutItemType = {}));
