import { useToast, ToastProps, ToastId } from '@chakra-ui/react';

const DEFAULT_TOAST_PROPS: ToastProps = {
  variant: 'left-accent',
  position: 'top-right',
  duration: 3000,
  isClosable: true,
};

export function useToastManagerHook() {
  const toast = useToast();

  const showToast = ({ title, status, ...rest }: ToastProps) => {
    // Prevent showing the same toast multiple times
    // if it's already active
    // and the id is provided
    if (rest.id && toast.isActive(rest.id)) return;

    const id = toast({
      ...DEFAULT_TOAST_PROPS,
      title,
      status,
      ...rest,
    });

    return id;
  };

  const updateToast = (toastId: ToastId, options: ToastProps) => {
    if (toastId) {
      toast.update(toastId, {
        ...DEFAULT_TOAST_PROPS,
        ...options,
      });
    }
  };

  const closeToast = (toastId: ToastId) => toast.close(toastId);
  const closeAllToasts = () => toast.closeAll();

  return { showToast, updateToast, closeToast, closeAllToasts };
}
