import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';

import BuyAdaPagesButton from '../../../components/ada/BuyAdaPagesButton';
import UpgradeSubscriptionButton from '../../../components/UpgradeSubscriptionButton';
import { t } from 'i18next';
import { useApi } from '../../../utils/useApi';
import { useBoundStore } from '../../../store/useBoundStore.js';

export type ConfirmNewAnalysisModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  fileDetails: { fileId: string; numberOfPages: number }[];
};

/**
 * ConfirmDeleteFileModal displays a modal asking for confirmation to delete files.
 *
 * @param isOpen - Determines if the modal is open.
 * @param onClose - Function to call when closing the modal.
 * @param onConfirm - Function to call when confirmation is made.
 * @param fileCount - Number of files to be deleted.
 */
export function ConfirmNewAnalysisModal({
  isOpen,
  onClose,
  onConfirm,
  fileDetails,
}: ConfirmNewAnalysisModalProps) {
  const supabase = useBoundStore((state) => state.supabase);
  const workspaceId = useBoundStore((state) => state.workspaceId);
  const workspaceLicenseType = useBoundStore(
    (state) => state.workspaceLicenseType
  );
  const featureFlags = useBoundStore((state) => state.featureFlags);
  const isStripeCustomer = useBoundStore((state) => state.isStripeCustomer);

  const { data } = useApi(
    supabase,
    '/embeddings',
    '/embed_premium_remaining_quota',
    {
      method: 'POST',
      workspaceId,
    }
  );

  const correctWorkspaceLicense =
    workspaceLicenseType && workspaceLicenseType !== 'FREE';

  const analyzeFeatureFlag = !!featureFlags?.analyze;

  const quotaConstraint =
    ((data?.pageLimitThisMonth ?? 0) -
      (data?.pagesUsedThisMonth ?? 0) +
      (data?.remainingAdditionalPages ?? 0) || 0) >
    fileDetails.reduce((acc, f) => acc + f.numberOfPages, 0);

  const constraintText = (
    <>
      {!correctWorkspaceLicense ? (
        <div>
          <span>
            {t('fileManagerPanel.fileAnalysisModal.workspaceLicense')}
          </span>
          <UpgradeSubscriptionButton size={'xs'}>
            Upgrade
          </UpgradeSubscriptionButton>
        </div>
      ) : !analyzeFeatureFlag ? (
        <span>{t('fileManagerPanel.fileAnalysisModal.featureFlagOff')}</span>
      ) : !quotaConstraint ? (
        isStripeCustomer ? (
          <div className="flex flex-col items-start gap-2">
            {t('fileManagerPanel.fileAnalysisModal.buyAdditionalPages')}
            <BuyAdaPagesButton redirectTo={window.location.pathname} />
          </div>
        ) : (
          <div>
            <span className="underline">
              {`${t('fileManagerPanel.fileAnalysisModal.contact')} `}
            </span>
            <a href="mailto:info@prodlane.io">info@prodlane.io</a>.
          </div>
        )
      ) : (
        <span className="text-maia-gray-700">
          {fileDetails.length > 1
            ? t('fileManagerPanel.fileAnalysisModal.bodyPlural', {
                documents: fileDetails.length,
                pages: fileDetails.reduce((acc, f) => acc + f.numberOfPages, 0),
              })
            : t('fileManagerPanel.fileAnalysisModal.bodySingular', {
                documents: fileDetails.length,
                pages: fileDetails.reduce((acc, f) => acc + f.numberOfPages, 0),
              })}
        </span>
      )}
    </>
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent className="w-full max-w-md">
        <ModalHeader>
          {t('fileManagerPanel.fileAnalysisModal.header')}
        </ModalHeader>
        <ModalBody>
          <div>{constraintText}</div>
        </ModalBody>
        <ModalFooter>
          <div className="flex items-center gap-2">
            <Button className="font-medium" onClick={onClose} variant="outline">
              {t('general.cancelButton')}
            </Button>
            <Button
              className="font-medium"
              isDisabled={
                !(
                  analyzeFeatureFlag &&
                  quotaConstraint &&
                  correctWorkspaceLicense
                )
              }
              colorScheme="maia-purple"
              onClick={onConfirm}
            >
              {t('general.executeButton')}
            </Button>
          </div>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
