import { Checkbox, TableColumnHeaderProps, Th } from '@chakra-ui/react';
import { ReactNode, useEffect, useRef, useState } from 'react';
import {
  faSort,
  faSortDown,
  faSortUp,
} from '@fortawesome/pro-duotone-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/pro-solid-svg-icons';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';

export enum SortDirection {
  UP,
  DOWN,
}

type TableHeaderProps = {
  children?: ReactNode;
  sortingDirection?: SortDirection;
  onClickSort?: (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
  onClickFilter?: (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
  showChevrons?: boolean;
  showFilter?: boolean;
  filterOpen?: boolean;
  filtersActive: boolean;
  filterOptions?: Set<string>;
  onFilterChange?: (selectedFilters: string[]) => void;
  onFilterClose: () => void;
} & TableColumnHeaderProps;

function TableHeader(props: TableHeaderProps) {
  const {
    children,
    sortingDirection,
    onClickSort,
    onClickFilter,
    showChevrons = true,
    showFilter = false,
    filterOpen = false,
    filtersActive,
    filterOptions,
    onFilterChange,
    onFilterClose,
    className,
    ...chakraProps
  } = props;

  const { t } = useTranslation();

  const [selectedFilters, setSelectedFilters] = useState<Set<string>>(
    filterOptions || new Set<string>()
  );
  const filterContainer = useRef<HTMLDivElement>(null);
  const filterSymbolRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        filterContainer.current &&
        !filterContainer.current.contains(event.target as Node) &&
        !filterSymbolRef.current?.contains(event.target as Node)
      ) {
        if (filterOpen) {
          onFilterClose();
        }
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [filterOpen]);

  useEffect(() => {
    if (onFilterChange) {
      onFilterChange(Array.from(selectedFilters));
    }
  }, [selectedFilters]);

  return (
    <Th
      {...chakraProps}
      className={twMerge('text-maia-text-dark font-semibold', className)}
    >
      <div className="flex items-center justify-between gap-2">
        {children}
        <div className="flex items-center gap-2 text-xs">
          <span
            className="cursor-pointer"
            onClick={(e) => {
              onClickSort ? onClickSort(e) : undefined;
            }}
          >
            {showChevrons ? (
              sortingDirection === SortDirection.DOWN ? (
                <FontAwesomeIcon icon={faSortDown} />
              ) : sortingDirection === SortDirection.UP ? (
                <FontAwesomeIcon icon={faSortUp} />
              ) : (
                <FontAwesomeIcon icon={faSort} />
              )
            ) : null}
          </span>
          <span
            ref={filterSymbolRef}
            className="cursor-pointer"
            onClick={(e) => {
              onClickFilter ? onClickFilter(e) : undefined;
            }}
          >
            {showFilter && (
              <FontAwesomeIcon
                icon={faFilter}
                className={`${
                  filterOpen
                    ? 'text-maia-support-blue'
                    : filtersActive
                      ? 'text-maia-support-green' // TODO: Hässlon sein Urgroßvater, ask Mathias for design
                      : 'text-chakra-gray-100'
                }`}
              />
            )}
          </span>
        </div>
      </div>
      {filterOpen && (
        <div
          ref={filterContainer}
          className="absolute top-14 z-10 flex max-h-60 w-56 flex-col rounded-b bg-white shadow-md"
        >
          <span className="border-b px-1 py-1">
            {t('sortableTable.filterResultsBy')} {children}
          </span>
          <div className="flex flex-col gap-2 overflow-y-auto px-1">
            {Array.from(filterOptions ?? [])?.map((value) => {
              return (
                <Checkbox
                  colorScheme="maia-purple"
                  className="hover:bg-maia-purple-50 rounded px-4"
                  isChecked={!selectedFilters.has(value)}
                  onChange={() => {
                    if (selectedFilters.has(value)) {
                      selectedFilters.delete(value);
                      setSelectedFilters(new Set(selectedFilters));
                    } else {
                      setSelectedFilters(new Set(selectedFilters.add(value)));
                    }
                  }}
                >
                  <span className="">{value}</span>
                </Checkbox>
              );
            })}
          </div>
          <button
            className="py-2"
            onClick={() => setSelectedFilters(new Set<string>())}
          >
            {t('sortableTable.clearFilters')}
          </button>
        </div>
      )}
    </Th>
  );
}

export default TableHeader;
