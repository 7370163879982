import { useDisclosure } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import Disable2FAModal from './modals/Disable2FAModal';
import EmailVerificationModal from './modals/EmailVerificationModal';
import SettingsSwitch from '../../settings/SettingsSwitch';
import { useTranslation } from 'react-i18next';
import { useToastManagerHook } from '../../../general/useToastManagerHook';
import { useBoundStore } from '../../../store/useBoundStore.js';

type EmailMFAProps = {
  setMfaCheck: (value: boolean) => void;
};

export default function EmailMFA({ setMfaCheck }: EmailMFAProps) {
  const { t } = useTranslation();
  const supabase = useBoundStore((state) => state.supabase);
  const userInfo = useBoundStore((state) => state.userInfo);
  const { showToast } = useToastManagerHook();

  const [isMFAEnabled, setIsMFAEnabled] = useState(false);
  const emailVerificationModal = useDisclosure();
  const disable2faModal = useDisclosure();

  const handleSwitchChange = () => {
    isMFAEnabled ? disable2faModal.onOpen() : emailVerificationModal.onOpen();
  };

  const fetchMFAstatus = async () => {
    const { data, error } = await supabase
      .from('user_setting')
      .select('is_email_mfa_enabled');

    if (error) {
      showToast({
        title: t('userSettings.security.generalError'),
        status: 'error',
      });
      console.error(error);
      return;
    }
    setIsMFAEnabled(!!data?.[0]?.is_email_mfa_enabled);
    setMfaCheck(true);
  };
  useEffect(() => {
    fetchMFAstatus();
  }, [emailVerificationModal.isOpen]);

  const handleDisableMFA = async () => {
    if (!userInfo?.userId) return;

    const { error } = await supabase
      .from('user_setting')
      .update({ is_email_mfa_enabled: false })
      .eq('user_id', userInfo.userId);

    if (error) {
      console.error(error);
      return;
    }

    setMfaCheck(true);
    setIsMFAEnabled(false);
    disable2faModal.onClose();
  };

  return (
    <>
      <SettingsSwitch
        title={t('userSettings.security.emailTitle')}
        subtitle={t('userSettings.security.emailSubtitle')}
        switchProps={{ isChecked: isMFAEnabled, onChange: handleSwitchChange }}
      />

      {disable2faModal.isOpen && (
        <Disable2FAModal
          title={t('userSettings.security.disable2FAModal.emailTitle')}
          subtitle={t('userSettings.security.disable2FAModal.emailSubtitle')}
          isOpen={disable2faModal.isOpen}
          onClose={disable2faModal.onClose}
          onConfirm={handleDisableMFA}
        />
      )}
      {emailVerificationModal.isOpen && (
        <EmailVerificationModal
          isOpen={emailVerificationModal.isOpen}
          onClose={emailVerificationModal.onClose}
        />
      )}
    </>
  );
}
