import { useEffect, useState } from 'react';

import TrialAboutToEndModal from './TrialAboutToEndModal';
import { useBoundStore } from '../../store/useBoundStore';

function TrialInfo() {
  const isInFreeTrial = useBoundStore((state) => state.isInFreeTrial);
  const userInfo = useBoundStore((state) => state.userInfo);
  const localStorageKey = `${userInfo?.userId}_trial_end_seen`;

  const [showTrialAboutToEndModal, setShowTrialAboutToEndModal] =
    useState(false);

  useEffect(() => {
    if (isInFreeTrial) {
      const trialEndModalLastSeenISO = localStorage.getItem(localStorageKey);

      // Don't show trial end modal again if user has already seen it within 24 hours
      const dayInMs = 24 * 60 * 60 * 1000;
      if (
        new Date().getTime() -
          new Date(trialEndModalLastSeenISO ?? '').getTime() <
        dayInMs
      ) {
        return;
      }

      const inThreeDays = new Date();
      inThreeDays.setDate(inThreeDays.getDate() + 3);

      setShowTrialAboutToEndModal(
        inThreeDays.getTime() > new Date(isInFreeTrial).getTime()
      );
    }
  }, []);

  function handleClose() {
    const now = new Date().toISOString();
    localStorage.setItem(localStorageKey, now);

    setShowTrialAboutToEndModal(false);
  }

  return (
    <TrialAboutToEndModal
      isOpen={showTrialAboutToEndModal}
      onClose={handleClose}
      trialEndTimestamp={isInFreeTrial ?? 0}
    />
  );
}

export default TrialInfo;
