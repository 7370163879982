import { Badge } from '@chakra-ui/react';
import { Database } from 'common-ts';

type WorkspaceUserRoleBadgeProps = {
  role:
    | Database['public']['Tables']['workspace_user']['Row']['user_type']
    | 'INVITED';
};

function WorkspaceUserRoleBadge({ role }: WorkspaceUserRoleBadgeProps) {
  return (
    <Badge
      size={'xs'}
      className={`${
        role === 'OWNER'
          ? 'bg-maia-brand-purple'
          : role === 'ADMIN'
            ? 'bg-maia-support-red'
            : role === 'USER'
              ? 'bg-maia-support-blue'
              : 'bg-chakra-gray-400'
      } text-xs text-white`}
    >
      {role}
    </Badge>
  );
}

export default WorkspaceUserRoleBadge;
