import { Button } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LogoPure from '../../components/Logo/LogoPure.js';
import { faFolderPlus } from '@fortawesome/pro-regular-svg-icons';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';

type NoCollectionProps = {
  className?: string;
  onAddDataCollectionClick: () => void;
};

function NoCollection({
  className,
  onAddDataCollectionClick,
}: NoCollectionProps) {
  const { t } = useTranslation();

  return (
    <div
      className={twMerge(
        'flex flex-col items-center justify-center',
        className
      )}
    >
      <LogoPure className="fill-maia-gray-300" size={55} />
      <div className="text-maia-gray-300 mt-9 text-center text-3xl font-bold">
        {t('fileManagerPanel.catchPhrase')}
      </div>
      <div className="mt-6 text-center">
        {t('fileManagerPanel.firstCollectionHint')}
      </div>
      <Button
        className="mt-8"
        colorScheme="maia-accent"
        variant="solid"
        leftIcon={<FontAwesomeIcon icon={faFolderPlus} />}
        onClick={onAddDataCollectionClick}
      >
        {t('fileManagerPanel.firstCollectionButton')}
      </Button>
    </div>
  );
}

export default NoCollection;
