import { MaiaApiRoutes, ROUTES } from 'common-ts';
import { Route, Routes } from 'react-router-dom';
import { useEffect, useState } from 'react';

import NotFoundPage from './NotFoundComponent';
import Search from './Search';
import { captureException } from '@sentry/react';
import { fetchApi } from '../../utils/useApi';
import { useBoundStore } from '../../store/useBoundStore.js';
import { useTypedParams } from 'react-router-typesafe-routes/dom';

export default function Container() {
  const { iframeId } = useTypedParams(ROUTES.IFRAME);
  const supabase = useBoundStore((state) => state.supabase);

  const [isValidIframe, setIsValidIframe] = useState<boolean | null>(null);
  const [iFrameSettings, setIFrameSettings] = useState<
    MaiaApiRoutes['/iframe']['/:iframe_id']['response'] | undefined
  >(undefined);

  const initialize = async () => {
    try {
      if (!iframeId) return;

      const endpoint = `/${iframeId}` as '/:iframe_id';
      const response = await fetchApi(
        supabase,
        '/iframe',
        endpoint,
        {
          method: 'GET',
          params: { iframe_id: iframeId },
        },
        true
      );

      setIsValidIframe(response.success && !!response.data.id);
      setIFrameSettings(response.data);
    } catch (error) {
      captureException(error);
      console.error(error);
    }
  };

  useEffect(() => {
    initialize();
  }, []);
  return (
    <>
      <Routes>
        {isValidIframe && iframeId && iFrameSettings ? (
          <Route
            path={ROUTES.IFRAME.$.SEARCH.relativePath}
            element={
              <Search iframeId={iframeId} iFrameSettings={iFrameSettings} />
            }
          />
        ) : null}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </>
  );
}
