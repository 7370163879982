import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';

type DocumentChatMessageGroupProps = {
  className?: string;
  mode: 'NORMAL' | 'POWER';
  children?: ReactNode;
};

function DocumentChatMessageGroup({
  className,
  mode,
  children,
}: DocumentChatMessageGroupProps) {
  const { t } = useTranslation();

  return (
    <div
      className={twMerge(
        `bg-maia-blue-100 ${
          mode === 'POWER' ? 'border-maia-accent' : 'border-maia-blue-300'
        } flex flex-col gap-3 rounded-2xl border px-2 pb-2 pt-0 md:px-5 md:pb-5`,
        className
      )}
    >
      <div
        className={`${
          mode === 'POWER'
            ? 'bg-maia-accent text-maia-blue-100'
            : 'bg-maia-blue-300 text-maia-blue-600'
        } self-start rounded-b px-3 py-1 text-xs font-medium`}
      >
        {mode === 'POWER' ? t('chat.powerModeQuery') : t('chat.documentQuery')}
      </div>
      {children}
    </div>
  );
}

export default DocumentChatMessageGroup;
