import { Progress, Skeleton, Text, Tooltip } from '@chakra-ui/react';

import AccessControl from '../AccessControl';
import BuyAdaPagesButton from './BuyAdaPagesButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons';
import { twMerge } from 'tailwind-merge';
import { useApi } from '../../utils/useApi';
import { useBoundStore } from '../../store/useBoundStore';
import { useEffect } from 'react';
import { useToastManagerHook } from '../../general/useToastManagerHook';
import { useTranslation } from 'react-i18next';

type AdaUsageProps = {
  className?: string;
};

function AdaUsage({ className }: AdaUsageProps) {
  const { t } = useTranslation();
  const workspaceId = useBoundStore((state) => state.workspaceId);
  const supabase = useBoundStore((state) => state.supabase);
  const isStripeCustomer = useBoundStore((state) => state.isStripeCustomer);

  const { showToast } = useToastManagerHook();

  const { data: adaUsage, error } = useApi(
    supabase,
    '/embeddings',
    '/embed_premium_remaining_quota',
    {
      method: 'POST',
      workspaceId,
    }
  );

  useEffect(() => {
    if (error) {
      showToast({
        title: t('components.ada.loadAdaUsageError'),
        status: 'error',
      });
    }
  }, [error]);

  return (
    <div
      className={twMerge(
        'flex flex-col items-center gap-2.5 px-5 py-2',
        className
      )}
    >
      {adaUsage ? (
        <>
          <Progress
            className="w-full"
            size={'sm'}
            value={Math.ceil(
              (adaUsage.pagesUsedThisMonth / adaUsage.pageLimitThisMonth) * 100
            )}
          />
          <div className="text-xs font-medium">{`${adaUsage.pagesUsedThisMonth}/${adaUsage.pageLimitThisMonth} ${t('components.ada.adaPagesUsed')}`}</div>
          {adaUsage.remainingAdditionalPages ? (
            <div className="flex items-center gap-2">
              <div className="text-xs font-medium">{`${t('components.ada.additionalPagesLeft')}: ${adaUsage.remainingAdditionalPages}`}</div>
              <Tooltip label={t('components.ada.additionalPagesTooltip')}>
                <FontAwesomeIcon
                  className="cursor-pointer text-xs"
                  icon={faQuestionCircle}
                />
              </Tooltip>
            </div>
          ) : null}
          <AccessControl allowedLicenses={['ENTERPRISE', 'PROFESSIONAL']}>
            <Tooltip label={t('components.ada.adaLimitTooltip')}>
              <Text className="cursor-help text-xs">
                ({t('fileManagerPanel.wholeWorkspace')})
              </Text>
            </Tooltip>
          </AccessControl>
          {isStripeCustomer &&
          adaUsage.pageLimitThisMonth -
            adaUsage.pagesUsedThisMonth +
            adaUsage.remainingAdditionalPages <
            50 ? (
            <BuyAdaPagesButton
              redirectTo={window.location.pathname}
              withInfoTooltip
            />
          ) : null}
        </>
      ) : (
        <>
          <Skeleton className="h-2 w-full" />
          <Skeleton className="h-5 w-2/3" />
        </>
      )}
    </div>
  );
}

export default AdaUsage;
