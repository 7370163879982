import { Link } from 'react-router-dom';
import { ReactNode } from 'react';

type DisabledReactRouterLinkProps = {
  isDisabled: boolean;
  to: string;
  children: ReactNode;
};

function DisabledReactRouterLink({
  isDisabled,
  to,
  children,
}: DisabledReactRouterLinkProps) {
  return isDisabled ? (
    <span style={{ cursor: 'not-allowed' }}>{children}</span>
  ) : (
    <Link to={to}>{children}</Link>
  );
}

export default DisabledReactRouterLink;
