import { Progress, Tooltip } from '@chakra-ui/react';

import { Database } from 'common-ts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';

export type Uploading = {
  type: 'UPLOADING';
};

export type BeingDeleted = {
  type: 'BEING_DELETED';
};

export type BeingAnalyzed = {
  type: 'BEING_ANALYZED';
};

export type Status = {
  type: Database['public']['Enums']['embedding_status'];
};

export type FileRowProgressUpdate = {
  status: Status | Uploading | BeingDeleted | BeingAnalyzed;
  failReason?: Database['public']['Enums']['embedding_fail_reason'] | null;
};

type FileProgressProps = {
  progressUpdate: FileRowProgressUpdate | undefined;
  fileType: string;
};

function FileProgress({ progressUpdate, fileType }: FileProgressProps) {
  const { t } = useTranslation();

  switch (progressUpdate?.status.type) {
    case 'FINISHED':
      return (
        <div className="text-maia-support-green">
          {t('fileManagerPanel.progressStateFinished')}
        </div>
      );
    case 'ONGOING':
      return (
        <div className="text-maia-brand-blue">
          <span className="pr-2">
            {t('fileManagerPanel.progressStateProgress')}
          </span>
        </div>
      );
    case 'UPLOADING':
      return (
        <div className="text-maia-support-blue">
          <Progress
            className="h-full w-full"
            size={'xs'}
            borderRadius={'full'}
            colorScheme="maia-blue"
            isIndeterminate
          />
        </div>
      );
    case 'FAILED':
      const failReasonTranslationSelector = `fileManagerPanel.fileFailReason.${progressUpdate.failReason}`;
      const failReasonString = progressUpdate.failReason
        ? // @ts-ignore
          ` (${t(failReasonTranslationSelector)})`
        : '';
      return (
        <div className="text-maia-support-red flex items-center">
          {`${t('fileManagerPanel.progressStateFailed')}${failReasonString}`}
          {progressUpdate?.failReason === 'FILE_EMPTY' &&
            fileType === 'pdf' && (
              <Tooltip
                label={
                  progressUpdate?.failReason === 'FILE_EMPTY' &&
                  fileType.toUpperCase() === 'PDF'
                    ? t('fileManagerPanel.tooltips.adaMightHelp')
                    : ''
                }
              >
                <FontAwesomeIcon className="ml-2" icon={faQuestionCircle} />
              </Tooltip>
            )}
        </div>
      );
    case 'BEING_DELETED':
      return (
        <div className="text-maia-support-red">
          <span className="pr-2">
            {t('fileManagerPanel.progressStateBeingDeleted')}
          </span>
        </div>
      );
    case 'BEING_ANALYZED':
      return (
        <div className="text-maia-accent">
          <span className="pr-2">
            {t('fileManagerPanel.progressStateBeingAnalyzed')}
          </span>
        </div>
      );
    default:
      return (
        <div className="text-maia-support-yellow">
          <span className="pr-2">
            {t('fileManagerPanel.progressStatePending')}
          </span>
        </div>
      );
  }
}

export default FileProgress;
