import { ChakraTheme, createMultiStyleConfigHelpers } from '@chakra-ui/react';

import { tableAnatomy } from '@chakra-ui/anatomy';

const modalHelpers = createMultiStyleConfigHelpers(['modal', 'header']);
const tableHelpers = createMultiStyleConfigHelpers(tableAnatomy.keys);

const Modal = modalHelpers.defineMultiStyleConfig({
  baseStyle: {
    header: {
      textColor: '#3D2643', // maia-gray-900
      fontSize: 'large',
      fontWeight: 'bold',
    },
  },
});

const Table = tableHelpers.defineMultiStyleConfig({
  baseStyle: {
    th: {
      textColor: 'maia-text-dark',
    },
  },
});

export const themeExtension: Partial<ChakraTheme> = {
  fonts: {
    body: `'Poppins', 'Helvetica', 'Arial', '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'sans-serif'`,
  },
  fontSizes: {
    xs: '0,75rem',
    sm: '0.875rem',
    md: '1rem',
    lg: '1.125rem',
    xl: '1.25rem',
    '2xl': '1.5rem',
    '3xl': '1.875rem',
    '4xl': '2.25rem',
    '5xl': '3rem',
    '6xl': '4rem',
  },
  colors: {
    'maia-blue': {
      50: '#FAFCFE',
      100: '#F3FAFE',
      200: '#EEEBFD',
      300: '#DEDAF6',
      400: '#CCC7EE',
      500: '#8D89BC',
      600: '#69649D',
      700: '#5A5781',
      800: '#343257',
      900: '#272644',
      1000: '#171635',
    },
    'maia-purple': {
      100: '#FAEAFD',
      200: '#F4D7FB',
      300: '#E7B0F4',
      400: '#D77EED',
      500: '#CB4EEA',
      600: '#A420C5',
      700: '#80199A',
      800: '#5B126E',
      900: '#370B42',
    },
    'maia-gray': {
      100: '#FAF6FB',
      200: '#F2E9F4',
      300: '#E1D4E4',
      400: '#B9A6BE',
      500: '#9C81A3',
      600: '#7F6386',
      700: '#6B4E73',
      800: '#523758',
      900: '#3D2643',
      1000: '#251529',
    },
    'maia-accent': {
      50: '#f4e8ff',
      100: '#ebd5ff',
      200: '#dbb5fd',
      300: '#c485fb',
      400: '#ae57f5',
      500: '#BE3ADF',
      600: '#8424cc',
      700: '#7022a7',
      800: '#5c1d86',
      900: '#3e0863',
    },
    'maia-text-dark': '#3D2643',
  },
  components: {
    FormLabel: {
      baseStyle: {
        textColor: '#3D2643', // maia-gray-900
        fontSize: 'sm',
        fontWeight: 'medium',
        mb: '1.5',
      },
    },
    Modal,
    Button: {
      defaultProps: {
        size: 'sm',
        class: 'text-sm',
      },
    },
    Input: {
      defaultProps: {
        focusBorderColor: '#BE3ADF',
        size: 'sm',
      },
    },
    TextArea: {
      defaultProps: {
        class: 'focus-visible:shadow-none',
      },
    },
    Divider: {
      baseStyle: {
        color: '#E2E8F0', // maia-border
      },
    },
    Table,
  },
};
