import { useEffect, useState } from 'react';

import { Database } from 'common-ts';
import GenericBanner from './GenericBanner';
import { captureException } from '@sentry/react';
import { useBoundStore } from '../../store/useBoundStore';

const POLL_BANNERS_INTERVAL_MS = 5 * 60 * 1000; // 5 Minutes;

function GenerciBanners() {
  const supabase = useBoundStore((state) => state.supabase);
  const [banners, setBanners] = useState<
    Database['public']['Views']['user_banner_view']['Row'][]
  >([]);

  useEffect(() => {
    async function fetchBanners() {
      const { data, error } = await supabase
        .from('user_banner_view')
        .select('*');

      if (error) {
        // If there is an error, the banner will simply not be displayed, showing an error could be confusing.
        captureException(error);
      }

      setBanners(data ?? []);
    }

    fetchBanners();

    const interval = setInterval(fetchBanners, POLL_BANNERS_INTERVAL_MS);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return banners.map((banner) => {
    return (
      <GenericBanner
        key={banner.id}
        body={banner.body}
        title={banner.title}
        status_type={banner.status_type}
        id={banner.id}
        workspace_id={banner.workspace_id}
      />
    );
  });
}

export default GenerciBanners;
