import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';

import { useTranslation } from 'react-i18next';

type GenerateInviteLinkWarningModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

function GenerateInviteLinkWarningModal({
  isOpen,
  onClose,
  onConfirm,
}: GenerateInviteLinkWarningModalProps) {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent className="w-full max-w-md">
        <ModalHeader>
          {t('workspaceSettings.generateNewLinkWarningTitle')}
        </ModalHeader>
        <ModalBody>
          <div>{t('workspaceSettings.generateNewLinkWaring')}</div>
        </ModalBody>
        <ModalFooter>
          <div className="flex items-center gap-2">
            <Button className="font-medium" onClick={onClose} variant="outline">
              {t('general.cancelButton')}
            </Button>
            <Button
              className="font-medium"
              colorScheme="maia-purple"
              onClick={onConfirm}
            >
              {t('workspaceSettings.generateInviteLinkButton')}
            </Button>
          </div>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default GenerateInviteLinkWarningModal;
