import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react';

import React from 'react';
import { useTranslation } from 'react-i18next';

type UnsavedChangesModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onDiscard: () => void;
  onSave?: () => void;
};

/**
 * Modal to handle unsaved changes.
 * The modal will prompt the user to save, discard or cancel the changes.
 * @param isOpen - Whether the modal is open.
 * @param onClose - Callback when the modal is closed or the user cancels the action.
 * @param onDiscard - Callback when the user discards the changes.
 * @param onSave - Callback when the user saves the changes.
 */
export const UnsavedChangesModal = ({
  isOpen,
  onClose,
  onDiscard,
  onSave,
}: UnsavedChangesModalProps) => {
  const { t } = useTranslation();
  const cancelRef = React.useRef<HTMLButtonElement>(null);
  return (
    <AlertDialog
      closeOnEsc={false}
      closeOnOverlayClick={false}
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {t('general.unsavedChanges')}
          </AlertDialogHeader>

          <AlertDialogBody>
            {t('promptLibrary.unsavedChangesDialogBody')}
          </AlertDialogBody>

          <AlertDialogFooter gap={3}>
            <Button ref={cancelRef} onClick={onClose} variant="outline">
              {t('general.cancelButton')}
            </Button>
            <Button colorScheme="red" onClick={onDiscard}>
              {t('general.discard')}
            </Button>
            <Button colorScheme="green" onClick={onSave}>
              {t('general.saveButtons')}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
