import {
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';

import AccessControl from '../../../components/AccessControl.js';
import { fetchApi } from '../../../utils/useApi.js';
import { useBoundStore } from '../../../store/useBoundStore.js';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';

type CreateCollectionModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onCollectionCreated?: (collectionId: string) => void;
};

function CreateCollectionModal({
  isOpen,
  onClose,
  onCollectionCreated,
}: CreateCollectionModalProps) {
  const { t } = useTranslation();
  const supabase = useBoundStore((state) => state.supabase);
  const workspaceId = useBoundStore((state) => state.workspaceId);

  const collectionNameRef = useRef(t('fileManagerPanel.newCollectionName'));
  const [collectionNameError, setCollectionNameError] = useState(false);
  const [makeCollectionPublic, setMakeCollectionPublic] = useState(false);

  const [creatingCollectionLoading, setCreatingCollectionLoading] =
    useState(false);

  async function handleCreateNewCollection() {
    setCreatingCollectionLoading(true);
    if (!collectionNameRef.current) {
      setCollectionNameError(true);
      return;
    }
    const bucketId = v4();
    setCollectionNameError(false);
    const { data } = await fetchApi(supabase, '/files', '/create_collection', {
      name: collectionNameRef.current,
      uuid: bucketId,
      method: 'POST',
      workspaceId,
      publicInWorkspace: makeCollectionPublic,
    });
    if (data?.id) onCollectionCreated?.(data.id);
    setCreatingCollectionLoading(false);

    onClose();
  }

  useEffect(() => {
    if (!isOpen) {
      collectionNameRef.current = t('fileManagerPanel.newCollectionName');
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} motionPreset="slideInBottom">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {t('fileManagerPanel.newCollectionModalHeader')}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody className="flex flex-col gap-4">
          <FormControl isInvalid={collectionNameError}>
            <FormLabel>
              {t('fileManagerPanel.newCollectionModalInputLabel')}
            </FormLabel>
            <Input
              autoFocus
              onChange={(e) => (collectionNameRef.current = e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleCreateNewCollection();
                }
              }}
              defaultValue={collectionNameRef.current}
            />
            {collectionNameError && (
              <FormErrorMessage>
                {t('fileManagerPanel.newCollectionInputEmptyError')}
              </FormErrorMessage>
            )}
          </FormControl>
          <AccessControl
            allowedLicenses={['PROFESSIONAL', 'ENTERPRISE']}
            allowedRoles={['ADMIN', 'OWNER']}
          >
            <Checkbox
              isChecked={makeCollectionPublic}
              onChange={(e) => {
                setMakeCollectionPublic(e.target.checked);
              }}
            >
              {t('fileManagerPanel.makePublicWorkspaceCollection')}
            </Checkbox>
          </AccessControl>
        </ModalBody>

        <ModalFooter className="gap-2">
          <Button className="font-medium" onClick={onClose} variant="outline">
            {t('general.cancelButton')}
          </Button>
          <Button
            className="font-medium"
            colorScheme="maia-accent"
            onClick={handleCreateNewCollection}
            isLoading={creatingCollectionLoading}
          >
            {t('fileManagerPanel.newCollectionModalConfirmButton')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default CreateCollectionModal;
