import {
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
} from '@chakra-ui/react';
import { CreatableSelect, MultiValue } from 'chakra-react-select';

import { Database } from 'common-ts';
import { useState } from 'react';
import { useToastManagerHook } from '../../general/useToastManagerHook';
import { useTranslation } from 'react-i18next';

type AddUserModalProps = {
  isOpen: boolean;
  alreadyPartOfWorkspaceUserMails: string[];
  onClose: () => void;
  onInvite: (
    emails: MultiValue<{ label: string; value: string }>,
    role: Database['public']['Enums']['workspace_user_type']
  ) => void;
};

function AddUserModal({
  isOpen,
  alreadyPartOfWorkspaceUserMails,
  onClose,
  onInvite,
}: AddUserModalProps) {
  const { t } = useTranslation();
  const { showToast } = useToastManagerHook();

  const [emails, setEmails] = useState<
    MultiValue<{ label: string; value: string }>
  >([]);
  const [role, setRole] =
    useState<Database['public']['Enums']['workspace_user_type']>('USER');

  function handleClose() {
    setEmails([]);
    setRole('USER');
    onClose();
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('workspaceSettings.addUserModal.title')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody className="flex flex-col gap-4">
          <CreatableSelect<{ label: string; value: string }, true>
            isMulti
            selectedOptionStyle="check"
            noOptionsMessage={() => ''}
            formatCreateLabel={(currentVal) => {
              return `${t(
                'workspaceSettings.addUserModal.createOptionLabel'
              )} '${currentVal}'`;
            }}
            components={{ DropdownIndicator: null }}
            onChange={(val, actionMeta) => {
              if (
                actionMeta.option &&
                alreadyPartOfWorkspaceUserMails.includes(
                  actionMeta.option?.value
                )
              ) {
                showToast({
                  title: t(
                    'workspaceSettings.addUserModal.alreadyMemberWarning'
                  ),
                  status: 'warning',
                });
                return;
              }
              setEmails(val);
            }}
            value={emails}
          />
          <FormControl>
            <FormLabel />
            <Select
              size={'sm'}
              onChange={(e) => {
                setRole(
                  e.target
                    .value as Database['public']['Enums']['workspace_user_type']
                );
              }}
            >
              <option value={'USER'}>User</option>
              <option value={'ADMIN'}>Admin</option>
            </Select>
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <div className="flex items-center gap-2">
            <Button variant="outline" onClick={handleClose}>
              {t('general.cancelButton')}
            </Button>
            <Button
              colorScheme="maia-accent"
              onClick={() => {
                onInvite(emails, role);
                handleClose();
              }}
              isDisabled={emails.length < 1}
            >
              {t('workspaceSettings.addUserModal.inviteButton')}
            </Button>
          </div>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default AddUserModal;
