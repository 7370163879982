import { CHAT_ROUTE_PREFIX, SEARCH_ROUTE_PREFIX } from 'common-ts';

import { io } from 'socket.io-client';

// Disclaimer: This works differently than HTTP Routes. The Socket IO server listens at VITE_MAIA_CORE_API_PUBLIC_URL/socket.io.
// It has namespaces (CHAT_ROUTE_PREFIX and SEARCH_ROUTE_PREFIX)
const CHAT_URL = `${import.meta.env.VITE_MAIA_CORE_API_PUBLIC_URL}${CHAT_ROUTE_PREFIX}`;
const SEARCH_URL = `${import.meta.env.VITE_MAIA_CORE_API_PUBLIC_URL}${SEARCH_ROUTE_PREFIX}`;
const IFRAME_SEARCH_URL = `${import.meta.env.VITE_MAIA_IFRAME_CORE_API_PUBLIC_URL}${SEARCH_ROUTE_PREFIX}`;

export const MAX_SOCKET_RECONNECTS = 20;

export const chatSocket = io(CHAT_URL, {
  autoConnect: false,
  reconnectionAttempts: MAX_SOCKET_RECONNECTS,
  withCredentials: true,
});

export const searchSocket = io(SEARCH_URL, {
  autoConnect: false,
  reconnectionAttempts: MAX_SOCKET_RECONNECTS,
  withCredentials: true,
});

export const iframeSearchSocket = io(IFRAME_SEARCH_URL, {
  autoConnect: false,
  reconnectionAttempts: MAX_SOCKET_RECONNECTS,
  withCredentials: true,
});
