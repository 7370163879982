import { Database } from 'common-ts';
import { ExtendedBucket } from '../@types/extendedTypes.js';
import { SupabaseClient } from '@supabase/supabase-js';
import { captureException } from '@sentry/react';

export async function getExtendedBuckets(
  client: SupabaseClient<Database>,
  userId: string,
  workspaceId: string
): Promise<ExtendedBucket[]> {
  const bucketsRes = await client.storage.listBuckets();
  const buckets = bucketsRes.data || [];

  const { data: bucketInfoData } = await client
    .from('bucket_info')
    .select('bucket_id,name,workspace_id,is_public_for_workspace')
    .is('deleted_at', null);

  const { data: bucketSharedData } = await client
    .from('shared_buckets')
    .select('bucket_id,user_id,access_type')
    .eq('user_id', userId);

  const { data: workspaceMembershipData, error: workspaceMembershipError } =
    await client
      .from('workspace_user')
      .select('workspace_id')
      .eq('user_id', userId);

  if (workspaceMembershipError) {
    captureException(workspaceMembershipError);
  }

  const bucketInfoMap: {
    [bucketId: string]: {
      name: string;
      workspaceId: string;
      isPublicForWorkspace: boolean;
    };
  } = Object.fromEntries(
    bucketInfoData?.map((b) => [
      b.bucket_id,
      {
        name: b.name,
        workspaceId: b.workspace_id,
        isPublicForWorkspace: b.is_public_for_workspace,
      },
    ]) || []
  );

  const bucketSharedMap = Object.fromEntries(
    bucketSharedData?.map((b) => [
      b.bucket_id,
      { userId: b.user_id, accessType: b.access_type },
    ]) || []
  );

  return buckets
    .map((bucket) => {
      const shared = bucket.owner !== userId;
      const bucketInfo = bucketInfoMap[bucket.id];

      // If the bucket info is missing, log an error and skip this bucket
      if (!bucketInfo) {
        console.error(`Missing bucket info for bucket ID: ${bucket.id}`);
        return null;
      }

      const extended: ExtendedBucket = {
        ...bucket,
        display_name: bucketInfo.name,
        is_shared_to_user: shared,
        access_type: shared ? bucketSharedMap[bucket.id]?.accessType : 'OWN',
        workspace_id: bucketInfo.workspaceId,
        show_in_workspace_id: bucketInfo.workspaceId, // TODO Probably obsolete with new sharing logic
        is_public_for_workspace: bucketInfo.isPublicForWorkspace,
      };
      return extended;
    })
    .filter(
      (bucket): bucket is ExtendedBucket =>
        bucket !== null &&
        (bucket.workspace_id === workspaceId ||
          // The only case in which collections that are not part of the current workspace should be shown is if they are shared from a workspace that the user is not a member of
          (bucket.id in bucketSharedMap &&
            !workspaceMembershipData
              ?.map((workspaceMembership) => workspaceMembership.workspace_id)
              .includes(bucket.workspace_id)))
    ) // Remove nulls and buckets not in the current workspace, except shared buckets
    .sort((bucketA, bucketB) =>
      bucketA.display_name.localeCompare(bucketB.display_name)
    );
}

export async function getUserInfo(supabaseClient: SupabaseClient) {
  const { data: sessionData, error: sessionError } =
    await supabaseClient.auth.getSession();

  if (!sessionData.session || sessionError) {
    console.error("User session not found while getting user's info");
    return;
  }

  const settings = await supabaseClient
    .from('user_setting')
    .select('first_name,last_name')
    .eq('user_id', sessionData.session.user.id)
    .limit(1)
    .single();

  return {
    userId: sessionData.session.user.id,
    email: sessionData.session.user.email ?? '',
    firstName: settings?.data?.first_name ?? '',
    lastName: settings?.data?.last_name ?? '',
  };
}

export async function fetchSentRequests(supabaseClient: SupabaseClient) {
  const { data: sessionData, error: sessionError } =
    await supabaseClient.auth.getSession();

  if (!sessionData.session || sessionError) {
    console.error('User session not found while fetching sent requests');
    return 0;
  }

  const today = new Date();
  const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  const { data: searchData, error: searchError } = await supabaseClient
    .from('search_request_view')
    .select('id')
    .gt('created_at', firstDayOfMonth.toISOString())
    .lt('created_at', today.toISOString());
  const { data: chatData, error: chatError } = await supabaseClient
    .from('chat_request_view')
    .select('id')
    .gt('created_at', firstDayOfMonth.toISOString())
    .lt('created_at', today.toISOString());
  const { data: chatDocData, error: chatDocError } = await supabaseClient
    .from('chat_document_request_view')
    .select('id')
    .gt('created_at', firstDayOfMonth.toISOString())
    .lt('created_at', today.toISOString());

  // TODO: Investigate if the problem still persists.
  // Assumption: session is invalid, so unable to get results
  // Root cause: supabase refresh token is not working as intended: https://github.com/supabase/auth-helpers/issues/436
  if (searchError || chatError || chatDocError) {
    throw new Error('Could not fetch sent messages');
  }

  return searchData.length + chatData.length + chatDocData.length;
}
