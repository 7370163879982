import { TFunction } from 'i18next';

export enum HistoryItemCategory {
  TODAY,
  YESTERDAY,
  LAST_7_DAYS,
  LAST_30_DAYS,
  OLDER_THAN_A_MONTH,
}

export function getCategoryTranslation(
  category: HistoryItemCategory,
  tFunction: TFunction
) {
  switch (category) {
    case HistoryItemCategory.TODAY:
      return tFunction('chat.category.today');
    case HistoryItemCategory.YESTERDAY:
      return tFunction('chat.category.yesterday');
    case HistoryItemCategory.LAST_7_DAYS:
      return tFunction('chat.category.last7days');
    case HistoryItemCategory.LAST_30_DAYS:
      return tFunction('chat.category.last30days');
    case HistoryItemCategory.OLDER_THAN_A_MONTH:
      return tFunction('chat.category.olderThanAMonth');
  }
}

/**
 * Determines the category of a chat based on its creation date.
 * @param createdAt - The creation date of the chat.
 * @returns The determined category of the chat.
 */
export const determineHistoryItemCategory = (
  createdAt: Date
): HistoryItemCategory => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  const last7Days = new Date(today);
  last7Days.setDate(today.getDate() - 7);

  const last30Days = new Date(today);
  last30Days.setDate(today.getDate() - 30);

  if (createdAt.getTime() >= today.getTime()) {
    return HistoryItemCategory.TODAY;
  } else if (
    createdAt.getTime() > yesterday.getTime() &&
    createdAt.getTime() < today.getTime()
  ) {
    return HistoryItemCategory.YESTERDAY;
  } else if (
    createdAt.getTime() > last7Days.getTime() &&
    createdAt.getTime() < today.getTime()
  ) {
    return HistoryItemCategory.LAST_7_DAYS;
  } else if (
    createdAt.getTime() > last30Days.getTime() &&
    createdAt.getTime() <= last7Days.getTime()
  ) {
    return HistoryItemCategory.LAST_30_DAYS;
  } else {
    return HistoryItemCategory.OLDER_THAN_A_MONTH;
  }
};
