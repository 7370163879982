import {
  Button,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import {
  DetailedHTMLProps,
  HTMLAttributes,
  useEffect,
  useRef,
  useState,
} from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ListItem from './ListItem.js';
import { PostgrestSingleResponse } from '@supabase/supabase-js';
import { captureException } from '@sentry/react';
import { faEllipsisVertical } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import { useToastManagerHook } from '../general/useToastManagerHook.js';

type HistoryItemProps = {
  chatId: string;
  selected: boolean;
  defaultTopic: string;
  chatTopic: string | undefined;
  onRename: (name: string) => Promise<PostgrestSingleResponse<null>>;
  domElementProps?: DetailedHTMLProps<
    HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >;
  onDeleteClick: () => void;
};

function HistoryItem({
  selected,
  chatTopic,
  defaultTopic,
  domElementProps,
  onRename,
  onDeleteClick,
}: HistoryItemProps) {
  const { t } = useTranslation();
  const { showToast } = useToastManagerHook();

  const renamingDivRef = useRef<HTMLDivElement>(null);

  const [topic, setTopic] = useState(chatTopic);

  useEffect(() => {
    setTopic(chatTopic);
  }, [chatTopic]);

  const [renaming, setRenaming] = useState(false);
  const newNameRef = useRef('');

  async function handleChatRename() {
    // TODO think about where to set the topic like cache ...
    if (!newNameRef.current) {
      newNameRef.current = topic ?? '';
      return;
    }
    const error = await onRename(newNameRef.current);

    if (error.error !== null) {
      captureException(error.error);
      showToast({ title: t('general.tryAgainError'), status: 'error' });
      return;
    }

    setTopic(newNameRef.current);
  }

  return (
    <ListItem
      className={`group w-full flex-shrink-0 justify-between ${
        renaming && 'h-auto py-1.5'
      }`}
      variant="left"
      selected={selected}
      domElementProps={domElementProps}
    >
      {renaming ? (
        <div className="flex flex-col gap-2.5" ref={renamingDivRef}>
          <Input
            autoFocus
            onFocus={(e) => {
              e.target.select();
            }}
            className="bg-white"
            defaultValue={topic}
            onChange={(e) => {
              newNameRef.current = e.target.value;
            }}
            onBlur={(e) => {
              if (!renamingDivRef.current?.contains(e.relatedTarget)) {
                handleChatRename();
                setRenaming(false);
              }
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                handleChatRename();
                setRenaming(false);
              }
            }}
          />
          <div className="flex items-center gap-2">
            <Button
              className="font-medium"
              variant="outline"
              size="xs"
              onClick={(e) => {
                setRenaming(false);
                e.stopPropagation();
                e.preventDefault();
              }}
            >
              {t('general.cancelButton')}
            </Button>
            <Button
              className="bg-maia-accent text-maia-text-light font-medium"
              size="xs"
              onClick={(e) => {
                handleChatRename();
                setRenaming(false);
                e.stopPropagation();
                e.preventDefault();
              }}
            >
              {t('general.saveButtons')}
            </Button>
          </div>
        </div>
      ) : (
        <>
          <div className="overflow-hidden text-ellipsis whitespace-nowrap">
            {topic ?? defaultTopic}
          </div>
          <div
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            <Menu placement="bottom-end">
              <MenuButton
                size={'sm'}
                as={IconButton}
                icon={<FontAwesomeIcon icon={faEllipsisVertical} />}
                variant="ghost"
                className={`md:opacity-0 md:group-hover:opacity-100`}
                aria-label="chat settings"
              />
              <MenuList className="min-w-[130px]">
                <MenuItem onClick={onDeleteClick}>
                  {t('general.deleteButton')}
                </MenuItem>
                <MenuItem onClick={() => setRenaming(true)}>
                  {t('general.renameButtons')}
                </MenuItem>
              </MenuList>
            </Menu>
          </div>
        </>
      )}
    </ListItem>
  );
}

export default HistoryItem;
