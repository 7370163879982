import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
} from '@chakra-ui/react';
import { useRef, useState } from 'react';

import { Database } from 'common-ts';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';

type TellUsAboutYourselfProps = {
  className?: string;
  firstNameError: boolean;
  onContinue: (
    firstName: string,
    lastName: string,
    jobTitle: Database['public']['Enums']['job_description'] | undefined,
    jobTitleOther: string | undefined
  ) => void;
};

function TellUsAboutYourself({
  className,
  firstNameError,
  onContinue,
}: TellUsAboutYourselfProps) {
  const { t } = useTranslation();

  const firstNameRef = useRef('');
  const lastNameRef = useRef('');
  const jobTitleOtherRef = useRef('');
  const [jobTitle, setJobTitle] = useState<
    Database['public']['Enums']['job_description'] | undefined
  >(undefined);

  const jobDescriptionTranslationMap: {
    [key in Database['public']['Enums']['job_description']]: string;
  } = {
    AGILE: t('onboarding.jobDescriptions.agile'),
    ANALYTICS: t('onboarding.jobDescriptions.analytics'),
    DESIGN: t('onboarding.jobDescriptions.design'),
    DEV_ENG: t('onboarding.jobDescriptions.dev'),
    C_LEVEL: t('onboarding.jobDescriptions.cLevel'),
    HR: t('onboarding.jobDescriptions.hr'),
    IT: t('onboarding.jobDescriptions.it'),
    LEGAL_FINANCE: t('onboarding.jobDescriptions.legal'),
    MARKETING: t('onboarding.jobDescriptions.marketing'),
    OPERATIONS: t('onboarding.jobDescriptions.operations'),
    PRODUCT_MANAGEMENT: t('onboarding.jobDescriptions.product'),
    PROJECT_MANAGEMENT: t('onboarding.jobDescriptions.project'),
    SALES: t('onboarding.jobDescriptions.sales'),
    STUDENT: t('onboarding.jobDescriptions.student'),
    SUPPORT: t('onboarding.jobDescriptions.support'),
    TEACHER: t('onboarding.jobDescriptions.teacher'),
    UX: t('onboarding.jobDescriptions.ux'),
    OTHER: t('onboarding.jobDescriptions.other'),
  };

  function handleContinue() {
    let jobTitleToSend = jobTitle;
    if (
      !Array.from(Object.keys(jobDescriptionTranslationMap)).includes(
        jobTitleToSend ?? ''
      )
    ) {
      jobTitleToSend = undefined;
    }

    onContinue(
      firstNameRef.current,
      lastNameRef.current,
      jobTitleToSend,
      jobTitleOtherRef.current
    );
  }

  return (
    <div
      className={twMerge(
        'flex w-full max-w-xl flex-col items-center gap-8 px-4 text-center',
        className
      )}
    >
      <div className="text-2xl font-semibold">
        {t('onboarding.tellUsAboutYourself')}
      </div>
      <FormControl className="w-full" isInvalid={firstNameError}>
        <FormLabel>{t('userSettings.firstName')}*</FormLabel>
        <Input
          backgroundColor={'white'}
          placeholder={t('userSettings.firstName')}
          onChange={(e) => {
            firstNameRef.current = e.target.value;
          }}
        />
        {firstNameError && (
          <FormErrorMessage>{t('onboarding.firstNameError')}</FormErrorMessage>
        )}
      </FormControl>
      <div className="w-full">
        <FormLabel>{t('userSettings.lastName')}</FormLabel>
        <Input
          backgroundColor={'white'}
          placeholder={t('userSettings.lastName')}
          onChange={(e) => {
            lastNameRef.current = e.target.value;
          }}
        />
      </div>
      <div className="w-full">
        <FormLabel>{t('onboarding.jobTitle')}</FormLabel>
        <Select
          size={'sm'}
          placeholder={t('onboarding.jobTitle')}
          onChange={(e) => {
            setJobTitle(
              e.target.value as Database['public']['Enums']['job_description']
            );
          }}
        >
          {Object.entries(jobDescriptionTranslationMap).map(([key, value]) => {
            return <option value={key}>{value}</option>;
          })}
        </Select>
        {jobTitle === 'OTHER' ? (
          <Input
            className="mt-2"
            backgroundColor={'white'}
            placeholder={t('onboarding.jobTitle')}
            onChange={(e) => {
              jobTitleOtherRef.current = e.target.value;
            }}
          />
        ) : null}
      </div>
      <div className="flex items-center justify-end gap-4 self-stretch">
        <Button colorScheme="maia-accent" onClick={handleContinue}>
          {t('onboarding.continueButton')}
        </Button>
      </div>
    </div>
  );
}

export default TellUsAboutYourself;
