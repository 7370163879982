import { AvailableModelEnum, ChatMessageType } from 'common-ts';
import ChatMessage, { SearchMessage } from './ChatMessage.js';
import {
  HTMLAttributes,
  KeyboardEvent,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from 'react';

type StreamingChatMessageProps = {
  chatMessageProps?: HTMLAttributes<HTMLDivElement>;
  msg: ChatMessageType | SearchMessage;
  streamingMessageContentRef: MutableRefObject<string>;
  onKeyUp?: (event: KeyboardEvent<HTMLElement>) => void;
  userIsMarker?: boolean;
  model: AvailableModelEnum;
};

function StreamingChatMessage({
  chatMessageProps,
  msg,
  streamingMessageContentRef,
  onKeyUp,
  userIsMarker = false,
  model,
}: StreamingChatMessageProps) {
  const [renderedMessageContent, setRenderedMessageContent] = useState('');
  const animationFrameRef = useRef<number>();
  const elapsedTime = useRef<number>(performance.now());
  const elapsedAdjustmentTime = useRef<number>(performance.now());
  const enrolledString = useRef<string>('');

  useEffect(() => {
    const defaultCharsPerFrame = model === AvailableModelEnum.GPT_4 ? 8 : 8;
    let charsPerFrame = defaultCharsPerFrame;
    let animationInterval = 20;

    function updateStreamingMessage() {
      if (
        streamingMessageContentRef.current.length !==
        enrolledString.current.length
      ) {
        const now = performance.now();
        if (performance.now() - elapsedTime.current > animationInterval) {
          elapsedTime.current = now;
          enrolledString.current = streamingMessageContentRef.current.substring(
            0,
            Math.min(
              enrolledString.current.length + charsPerFrame,
              streamingMessageContentRef.current.length
            )
          );

          setRenderedMessageContent(enrolledString.current);
        }

        // Adjust 'typing' speed every second if needed
        if (performance.now() - elapsedAdjustmentTime.current > 1000) {
          elapsedAdjustmentTime.current = now;
          // Slow down if character backlog < 300
          if (
            streamingMessageContentRef.current.length -
              enrolledString.current.length <
            300
          ) {
            animationInterval = Math.min(
              Math.ceil(animationInterval * 1.2),
              50
            );
          }
          // Speed up if character backlog > 500
          else if (
            streamingMessageContentRef.current.length -
              enrolledString.current.length >
            500
          ) {
            animationInterval = Math.max(
              10,
              Math.floor(animationInterval * 0.8)
            );
          }
          // Additional speedup if character backlog > 1000
          if (
            streamingMessageContentRef.current.length -
              enrolledString.current.length >
            1000
          ) {
            charsPerFrame = charsPerFrame * 2;
          } else if (
            streamingMessageContentRef.current.length -
              enrolledString.current.length <
            500
          ) {
            charsPerFrame = defaultCharsPerFrame;
          }
        }
      }
      animationFrameRef.current = requestAnimationFrame(updateStreamingMessage);
    }

    if (!animationFrameRef.current) {
      updateStreamingMessage();
    }

    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
        animationFrameRef.current = undefined;
      }
    };
  }, []);

  msg.msg = renderedMessageContent;

  return (
    <ChatMessage
      likeInVisible
      props={chatMessageProps}
      msg={msg}
      onKeyUp={onKeyUp}
      userIsMarker={userIsMarker}
    />
  );
}

export default StreamingChatMessage;
