import {
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';

import AccessControl from '../../../components/AccessControl.js';
import CollectionNavigator from './CollectionNavigator.js';
import SearchBar from '../../../components/SearchBar';
import { useBoundStore } from '../../../store/useBoundStore.js';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

type FileSelectorProps = {
  className?: string;
  isPowerModeEnabled: boolean;
  collectionSelectedFileIdMap: Map<string, Map<string, string>> | undefined;
  onFileSelect: (
    collectionId: string,
    files: { name: string; id: string }[]
  ) => void;
  onFileUnselect: (
    collectionId: string,
    files: { name: string; id: string }[]
  ) => void;
  onTogglePowerMode: () => void;
};

function FileSelector({
  className,
  collectionSelectedFileIdMap,
  isPowerModeEnabled,
  onFileSelect,
  onFileUnselect,
}: FileSelectorProps) {
  const { t } = useTranslation();
  const extendedBuckets = useBoundStore((state) => state.extendedBuckets);
  const [searchTerm, setSearchTerm] = useState('');

  return (
    <div className="h-full w-full">
      <div className="h-[80vh] justify-center md:h-[unset]">
        <div>
          <Tabs
            size={'sm'}
            variant={'unstyled'}
            colorScheme="maia-purple"
            className={className}
            paddingTop={'4'}
            height={'full'}
          >
            <div className="flex items-center justify-between">
              <TabList>
                <Tab className="border-maia-border text-maia-text border-b-2 font-semibold">
                  {t('chat.fileSelector.yourCollections')}
                </Tab>
                <Tab className="border-maia-border text-maia-text border-b-2 font-semibold">
                  {t('chat.fileSelector.sharedWithYou')}
                </Tab>
                <AccessControl allowedLicenses={['PROFESSIONAL', 'ENTERPRISE']}>
                  <Tab className="border-maia-border text-maia-text border-b-2 font-semibold">
                    {t('chat.fileSelector.workspaceCollections')}
                  </Tab>
                </AccessControl>
              </TabList>
              <SearchBar onSearchTermChange={setSearchTerm} />
            </div>
            <TabIndicator
              className="bg-maia-accent -mt-0.5 mb-4"
              height={'2px'}
            />
            <TabPanels>
              <TabPanel padding={0} paddingTop={[10, 10, 4]} height={'full'}>
                <CollectionNavigator
                  searchTerm={searchTerm}
                  collections={extendedBuckets.filter(
                    (bucket) =>
                      !bucket.is_shared_to_user &&
                      !bucket.is_public_for_workspace
                  )}
                  collectionSelectedFileIdMap={collectionSelectedFileIdMap}
                  onFileSelect={onFileSelect}
                  onFileUnselect={onFileUnselect}
                  isPowerModeEnabled={isPowerModeEnabled}
                />
              </TabPanel>
              <TabPanel padding={0} paddingTop={[10, 10, 4]} height={'full'}>
                <CollectionNavigator
                  searchTerm={searchTerm}
                  collections={extendedBuckets.filter(
                    (bucket) =>
                      bucket.is_shared_to_user &&
                      !bucket.is_public_for_workspace
                  )}
                  collectionSelectedFileIdMap={collectionSelectedFileIdMap}
                  onFileSelect={onFileSelect}
                  onFileUnselect={onFileUnselect}
                  isPowerModeEnabled={isPowerModeEnabled}
                />
              </TabPanel>
              <TabPanel padding={0} paddingTop={[10, 10, 4]} height={'full'}>
                <CollectionNavigator
                  searchTerm={searchTerm}
                  collections={extendedBuckets.filter(
                    (bucket) => bucket.is_public_for_workspace
                  )}
                  collectionSelectedFileIdMap={collectionSelectedFileIdMap}
                  onFileSelect={onFileSelect}
                  onFileUnselect={onFileUnselect}
                  isPowerModeEnabled={isPowerModeEnabled}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </div>
      </div>
    </div>
  );
}

export default FileSelector;
