import { useDisclosure } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import Disable2FAModal from './modals/Disable2FAModal';
import SettingsSwitch from '../../settings/SettingsSwitch';
import TotpModal from './modals/TotpModal';
import { useTranslation } from 'react-i18next';
import { useToastManagerHook } from '../../../general/useToastManagerHook';
import { useBoundStore } from '../../../store/useBoundStore.js';

type AuthenticatorAppMFAProps = {
  setMfaCheck: (value: boolean) => void;
};

export default function AuthenticatorAppMFA({
  setMfaCheck,
}: AuthenticatorAppMFAProps) {
  const { t } = useTranslation();
  const supabase = useBoundStore((state) => state.supabase);
  const userInfo = useBoundStore((state) => state.userInfo);
  const { showToast } = useToastManagerHook();

  const totpModal = useDisclosure();
  const disable2faModal = useDisclosure();

  const [factorId, setFactorId] = useState<string>('');
  const isMFAEnabled = factorId !== '';

  const getAuthenticationLevel = async () => {
    const { data, error } = await supabase.auth.mfa.listFactors();

    if (error) {
      showToast({
        title: t('userSettings.security.generalError'),
        status: 'error',
      });
      console.error(error.message);
    }

    // since supabase currently support only one factor i.e. TOTP
    const enabledFactorId = data?.totp?.[0]?.id || '';

    setFactorId(enabledFactorId);
    setMfaCheck(true);
  };

  const handleDisable2FA = async () => {
    if (!factorId || !userInfo?.userId) return;
    const { error } = await supabase.auth.mfa.unenroll({
      factorId,
    });
    if (error) {
      console.error(error.message);
      return;
    }
    await supabase.auth.refreshSession();
    await getAuthenticationLevel();
    setMfaCheck(true);
  };

  useEffect(() => {
    getAuthenticationLevel();
  }, [totpModal.isOpen]);
  return (
    <>
      <SettingsSwitch
        title={t('userSettings.security.authenticatorAppTitle')}
        subtitle={t('userSettings.security.authenticatorAppSubtitle')}
        switchProps={{
          isChecked: isMFAEnabled,
          onChange: isMFAEnabled ? disable2faModal.onOpen : totpModal.onOpen,
        }}
      />

      {totpModal.isOpen && (
        <TotpModal isOpen={totpModal.isOpen} onClose={totpModal.onClose} />
      )}
      {disable2faModal.isOpen && (
        <Disable2FAModal
          title={t('userSettings.security.disable2FAModal.authenticatorTitle')}
          subtitle={t(
            'userSettings.security.disable2FAModal.authenticatorSubtitle'
          )}
          isOpen={disable2faModal.isOpen}
          onClose={disable2faModal.onClose}
          onConfirm={handleDisable2FA}
        />
      )}
    </>
  );
}
