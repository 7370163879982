import { StripeSlice, initialStripeState } from './createStripeSlice';
import { UserInfoSlice, initialUserState } from './createUserInfoSlice';
import {
  WorkspaceInfoSlice,
  initialWorkspaceState,
} from './createWorkspaceInfoSlice';

import { StateCreator } from 'zustand';
import { SupabaseSlice } from './createSupabaseSlice';

export type SharedSlice = {
  resetToInitialState: () => void;
};

export const createSharedSlice: StateCreator<
  UserInfoSlice & SupabaseSlice & WorkspaceInfoSlice & StripeSlice,
  [],
  [],
  SharedSlice
> = (set) => ({
  resetToInitialState: () => {
    const defaultState = {
      ...initialStripeState,
      ...initialUserState,
      ...initialWorkspaceState,
    };
    set((state) => ({ ...state, ...defaultState }));
  },
});
