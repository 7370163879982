import { DetailedHTMLProps, HTMLAttributes, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

type ListItemProps = {
  className?: string;
  mode?: 'dark' | 'light';
  variant?: 'left' | 'right';
  selected: boolean;
  children?: ReactNode;
  domElementProps?: DetailedHTMLProps<
    HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >;
  bordered?: boolean;
  isDisabled?: boolean;
};

function ListItem({
  className,
  mode = 'light',
  variant = 'right',
  selected,
  children,
  domElementProps,
  bordered = false,
  isDisabled = false,
}: ListItemProps) {
  const modeClassNameMap = {
    light: `bg-white ${!isDisabled ? 'hover:bg-maia-blue-100' : ''} ${
      selected
        ? 'border-maia-accent bg-maia-blue-100'
        : 'border-transparent bg-white'
    }`,
    dark: `text-white ${!isDisabled ? 'hover:bg-maia-blue-800' : ''} ${
      selected
        ? 'border-maia-accent bg-maia-blue-800'
        : 'border-transparent bg-maia-blue-900'
    }`,
  };

  // Additional classes for the border
  const borderClasses =
    bordered && !selected ? 'border border-maia-border' : '';

  return (
    <div
      {...domElementProps}
      className={twMerge(
        `0 flex h-10 items-center rounded font-normal ${
          variant === 'right' ? 'border-r-4 pl-4 pr-3' : 'border-l-4 pl-3 pr-4'
        } ${modeClassNameMap[mode]} ${borderClasses}`,
        className
      )}
      aria-disabled={isDisabled}
    >
      {children}
    </div>
  );
}

export default ListItem;
