import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

type MaiaMainGridProps = {
  className?: string;
  children?: ReactNode;
};

function MaiaMainGrid({ className, children }: MaiaMainGridProps) {
  return (
    <div className={twMerge('grid h-full grid-cols-11 gap-4 px-4', className)}>
      {children}
    </div>
  );
}

export default MaiaMainGrid;
