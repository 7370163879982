import {
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from '@chakra-ui/react';

import { t } from 'i18next';
import { twMerge } from 'tailwind-merge';
import { useState } from 'react';

type ChooseSeatAmountProps = {
  className?: string;
  isTrial?: boolean;
  seatsEmptyError: boolean;
  seatsInvalidError: boolean;
  continueButtonLoading: boolean;
  onContinue: (seats?: number) => void;
};

function ChooseSeatAmount({
  className,
  isTrial,
  seatsEmptyError,
  seatsInvalidError,
  continueButtonLoading,
  onContinue,
}: ChooseSeatAmountProps) {
  const [seats, setSeats] = useState<number | undefined>(1);

  return (
    <div
      className={twMerge(
        'flex max-w-xl flex-col items-center gap-8 text-center',
        className
      )}
    >
      <div className="text-2xl font-semibold">
        {t('createWorkspace.seatSelectionTitle')}
      </div>

      <FormControl
        isInvalid={seatsEmptyError || seatsInvalidError}
        className="flex flex-col items-center"
      >
        <NumberInput
          min={1}
          max={isTrial ? 3 : 10000}
          defaultValue={1}
          className="w-28 bg-white"
          onChange={(valueString) => {
            setSeats(Number(valueString));
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onContinue(seats);
            }
          }}
        >
          <NumberInputField autoFocus />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
        {(seats ?? 0) > 50 ? (
          <FormHelperText>
            {t('createWorkspace.manySeatsEnterpriseHint')}{' '}
            <a href="mailto:info@prodlane.io">info@prodlane.io</a>.
          </FormHelperText>
        ) : seatsEmptyError ? (
          <FormErrorMessage>
            {t('createWorkspace.seatsEmptyError')}
          </FormErrorMessage>
        ) : seatsInvalidError ? (
          <FormErrorMessage>
            {`${seats} ${t('createWorkspace.seatsInvalidError')}`}
          </FormErrorMessage>
        ) : isTrial ? (
          <FormHelperText>{t('createWorkspace.seatsTrialHint')}</FormHelperText>
        ) : null}
      </FormControl>
      <div className="flex items-center justify-end gap-4">
        <Button
          isLoading={continueButtonLoading}
          colorScheme="maia-accent"
          onClick={() => {
            onContinue(seats);
          }}
        >
          {t('createWorkspace.continueButton')}
        </Button>
      </div>
    </div>
  );
}

export default ChooseSeatAmount;
