import { IconButton } from '@chakra-ui/react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { captureException } from '@sentry/react';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import { useToastManagerHook } from '../../general/useToastManagerHook.js';
import { useBoundStore } from '../../store/useBoundStore.js';

type FilePreviewIconButtonProps = {
  fileId?: string;
  documentId?: string;
  fileName: string;
  startingPage?: number;
};

function FilePreviewIconButton({
  fileId,
  documentId,
  fileName,
  startingPage,
}: FilePreviewIconButtonProps) {
  const { t } = useTranslation();
  const supabase = useBoundStore((state) => state.supabase);
  const { showToast } = useToastManagerHook();

  async function handlePresignedUrl(
    fileName: string,
    fileId?: string,
    documentId?: string
  ) {
    const { data: fIdata, error: fIerror } = fileId
      ? await supabase
          .from('document_view')
          .select('bucket_id')
          .eq('file_id', fileId)
          .limit(1)
      : documentId
        ? await supabase
            .from('document_view')
            .select('bucket_id')
            .eq('document_id', documentId)
            .limit(1)
        : { error: 'no fileId nor documentId provided', data: null };

    const bucketId = fIdata?.[0] && fIdata[0].bucket_id;

    if (fIerror) {
      captureException(fIerror);
      showToast({ title: t('general.reloadError'), status: 'error' });
      return;
    }

    if (!bucketId) {
      showToast({ title: t('general.resourceNotFound'), status: 'warning' });
      return;
    }

    const { data, error } = await supabase.storage
      .from(bucketId)
      .createSignedUrl(fileName, 60);
    if (error) {
      captureException(error);
      showToast({ title: t('general.reloadError'), status: 'error' });
      console.error('Error generating URL:', error);
    } else if (data.signedUrl) {
      window.open(
        startingPage
          ? `${data.signedUrl}#page=${startingPage}`
          : data.signedUrl,
        '_blank'
      );
    }
  }

  return (
    <IconButton
      icon={<FontAwesomeIcon icon={faArrowUpRightFromSquare} />}
      aria-label={`open document ${fileName} in another tab`}
      variant={'ghost'}
      className="text-maia-text-dark font-medium"
      onClick={(e) => {
        handlePresignedUrl(fileName, fileId, documentId);
        e.stopPropagation();
      }}
    />
  );
}

export default FilePreviewIconButton;
