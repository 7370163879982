import { t } from 'i18next';

type RenderErrorProps = {
  content: string;
};

function RenderError({ content }: RenderErrorProps) {
  return (
    <>
      <div className="font-extrabold">{t('general.errorWhileRendering')}</div>
      {content}
    </>
  );
}

export default RenderError;
