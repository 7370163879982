import {
  Box,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
} from '@chakra-ui/react';
import { ChangeEvent, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ROUTES } from 'common-ts';
import { captureException } from '@sentry/react';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { faLockKeyhole } from '@fortawesome/pro-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useToastManagerHook } from '../../general/useToastManagerHook.js';
import { useBoundStore } from '../../store/useBoundStore.js';

const PASSWORD_RESET_TOAST_ID = 'password-reset-toast';

export default function PasswordReset() {
  const navigate = useNavigate();
  const supabase = useBoundStore((state) => state.supabase);
  const { showToast } = useToastManagerHook();
  const { t } = useTranslation();

  const [email, setEmail] = useState('');
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) =>
    setEmail(e.target.value);
  const handleResetPassword = () => {
    if (email === '') {
      setIsError(true);
    } else {
      setIsError(false);
      setIsLoading(true);
      supabase.auth
        .resetPasswordForEmail(email, {
          redirectTo: `${
            import.meta.env.VITE_MAIA_APP_PUBLIC_URL
          }${ROUTES.RESETTING_PW.buildPath({})}`,
        })
        .then((res) =>
          res.data
            ? /*  removing this toast because the message can be misleading
                  In case, if the user is not registered or deleted, the message will still show email sent successfully 
                  because the endpoint does not respond with an error in this case.
                  Previously, we used to soft delete the user, so the email would be still sent. 
                  But now, we are hard deleting the user, so the email won't be sent and this is handled by supabase.
                  
                  Checking whether user exists or not is susceptible to email enumeration attack hence not a good idea to check whether user exists or not.
                  For more information: https://github.com/supabase/auth-js/issues/590#issuecomment-1632770323
              */
              navigate(ROUTES.AUTH.SIGN_IN.buildPath({}))
            : showToast({
                id: PASSWORD_RESET_TOAST_ID,
                title: t('loginView.resetPassword.toastResponseErrorMessage'),
                status: 'error',
              })
        )
        .catch((e) => {
          captureException(e);
          showToast({
            id: PASSWORD_RESET_TOAST_ID,
            title: t('loginView.resetPassword.toastRequestErrorMessage'),
            status: 'error',
          });
        })
        .finally(() => setIsLoading(false));
    }
  };
  return (
    <div className="flex w-full max-w-md flex-col items-center">
      <div className="flex w-full flex-col items-center space-y-4">
        <Box
          bgGradient="linear(to-br, blue.500, pink.500)"
          className="flex h-16 w-16 items-center justify-center rounded-full"
        >
          <Box
            bgGradient="linear(to-r, blue.500, pink.500)"
            className="flex h-12 w-12 items-center justify-center rounded-full border-2 border-solid border-white"
            textColor={'blue.50'}
          >
            <FontAwesomeIcon
              className="h-6 w-6 fill-current"
              icon={faLockKeyhole}
            />
          </Box>
        </Box>
        <Divider size={'12px'} colorScheme="blue" />
        <Text fontSize={'3xl'} alignSelf={'self-start'}>
          {t('loginView.resetPassword.title')}
        </Text>
        <Text fontSize={'sm'} textColor={'gray.500'}>
          {t('loginView.resetPassword.subtitle')}
        </Text>
      </div>
      <FormControl isInvalid={isError}>
        <FormLabel>Email</FormLabel>
        <Input type="email" value={email} onChange={handleEmailChange} />
        {isError && (
          <FormErrorMessage>
            {t('loginView.resetPassword.emptyEmailErrorMessage')}
          </FormErrorMessage>
        )}
      </FormControl>
      <div className="mt-8 flex w-full justify-between">
        <Button
          leftIcon={<FontAwesomeIcon icon={faArrowLeft} />}
          variant={'outline'}
          onClick={() => navigate(ROUTES.AUTH.SIGN_IN.buildPath({}))}
        >
          {t('loginView.resetPassword.backToLoginButton')}
        </Button>
        <Button
          variant={'solid'}
          colorScheme="maia-blue"
          onClick={handleResetPassword}
          isLoading={isLoading}
        >
          {t('loginView.resetPassword.resetPasswordButton')}
        </Button>
      </div>
    </div>
  );
}
