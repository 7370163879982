import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react';

import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

type ConfirmDeletionModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

export default function DeleteShareModal({
  isOpen,
  onConfirm,
  onClose,
}: ConfirmDeletionModalProps) {
  const { t } = useTranslation();
  const cancelRef = useRef(null);

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {t('fileManagerPanel.deleteShareModalTitle')}
          </AlertDialogHeader>
          <AlertDialogBody>
            {t('fileManagerPanel.deleteShareModalSubtitle')}
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              {t('fileManagerPanel.deleteShareModalSecondaryButton')}
            </Button>
            <Button colorScheme="red" onClick={onConfirm} ml={3}>
              {t('fileManagerPanel.deleteShareModalRemoveButton')}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
