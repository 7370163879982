import { Switch, SwitchProps, Text } from '@chakra-ui/react';

type SettingsSwitchProps = {
  title: string;
  subtitle: string;
  switchProps: SwitchProps;
};

function SettingsSwitch({ title, subtitle, switchProps }: SettingsSwitchProps) {
  return (
    <div className="flex items-center justify-between">
      <div className="flex flex-col gap-1.5">
        <Text className="font-medium">{title}</Text>
        <Text className="text-xs font-medium text-gray-500">{subtitle}</Text>
      </div>
      <Switch colorScheme="maia-purple" {...switchProps} />
    </div>
  );
}

export default SettingsSwitch;
