import {
  Alert,
  AlertIcon,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tooltip,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { decodeName } from 'common-ts';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import { fetchApi } from '../../../utils/useApi.js';
import { useBoundStore } from '../../../store/useBoundStore.js';
import { useTranslation } from 'react-i18next';

type EditCollectionModalProps = {
  deleteTooltipLabel?: string;
  deleteIsDisabled?: boolean;
  isOpen: boolean;
  selectedCollectionId: string | undefined;
  selectedCollectionName: string | undefined;
  onClose: () => void;
  onDelete: () => void;
  fetchCollections: () => void;
};

function EditCollectionModal({
  deleteTooltipLabel,
  deleteIsDisabled,
  isOpen,
  selectedCollectionId,
  selectedCollectionName = '',
  onClose,
  onDelete,
  fetchCollections,
}: EditCollectionModalProps) {
  const { t } = useTranslation();
  const supabase = useBoundStore((state) => state.supabase);
  const workspaceId = useBoundStore((state) => state.workspaceId);

  const [showDeleteWarning, setShowDeleteWarning] = useState(false);

  useEffect(() => {
    setShowDeleteWarning(false);
  }, [isOpen]);

  const [collectionName, setCollectionName] = useState(
    decodeName(selectedCollectionName)
  );
  const [collectionNameError, setCollectionNameError] = useState(false);

  async function handleUpdateCollectionTitle() {
    if (!collectionName) {
      setCollectionNameError(true);
      return;
    }
    if (collectionNameError) setCollectionNameError(false);
    if (!selectedCollectionId) return;

    const resp = await fetchApi(supabase, '/share', '/rename_bucket', {
      method: 'POST',
      bucketId: selectedCollectionId,
      name: collectionName,
      workspaceId,
    });
    if (resp.data?.success) fetchCollections();
    onClose();
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      motionPreset="slideInBottom"
      colorScheme="maia-gray"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {t('fileManagerPanel.editCollectionModalHeader')}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl isInvalid={collectionNameError}>
            <FormLabel>
              {t('fileManagerPanel.editCollectionModalInputLabel')}
            </FormLabel>
            <Input
              maxLength={128}
              onChange={(e) => setCollectionName(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleUpdateCollectionTitle();
                }
              }}
              value={collectionName}
              isDisabled={showDeleteWarning}
              autoFocus
            />
            {collectionNameError && (
              <FormErrorMessage>
                {t('fileManagerPanel.editCollectionInputEmptyError')}
              </FormErrorMessage>
            )}
          </FormControl>
          {showDeleteWarning && (
            <Alert variant={'left-accent'} status="warning" mt={'3'}>
              <AlertIcon />
              <div>
                <div className="font-semibold">
                  {t('fileManagerPanel.deleteCollectionModalTitle')}
                </div>
                <div>{t('fileManagerPanel.deleteCollectionModalSubtitle')}</div>
              </div>
            </Alert>
          )}
        </ModalBody>

        <ModalFooter>
          <Tooltip label={deleteTooltipLabel} openDelay={500}>
            {showDeleteWarning ? (
              <div className="flex items-center gap-2">
                <Button
                  className="font-medium"
                  onClick={onClose}
                  variant="outline"
                >
                  {t('general.cancelButton')}
                </Button>
                <Button
                  className="bg-maia-support-red text-maia-text-light font-medium"
                  isDisabled={deleteIsDisabled}
                  onClick={onDelete}
                >
                  {t('general.deleteButton')}
                </Button>
              </div>
            ) : (
              <div className="flex w-full items-center justify-between">
                <Button
                  isDisabled={deleteIsDisabled}
                  colorScheme="red"
                  variant={'ghost'}
                  leftIcon={<FontAwesomeIcon icon={faTrash} />}
                  onClick={() => setShowDeleteWarning(true)}
                >
                  {t('general.deleteButton')}
                </Button>
                <div className="flex items-center gap-2">
                  <Button
                    className="font-medium"
                    onClick={onClose}
                    variant="outline"
                  >
                    {t('general.cancelButton')}
                  </Button>
                  <Button
                    className="font-medium"
                    colorScheme="maia-accent"
                    onClick={handleUpdateCollectionTitle}
                  >
                    {t('general.saveButtons')}
                  </Button>
                </div>
              </div>
            )}
          </Tooltip>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default EditCollectionModal;
