import { captureException, captureMessage } from '@sentry/react';

import { useEffect } from 'react';

const CHECK_FOR_VERSION_EACH_MIN = 5;
const VERSION_LOCAL_STORAGE_KEY = 'versionOfProduct';
const FIRST_VISIT_LOCAL_STORAGE_KEY = 'firstVisitFlag';

/**
 * Checks if localStorage is available and useable
 */
function isLocalStorageAvailable() {
  // TODO, that ignores the case the the localstorage quota is reached
  // which for now can be safely ignored
  try {
    const test = 'test-storage';
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (e) {
    captureException(e);
    return false;
  }
}

function updateIfNoNewest(liveVersion: string) {
  if (!isLocalStorageAvailable()) {
    // Prevent any kind of not intended loophole here
    return;
  }

  const firstVisit =
    localStorage.getItem(FIRST_VISIT_LOCAL_STORAGE_KEY) === null;
  const currentVersion = localStorage.getItem(VERSION_LOCAL_STORAGE_KEY);

  if (currentVersion === liveVersion) {
    // No update needed
    return;
  }

  // Mark the first visit. This key persists even if VERSION_LOCAL_STORAGE_KEY is deleted.
  if (firstVisit) {
    localStorage.setItem(FIRST_VISIT_LOCAL_STORAGE_KEY, 'visited');
    return;
  }

  // If we reach this point, it means there's an update.
  // eslint-disable-next-line no-console
  console.log(`Updated from ${currentVersion} to ${liveVersion}`);
  localStorage.setItem(VERSION_LOCAL_STORAGE_KEY, liveVersion);
  window.location.reload();
}

function useForceUpdateHook() {
  useEffect(() => {
    const versionCheck = async () => {
      const response = await fetch('/version.json');

      if (response.status === 200) {
        try {
          const data = await response.json();
          if (typeof data.version !== 'string') {
            throw new Error('Version is not a string');
          } else {
            updateIfNoNewest(data.version);
          }
        } catch (error) {
          console.error('Version check parsing failed', response, error);
          captureException(error);
        }
      } else {
        console.error('Version check failed', response);
        captureMessage('Version check failed', 'error');
      }
    };

    const interval = setInterval(
      versionCheck,
      CHECK_FOR_VERSION_EACH_MIN * 1000 * 60
    ); // conversion to minutes

    versionCheck(); // Immediate version check on hook call

    return () => clearInterval(interval); // Cleanup on hook unmount
  }, []);
}

export default useForceUpdateHook;
