/**
 * Escape characters in a plain text string that would otherwise be interpreted as markdown. (e.g. '#' becomes '\#')
 * @param snippet plain text string
 * @returns snippet escaped so it can be used in markdown
 */
export function escapeMarkdownSpecialChars(snippet) {
    return snippet
        .replace(/\*/g, '\\*') // asterisks
        .replace(/#/g, '\\#') // number signs
        .replace(/\//g, '\\/') // slashes
        .replace(/\(/g, '\\(') // parentheses
        .replace(/\)/g, '\\)') // parentheses
        .replace(/\[/g, '\\[') // square brackets
        .replace(/\]/g, '\\]') // square brackets
        .replace(/</g, '&lt;') // angle brackets
        .replace(/>/g, '&gt;') // angle brackets
        .replace(/_/g, '\\_') // underscores
        .replace(/`/g, '\\`') // codeblock
        .replace(/-/g, '\\-') // List
        .replace(/\|/g, '\\|'); // Table
}
