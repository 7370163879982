import {
  Database,
  MaiaGenericError,
  createSupabaseErrorResponse,
  maiaErrorWithStack,
} from 'common-ts';
import {
  Prompt,
  PromptCollection,
  PromptLibraryContent,
} from './promptLibraryTypes';

import { SupabaseClient } from '@supabase/supabase-js';

/**
 * Get the content of the users private prompt library.
 * This gets all prompt collections, prompts, favorites, and recently used prompts for the user.
 * Will return the last 15 used prompts in the recently used section.
 * @param userId The user id to get the prompt library content for.
 */
export const getYourPromptLibraryContent = async (
  userId: string,
  supabase: SupabaseClient<Database, 'public'>
) => {
  const { data: promptCollections, error: promptCollectionsError } =
    await supabase
      .from('prompt_collection')
      .select('*')
      .eq('user_id', userId)
      .order('name', { ascending: true });

  if (promptCollectionsError)
    return createSupabaseErrorResponse(
      promptCollectionsError,
      'Error fetching prompt collections'
    );

  const collectionIds = promptCollections?.map((collection) => collection.id);
  const { data: prompts, error: promptsError } = await supabase
    .from('prompt')
    .select('*')
    .in('prompt_collection_id', collectionIds)
    .eq('user_id', userId)
    .order('name', { ascending: true });

  if (promptsError) {
    return createSupabaseErrorResponse(promptsError, 'Error fetching prompts');
  }

  const promptIds = prompts?.map((prompt) => prompt.id);

  // Parallel queries
  const [promptFavoritesResult, promptUsageResult] = await Promise.all([
    supabase
      .from('prompt_favorite')
      .select('*')
      .in('prompt_id', promptIds)
      .eq('user_id', userId),
    supabase
      .from('prompt_usage')
      .select('*')
      .eq('user_id', userId)
      .order('used_at', { ascending: false })
      .limit(15),
  ]);

  if (promptFavoritesResult.error)
    return createSupabaseErrorResponse(
      promptFavoritesResult.error,
      'Error fetching prompt favorites'
    );
  if (promptUsageResult.error)
    return createSupabaseErrorResponse(
      promptUsageResult.error,
      'Error fetching prompt usage'
    );

  const favoritePrompts: Prompt[] = prompts
    ?.filter((prompt) =>
      promptFavoritesResult.data?.some(
        (favorite) => favorite.prompt_id === prompt.id
      )
    )
    .map((prompt) => ({
      id: prompt.id,
      value: prompt.value,
      name: prompt.name,
      isFavorite: true,
      collectionId: prompt.prompt_collection_id,
    }));

  const recentlyUsedPrompts: Prompt[] = prompts
    ?.filter((prompt) =>
      promptUsageResult.data?.some((usage) => usage.prompt_id === prompt.id)
    )
    .sort(
      (a, b) =>
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    )
    .map((prompt) => ({
      id: prompt.id,
      value: prompt.value,
      name: prompt.name,
      isFavorite: favoritePrompts.some((fav) => fav.id === prompt.id),
      collectionId: prompt.prompt_collection_id,
    }));

  const collections: PromptCollection[] = promptCollections?.map(
    (collection) => {
      const collectionPrompts = prompts?.filter(
        (prompt) => prompt.prompt_collection_id === collection.id
      );

      return {
        id: collection.id,
        name: collection.name,
        prompts: collectionPrompts?.map((prompt) => ({
          id: prompt.id,
          value: prompt.value,
          name: prompt.name,
          isFavorite: favoritePrompts.some((fav) => fav.id === prompt.id),
          collectionId: prompt.prompt_collection_id,
        })),
      };
    }
  );

  const ret: PromptLibraryContent = {
    favorites: favoritePrompts,
    recentlyUsed: recentlyUsedPrompts,
    collections,
  };

  return { data: ret, error: null };
};

/**
 * Get the content of the example prompt library.
 * The example prompt library is a public library of prompts that all users can use.
 * Since it is localized, the language of the prompts will be in the given language.
 * Favorites and recently used prompts are stored per user and will be returned in the response.
 * @param userId The user id to get the prompt library content for.
 * @param language The language to get the prompts in. This should be a language code like 'en' or 'de'.
 */
export const getExamplePromptLibraryContent = async (
  userId: string,
  language: string,
  supabase: SupabaseClient<Database, 'public'>
) => {
  // Fetch initial data
  const { data: promptCollections, error: promptCollectionsError } =
    await supabase.from('example_prompt_collection').select('*');
  if (promptCollectionsError)
    return createSupabaseErrorResponse(
      promptCollectionsError,
      'Error fetching example prompt collections'
    );

  // Early exit if no collections
  const collectionIds = promptCollections.map((collection) => collection.id);
  if (!collectionIds.length) return { data: null, error: null };

  const { data: prompts, error: promptsError } = await supabase
    .from('example_prompt')
    .select('*')
    .in('example_prompt_collection_id', collectionIds);

  if (promptsError) {
    return createSupabaseErrorResponse(
      promptsError,
      'Error fetching example prompts'
    );
  }

  const promptIds = prompts.map((prompt) => prompt.id);

  // Fetch prompts, favorites, and usage data in parallel
  const [promptFavoritesResult, promptUsageResult] = await Promise.all([
    supabase
      .from('example_prompt_favorite')
      .select('*')
      .in('example_prompt_id', promptIds)
      .eq('user_id', userId),
    supabase
      .from('example_prompt_usage')
      .select('*')
      .eq('user_id', userId)
      .order('used_at', { ascending: false })
      .limit(15),
  ]);

  if (promptFavoritesResult.error)
    return createSupabaseErrorResponse(
      promptFavoritesResult.error,
      'Error fetching example prompt favorites'
    );
  if (promptUsageResult.error)
    return createSupabaseErrorResponse(
      promptUsageResult.error,
      'Error fetching example prompt usage'
    );

  const promptFavorites = promptFavoritesResult.data;
  const promptUsage = promptUsageResult.data;

  const userLanguage = language.includes('de') ? 'de' : 'en';

  const favoritePrompts: Prompt[] = prompts
    ?.filter((prompt) =>
      promptFavorites?.some(
        (favorite) => favorite.example_prompt_id === prompt.id
      )
    )
    .map((prompt) => ({
      id: prompt.id,
      value: userLanguage === 'de' ? prompt.value_de : prompt.value_en,
      name: userLanguage === 'de' ? prompt.name_de : prompt.name_en,
      isFavorite: true,
      collectionId: prompt.example_prompt_collection_id,
    }));

  const recentlyUsedPrompts: Prompt[] = prompts
    ?.filter((prompt) =>
      promptUsage?.some((usage) => usage.example_prompt_id === prompt.id)
    )
    .sort((a, b) => {
      return (
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      );
    })
    .map((prompt) => ({
      id: prompt.id,
      value: userLanguage === 'de' ? prompt.value_de : prompt.value_en,
      name: userLanguage === 'de' ? prompt.name_de : prompt.name_en,
      isFavorite: favoritePrompts.some((fav) => fav.id === prompt.id),
      collectionId: prompt.example_prompt_collection_id,
    }));

  // Sort the collections based on the current user's language
  const sortedCollections = [...promptCollections]?.sort((a, b) => {
    if (userLanguage === 'de') {
      return a.name_de.localeCompare(b.name_de);
    }
    return a.name_en.localeCompare(b.name_en);
  });

  const collections: PromptCollection[] = sortedCollections?.map(
    (collection) => {
      const collectionPrompts = prompts
        ?.filter(
          (prompt) => prompt.example_prompt_collection_id === collection.id
        )
        ?.sort((a, b) => {
          if (userLanguage === 'de') {
            return a.name_de.localeCompare(b.name_de);
          }
          return a.name_en.localeCompare(b.name_en);
        });

      return {
        id: collection.id,
        name: userLanguage === 'de' ? collection.name_de : collection.name_en,
        prompts: collectionPrompts?.map((prompt) => ({
          id: prompt.id,
          value: userLanguage === 'de' ? prompt.value_de : prompt.value_en,
          name: userLanguage === 'de' ? prompt.name_de : prompt.name_en,
          isFavorite: favoritePrompts.some((fav) => fav.id === prompt.id),
          collectionId: prompt.example_prompt_collection_id,
        })),
      };
    }
  );

  const ret: PromptLibraryContent = {
    favorites: favoritePrompts,
    recentlyUsed: recentlyUsedPrompts,
    collections,
  };

  return { data: ret, error: null };
};

/**
 * Add a new prompt collection to the users private prompt library.
 * This will create a new collection with the given name and return the new collection.
 * @param name The name of the new collection.
 * @param user_id The user id to add the collection for.
 */
export const addPromptCollection = async (
  name: string,
  user_id: string,
  supabase: SupabaseClient<Database, 'public'>
) => {
  const { data, error } = await supabase
    .from('prompt_collection')
    .insert([{ name, user_id }])
    .select()
    .single();

  if (error) {
    return {
      data: null,
      error: {
        message: 'Error inserting prompt collection',
        name: 'SupabaseError',
      },
    };
  }

  if (!data) {
    return {
      data: null,
      error: {
        message: 'No collection returned from insert query',
        name: 'InsertedCollectionNoReturned',
      },
    };
  }

  return {
    data: {
      id: data.id,
      name: data.name,
      prompts: [],
    },
    error: null,
  };
};

/**
 * Add a new prompt to the users private prompt library.
 * @param prompt_collection_id The id of the collection to add the prompt to.
 * @param user_id The user id to add the prompt for.
 * @param name The name of the new prompt.
 * @param value Optional value of the new prompt. Defaults to an empty string.
 */
export const addPromptToCollection = async (
  prompt_collection_id: string,
  user_id: string,
  name: string,
  value: string = '',
  supabase: SupabaseClient<Database, 'public'>
) => {
  const { data, error } = await supabase
    .from('prompt')
    .insert([{ prompt_collection_id, user_id, value, name }])
    .select();

  if (error) {
    return createSupabaseErrorResponse(error, 'Error inserting prompt');
  }

  if (!data[0]) {
    return {
      data: null,
      error: maiaErrorWithStack<MaiaGenericError<'InsertedPromptNoReturned'>>({
        message: 'No prompt returned from insert query',
        name: 'InsertedPromptNoReturned',
      }),
    };
  }

  const ret: Prompt = {
    id: data[0].id,
    value: data[0].value,
    name: data[0].name,
    isFavorite: false,
    collectionId: data[0].prompt_collection_id,
  };

  return { data: ret, error: null };
};

/**
 * Update the value of a private prompt.
 * @param prompt - the prompt to update
 * @param value - the new value for the prompt
 * @param userId - the user id to update the prompt for
 */
export const updatePromptValue = async (
  prompt: Prompt,
  value: string,
  userId: string,
  supabase: SupabaseClient<Database, 'public'>
) => {
  const { data, error } = await supabase
    .from('prompt')
    .update({ value })
    .eq('id', prompt.id)
    .eq('user_id', userId)
    .select();

  if (error) {
    return createSupabaseErrorResponse(error, 'Error updating prompt value');
  }

  if (!data) {
    return {
      data: null,
      error: maiaErrorWithStack<MaiaGenericError<'InsertedPromptNoReturned'>>({
        message: 'No prompt returned from update query',
        name: 'InsertedPromptNoReturned',
      }),
    };
  }

  if (data[0] === undefined) {
    return {
      data: null,
      error: maiaErrorWithStack<MaiaGenericError<'NoPromptUpdated'>>({
        message: 'No prompt updated',
        name: 'NoPromptUpdated',
      }),
    };
  }

  const ret: Prompt = {
    id: data[0].id,
    value: data[0].value,
    name: data[0].name,
    isFavorite: prompt.isFavorite,
    collectionId: data[0].prompt_collection_id,
  };

  return { data: ret, error: null };
};

/**
 * Create a record in the prompt_usage table to track when a user uses a private prompt.
 * @param promptId - the id of the prompt to update
 * @param userId - the user id to update the prompt for
 */
export const insertPromptUsage = async (
  promptId: string,
  userId: string,
  supabase: SupabaseClient<Database, 'public'>
) => {
  const { data, error } = await supabase
    .from('prompt_usage')
    .insert([{ prompt_id: promptId, user_id: userId }])
    .select();

  if (error) {
    return createSupabaseErrorResponse(error, 'Error inserting prompt usage');
  }

  const ret = data[0];

  return { data: ret, error: null };
};

/**
 * Add a private prompt to the users favorites.
 * @param promptId - the id of the prompt to add to favorites
 * @param userId - the user id to add the favorite for
 */
export const insertPromptFavorite = async (
  promptId: string,
  userId: string,
  supabase: SupabaseClient<Database, 'public'>
) => {
  const { data, error } = await supabase
    .from('prompt_favorite')
    .insert([{ prompt_id: promptId, user_id: userId }])
    .select();

  if (error) {
    return createSupabaseErrorResponse(
      error,
      'Error inserting prompt favorite'
    );
  }

  const ret = data[0];

  return { data: ret, error: null };
};

/**
 * Remove a private prompt from the users favorites.
 * @param promptId - the id of the prompt to remove from favorites
 * @param userId - the user id to remove the favorite for
 */
export const deletePromptFavorite = async (
  promptId: string,
  userId: string,
  supabase: SupabaseClient<Database, 'public'>
) => {
  const { error } = await supabase
    .from('prompt_favorite')
    .delete()
    .eq('prompt_id', promptId)
    .eq('user_id', userId)
    .select();

  if (error) {
    return createSupabaseErrorResponse(error, 'Error deleting prompt favorite');
  }

  return { error: null };
};

/**
 * Update the name of a private prompt.
 * @param prompt - the prompt to update
 * @param newName - the new name for the prompt
 * @param userId - the user id to update the prompt for
 */
export const renamePrompt = async (
  prompt: Prompt,
  newName: string,
  userId: string,
  supabase: SupabaseClient<Database, 'public'>
) => {
  const { data, error } = await supabase
    .from('prompt')
    .update({ name: newName })
    .eq('id', prompt.id)
    .eq('user_id', userId)
    .select();

  if (error) {
    return createSupabaseErrorResponse(error, 'Error updating prompt name');
  }

  if (!data) {
    return {
      data: null,
      error: maiaErrorWithStack<MaiaGenericError<'InsertedPromptNoReturned'>>({
        message: 'No prompt returned from update query',
        name: 'InsertedPromptNoReturned',
      }),
    };
  }

  if (data[0] === undefined) {
    return {
      data: null,
      error: maiaErrorWithStack<MaiaGenericError<'NoPromptUpdated'>>({
        message: 'No prompt updated',
        name: 'NoPromptUpdated',
      }),
    };
  }

  const ret: Prompt = {
    id: data[0].id,
    value: data[0].value,
    name: data[0].name,
    isFavorite: prompt.isFavorite,
    collectionId: data[0].prompt_collection_id,
  };

  return { data: ret, error: null };
};

/**
 * Delete a private prompt from the user's library.
 * @param prompt - the prompt to delete
 * @param userId - the user id to delete the prompt for
 */
export const deletePrompt = async (
  prompt: Prompt,
  userId: string,
  supabase: SupabaseClient<Database, 'public'>
) => {
  const { error } = await supabase
    .from('prompt')
    .delete()
    .eq('id', prompt.id)
    .eq('user_id', userId)
    .select();

  if (error) {
    return createSupabaseErrorResponse(error, 'Error deleting prompt');
  }

  return { error: null };
};

/**
 * Delete a private prompt collection from the user's library.
 * @param collection - the collection to delete
 * @param userId - the user id to delete the collection for
 */
export const deletePromptCollection = async (
  collection: PromptCollection,
  userId: string,
  supabase: SupabaseClient<Database, 'public'>
) => {
  const { error } = await supabase
    .from('prompt_collection')
    .delete()
    .eq('id', collection.id)
    .eq('user_id', userId)
    .select();

  if (error) {
    return createSupabaseErrorResponse(
      error,
      'Error deleting prompt collection'
    );
  }

  return { error: null };
};

/**
 * Update the name of a private prompt collection.
 * @param collection - the collection to update
 * @param newName - the new name for the collection
 * @param userId - the user id to update the collection for
 */
export const renamePromptCollection = async (
  collection: PromptCollection,
  newName: string,
  userId: string,
  supabase: SupabaseClient<Database, 'public'>
) => {
  const { data, error } = await supabase
    .from('prompt_collection')
    .update({ name: newName })
    .eq('id', collection.id)
    .eq('user_id', userId)
    .select();

  if (error) {
    return createSupabaseErrorResponse(error, 'Error updating collection name');
  }

  if (!data) {
    return {
      data: null,
      error: maiaErrorWithStack<
        MaiaGenericError<'InsertedCollectionNoReturned'>
      >({
        message: 'No collection returned from update query',
        name: 'InsertedCollectionNoReturned',
      }),
    };
  }

  if (data[0] === undefined) {
    return {
      data: null,
      error: maiaErrorWithStack<MaiaGenericError<'NoCollectionUpdated'>>({
        message: 'No collection updated',
        name: 'NoCollectionUpdated',
      }),
    };
  }

  const ret: PromptCollection = {
    id: data[0].id,
    name: data[0].name,
    prompts: collection.prompts,
  };

  return { data: ret, error: null };
};

/**
 * Add an example prompt to the users favorites.
 * @param examplePromptId - the id of the example prompt to add to favorites
 * @param userId - the user id to add the favorite for
 */
export const insertExamplePromptFavorite = async (
  examplePromptId: string,
  userId: string,
  supabase: SupabaseClient<Database, 'public'>
) => {
  const { data, error } = await supabase
    .from('example_prompt_favorite')
    .insert([{ example_prompt_id: examplePromptId, user_id: userId }])
    .select();

  if (error) {
    return createSupabaseErrorResponse(
      error,
      'Error inserting example prompt favorite'
    );
  }

  const ret = data[0];

  return { data: ret, error: null };
};

/**
 * Remove an example prompt from the users favorites.
 * @param examplePromptId - the id of the example prompt to remove from favorites
 * @param userId - the user id to remove the favorite for
 */
export const deleteExamplePromptFavorite = async (
  examplePromptId: string,
  userId: string,
  supabase: SupabaseClient<Database, 'public'>
) => {
  const { error } = await supabase
    .from('example_prompt_favorite')
    .delete()
    .eq('example_prompt_id', examplePromptId)
    .eq('user_id', userId)
    .select();

  if (error) {
    return createSupabaseErrorResponse(
      error,
      'Error deleting example prompt favorite'
    );
  }

  return { error: null };
};

/**
 * Creates a record in the example_prompt_usage table to track when a user uses an example prompt.
 * @param examplePromptId - the id of the example prompt that was used
 * @param userId - the user id of the user that used the example prompt
 */
export const insertExamplePromptUsage = async (
  examplePromptId: string,
  userId: string,
  supabase: SupabaseClient<Database, 'public'>
) => {
  const { data, error } = await supabase
    .from('example_prompt_usage')
    .insert([{ example_prompt_id: examplePromptId, user_id: userId }])
    .select();

  if (error) {
    return createSupabaseErrorResponse(
      error,
      'Error inserting example prompt usage'
    );
  }

  const ret = data[0];

  return { data: ret, error: null };
};
