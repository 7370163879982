import SelectableCollectionFileList, {
  FileWithStatus,
} from './SelectableCollectionFileList.js';
import { useBreakpointValue } from '@chakra-ui/react';
import { faAngleRight, faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { useEffect, useMemo, useState } from 'react';

import { ExtendedBucket } from '../../../@types/extendedTypes.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ListItem from '../../../components/ListItem.js';
import { captureException } from '@sentry/react';
import { decodeName } from 'common-ts';
import { faFolder } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { useToastManagerHook } from '../../../general/useToastManagerHook.js';
import { useBoundStore } from '../../../store/useBoundStore.js';

type CollectionNavigatorProps = {
  searchTerm: string;
  collections: ExtendedBucket[];
  isPowerModeEnabled: boolean;
  collectionSelectedFileIdMap: Map<string, Map<string, string>> | undefined;
  onFileSelect: (
    collectionId: string,
    files: { name: string; id: string }[]
  ) => void;
  onFileUnselect: (
    collectionId: string,
    files: { name: string; id: string }[]
  ) => void;
};

function CollectionNavigator({
  searchTerm,
  collections,
  collectionSelectedFileIdMap,
  onFileSelect,
  onFileUnselect,
}: CollectionNavigatorProps) {
  const { t } = useTranslation();
  const supabase = useBoundStore((state) => state.supabase);
  const { showToast } = useToastManagerHook();

  const isMobile = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  const [selectedCollection, setSelectedCollection] = useState<
    { id: string; name: string } | undefined
  >(undefined);
  const [collectionFilesMap, setCollectionFilesMap] = useState<
    Map<string, FileWithStatus[]>
  >(new Map());

  async function fetchCollectionFiles() {
    for (const collection of collections) {
      const { data: objectsData, error: objectsError } = await supabase.storage
        .from(collection.id)
        .list(undefined, { limit: 1000 });
      const { data: fileInfoData, error: fileInfoError } = await supabase
        .from('document_view')
        .select('embedding_status, file_id')
        .eq('bucket_id', collection.id);
      if (objectsData && fileInfoData) {
        const filesWithStatus: FileWithStatus[] = [];

        objectsData.forEach((file) => {
          const fileInfo = fileInfoData.find(
            (fileInfo) => fileInfo.file_id === file.id
          );
          filesWithStatus.push({
            ...file,
            embeddingStatus: fileInfo?.embedding_status ?? 'FAILED',
          });
        });

        setCollectionFilesMap((prev) => {
          prev.set(collection.id, filesWithStatus);
          return new Map(prev);
        });
      } else if (objectsError || fileInfoError) {
        captureException(objectsError ?? fileInfoError);
        showToast({
          title: t('chat.fileSelector.loadingFileError'),
          status: 'error',
        });
      }
    }
  }

  useEffect(() => {
    fetchCollectionFiles();
  }, []);

  const filteredCollections = useMemo(() => {
    return collections.filter(
      (collection) =>
        decodeName(collection.display_name)
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        collectionFilesMap
          ?.get(collection.id)
          ?.find((file) =>
            file.name.toLowerCase().includes(searchTerm.toLowerCase())
          )
    );
  }, [collections, searchTerm, collectionFilesMap]);

  const collectionElements = useMemo(() => {
    return filteredCollections.map((collection) => {
      const decodedName = decodeName(collection.display_name);
      const searchTermIndex = decodedName
        .toLowerCase()
        .indexOf(searchTerm.toLowerCase());
      const prefix = decodedName.slice(0, searchTermIndex);
      const boldPart = decodedName.slice(
        searchTermIndex,
        searchTermIndex + searchTerm.length
      );
      const suffix = decodedName.slice(searchTermIndex + searchTerm.length);

      return (
        <ListItem
          variant="left"
          key={collection.id}
          className={`flex-shrink-0`}
          selected={selectedCollection?.id === collection.id}
          domElementProps={{
            onClick: () => {
              setSelectedCollection({
                id: collection.id,
                name: collection.display_name,
              });
            },
          }}
        >
          <div
            className={`flex w-full items-center justify-between gap-2 truncate`}
          >
            <div className="flex min-w-0 items-center gap-2">
              <FontAwesomeIcon
                icon={faFolder}
                className="text-chakra-gray-400 text-base"
              />
              {searchTermIndex > -1 ? (
                <div className="truncate">
                  <span>{prefix}</span>
                  <span className="font-semibold">{boldPart}</span>
                  <span>{suffix}</span>
                </div>
              ) : (
                <div>{decodedName}</div>
              )}
            </div>
            <div className="text-chakra-gray-500 flex items-center gap-1">
              <div>{collectionFilesMap?.get(collection.id)?.length}</div>
              <FontAwesomeIcon icon={faAngleRight} className="text-xs" />
            </div>
          </div>
        </ListItem>
      );
    });
  }, [filteredCollections, searchTerm, collectionFilesMap, selectedCollection]);

  if (isMobile) {
    return (
      <div className="flex h-full w-full flex-col">
        {selectedCollection?.id ? (
          <>
            <SelectableCollectionFileList
              key={selectedCollection?.id}
              className="w-full"
              files={collectionFilesMap?.get(selectedCollection?.id)}
              searchTerm={searchTerm}
              selectedFiles={collectionSelectedFileIdMap?.get(
                selectedCollection?.id
              )}
              onFileSelect={(files) =>
                onFileSelect(selectedCollection?.id, files)
              }
              onFileUnselect={(files) =>
                onFileUnselect(selectedCollection?.id, files)
              }
              onRefreshFilesClick={fetchCollectionFiles}
            />
            {/* Hässlon */}
            <div className="absolute top-0 z-10 flex h-14 w-full items-center justify-between bg-white px-4">
              <FontAwesomeIcon
                icon={faArrowLeft}
                className="cursor-pointer select-none"
                onClick={() => setSelectedCollection(undefined)}
              />
              <div className="flex items-center gap-4">
                <FontAwesomeIcon
                  icon={faFolder}
                  className="text-maia-gray-400"
                />
                <div>{decodeName(selectedCollection.name)}</div>
              </div>
            </div>
          </>
        ) : (
          <div className="flex w-full min-w-0 flex-grow flex-col overflow-y-auto">
            {collectionElements}
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div className="border-maia-border flex h-[515px] w-full flex-col border-b border-t">
        <div className="flex min-h-0 flex-grow">
          {/* ------------------------------------------------ Collections ------------------------------------------------ */}
          <div className="flex min-w-[340px] basis-1/2 flex-col overflow-y-auto">
            {collectionElements}
          </div>
          <div className="border-maia-border border-r" />
          {/* ------------------------------------------------ Files ------------------------------------------------ */}
          <div className="min-w-0 basis-1/2">
            {selectedCollection?.id && (
              <SelectableCollectionFileList
                key={selectedCollection?.id}
                className="w-full"
                files={collectionFilesMap?.get(selectedCollection?.id)}
                searchTerm={searchTerm}
                selectedFiles={collectionSelectedFileIdMap?.get(
                  selectedCollection?.id
                )}
                onFileSelect={(files) =>
                  onFileSelect(selectedCollection?.id, files)
                }
                onFileUnselect={(files) =>
                  onFileUnselect(selectedCollection?.id, files)
                }
                onRefreshFilesClick={() => {
                  fetchCollectionFiles();
                }}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default CollectionNavigator;
