import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Progress,
  Radio,
  RadioGroup,
  Skeleton,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { captureException } from '@sentry/react';
import { fetchApi } from '../../../utils/useApi';
import { useBoundStore } from '../../../store/useBoundStore';
import { useToastManagerHook } from '../../../general/useToastManagerHook';
import { useTranslation } from 'react-i18next';
import { zeros } from 'common-ts';

type MoveCollectionModalProps = {
  isOpen: boolean;
  collectionId: string;
  collectionName: string;
  onClose: () => void;
};

function MoveCollectionModal({
  isOpen,
  collectionId,
  collectionName,
  onClose,
}: MoveCollectionModalProps) {
  const { t } = useTranslation();
  const supabase = useBoundStore((state) => state.supabase);
  const workspaceId = useBoundStore((state) => state.workspaceId);
  const updateExtendedBuckets = useBoundStore(
    (state) => state.updateExtendedBuckets
  );
  const { showToast } = useToastManagerHook();

  const [workspacesLoading, setWorkspacesLoading] = useState(true);
  const [availableWorkspaces, setAvailableWorkspaces] = useState<
    | {
        id: string;
        name: string;
        license_type: 'FREE' | 'BASIC' | 'PROFESSIONAL' | 'ENTERPRISE';
      }[]
    | undefined
  >(undefined);
  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState<
    string | undefined
  >(undefined);

  const [showStorageError, setShowStorageError] = useState(false);
  const [migrationInProgress, setMigrationInProgress] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  useEffect(() => {
    if (isOpen) {
      async function fetchWorkspaces() {
        setWorkspacesLoading(true);
        const { data, error } = await supabase
          .from('workspace')
          .select('id, name, license_type')
          .neq('id', workspaceId)
          .neq('license_type', 'FREE');

        if (!data || error) {
          showToast({ title: t('general.tryAgainError'), status: 'error' });
          if (error) {
            captureException(error);
          }
          return;
        }

        setAvailableWorkspaces(data);
        setWorkspacesLoading(false);
      }

      fetchWorkspaces();
    }
  }, [isOpen]);

  async function handleMigrateCollection() {
    if (!selectedWorkspaceId) {
      return;
    }
    setShowStorageError(false);
    setMigrationInProgress(true);

    const res = await fetchApi(supabase, '/files', '/migrate_collection', {
      newWorkspaceId: selectedWorkspaceId,
      collectionId,
      method: 'POST',
    });

    if (!res.success) {
      if (res.status === 551) {
        setShowStorageError(true);
      } else {
        showToast({ title: t('general.tryAgainError'), status: 'error' });
      }
      setMigrationInProgress(false);
      return;
    }
    setShowSuccessMessage(true);
    setMigrationInProgress(false);
  }

  async function handleClose() {
    if (migrationInProgress) {
      return;
    }

    if (showSuccessMessage) {
      await updateExtendedBuckets();
    }
    onClose();
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {t('fileManagerPanel.migrateToWorkspace.modalHeader')}
        </ModalHeader>
        <ModalCloseButton isDisabled={migrationInProgress} />
        <ModalBody className="flex flex-col gap-4">
          {showSuccessMessage ? (
            <Alert status="success">
              <AlertIcon />
              <AlertTitle>
                {t('fileManagerPanel.migrateToWorkspace.successHeader')}
              </AlertTitle>
              <AlertDescription>
                {t('fileManagerPanel.migrateToWorkspace.successBody', {
                  collectionName,
                  workspaceName:
                    availableWorkspaces?.find(
                      (workspace) => workspace.id === selectedWorkspaceId
                    )?.name ?? '',
                })}
              </AlertDescription>
            </Alert>
          ) : (
            <>
              <div>{t('fileManagerPanel.migrateToWorkspace.modalBody')}</div>
              <Alert status="warning">
                <AlertIcon />
                {t('fileManagerPanel.migrateToWorkspace.sharesRemovedWarning')}
              </Alert>
              <RadioGroup
                className="flex flex-col gap-4"
                onChange={(workspaceId) => {
                  setSelectedWorkspaceId(workspaceId);
                }}
                value={selectedWorkspaceId}
              >
                {workspacesLoading
                  ? zeros(3).map((_, index) => {
                      return <Skeleton key={index} />;
                    })
                  : availableWorkspaces?.map((workspace) => {
                      return (
                        <Radio value={workspace.id} size={'sm'}>
                          {workspace.name}
                        </Radio>
                      );
                    })}
              </RadioGroup>
              {migrationInProgress && (
                <Progress colorScheme="maia-accent" isIndeterminate />
              )}
              {showStorageError && (
                <Alert status="error">
                  <AlertIcon />
                  <Box>
                    <AlertTitle>
                      {t(
                        'fileManagerPanel.migrateToWorkspace.storageLimitReachedHeader'
                      )}
                    </AlertTitle>
                    <AlertDescription>
                      {t(
                        'fileManagerPanel.migrateToWorkspace.storageLimitReachedBody'
                      )}
                    </AlertDescription>
                  </Box>
                </Alert>
              )}
            </>
          )}
        </ModalBody>

        <ModalFooter>
          {showSuccessMessage ? (
            <Button variant={'outline'} onClick={handleClose}>
              {t('general.closeButtons')}
            </Button>
          ) : (
            <>
              <Button
                variant={'outline'}
                mr={3}
                onClick={handleClose}
                isDisabled={migrationInProgress}
              >
                {t('general.cancelButton')}
              </Button>
              <Button
                colorScheme="maia-accent"
                isDisabled={!selectedWorkspaceId || migrationInProgress}
                onClick={handleMigrateCollection}
              >
                {t('fileManagerPanel.migrateToWorkspace.confirmButton')}
              </Button>
            </>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default MoveCollectionModal;
