/**
 * Storage limits per workspace seat of each plan.
 */
export const planStorageLimitsMb = new Map([
    ['FREE', 0],
    ['BASIC', 5120],
    ['PROFESSIONAL', 5120],
    ['ENTERPRISE', 10240],
    [undefined, 0],
]);
/**
 * Size limitation for individual uploaded files
 */
export const planFileSizeLimitMbMap = {
    FREE: 0,
    BASIC: 150,
    PROFESSIONAL: 150,
    // If you set this higher, you will also have to raise the supabase limit
    // compose.supabase.yml For our servers (FILE_SIZE_LIMIT)
    // config.toml for local development(file_size_limit)
    ENTERPRISE: 300,
};
