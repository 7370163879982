import { useEffect, useRef, useState, useCallback } from 'react';

/**
 * Custom hook that provides a timer functionality.
 * The timer will start counting down from the provided duration.
 * It will keep in account the time drift caused by the interval function.
 *
 * @param duration - The duration of the timer in seconds.
 * @returns An object containing the `timeLeft` and `reset` function.
 */
function useTimer(duration: number) {
  const [timeLeft, setTimeLeft] = useState(duration);
  const intervalId = useRef<NodeJS.Timeout | null>(null);
  const [shouldReset, setShouldReset] = useState(false);

  const reset = useCallback(() => {
    setTimeLeft(duration);
    setShouldReset(true);
    if (intervalId.current) {
      clearInterval(intervalId.current);
      intervalId.current = null;
    }
  }, [duration]);

  useEffect(() => {
    if (shouldReset) setShouldReset(false);

    const endTime = Date.now() + duration * 1000;
    intervalId.current = setInterval(() => {
      const newTimeLeft = Math.round((endTime - Date.now()) / 1000);
      if (newTimeLeft <= 0) {
        clearInterval(intervalId.current as NodeJS.Timeout);
        intervalId.current = null;
        setTimeLeft(0);
      } else {
        setTimeLeft(newTimeLeft);
      }
    }, 1000);

    return () => {
      if (intervalId.current) {
        clearInterval(intervalId.current);
      }
    };
  }, [duration, shouldReset]);

  return { timeLeft, reset };
}

export default useTimer;
