import { Database } from 'common-ts';
import { ExtendedBucket } from '../../@types/extendedTypes';
import { FeatureFlags } from '../../general/featureFlags';
import { SupabaseClient } from '@supabase/supabase-js';
import { fetchApi } from '../../utils/useApi';
import { getExtendedBuckets } from '../utils';
import { getUserPlan } from '../../utils/userPlan';

export type WorkspaceState = {
  loading: boolean;
  extendedBuckets: ExtendedBucket[];
  featureFlags: FeatureFlags | undefined;
  workspaceId: string;
  workspaceUserType: Database['public']['Enums']['workspace_user_type'];
  workspaceInactive: Database['public']['Tables']['workspace']['Row']['inactive'];
  workspaceLicenseType:
    | Database['public']['Tables']['workspace']['Row']['license_type']
    | null;
  workspaceUserDisabled: Database['public']['Tables']['workspace_user']['Row']['disabled'];
  isStripeCustomer: string | undefined;
  isInFreeTrial: number | undefined; // string -> unix timestamp (milliseconds) of trial end date, undefined -> no trial running
  lastVisitedCollection: string | undefined;
};

export async function fetchWorkspaceStateInfo(
  workspaceId: string,
  supabase: SupabaseClient<Database, 'public'>,
  userId: string
) {
  const userPlanResult = await getUserPlan(supabase, workspaceId);
  // TODO can we use toasts in here if something fails?
  const { data } =
    userPlanResult.workspaceUserType === 'ADMIN' ||
    userPlanResult.workspaceUserType === 'OWNER'
      ? await fetchApi(supabase, '/workspace', '/is_stripe_customer', {
          method: 'POST',
          workspaceId,
        })
      : { data: undefined };

  const {
    workspaceUserType,
    workspaceInactive,
    workspaceUserDisabled,
    featureFlags,
    licenseType: workspaceLicenseType,
  } = userPlanResult;

  const extendedBuckets = userId
    ? await getExtendedBuckets(supabase, userId, workspaceId)
    : [];

  const state: Omit<
    WorkspaceState,
    'workspaceId' | 'loading' | 'lastVisitedCollection'
  > = {
    workspaceUserType,
    workspaceInactive,
    workspaceLicenseType,
    workspaceUserDisabled,
    featureFlags,
    extendedBuckets,
    isStripeCustomer: data?.stripeId,
    isInFreeTrial: data?.endDateSecondsIfTrial,
  };

  return state;
}
