import { Divider } from '@chakra-ui/react';
import WorkspaceSwitcher from './menu/WorkspaceSwitcher.js';
import SubscriptionUpdateBanner from '../pages/home/SubscriptionUpdateBanner.js';

type MaiaLayoutProps = {
  sidePanelContent: React.ReactElement;
  mainPanelContent: React.ReactElement;
  sidePanelOpenMobile: boolean;
  onOpenSidePanelMobile: () => void;
  onCloseSidePanelMobile: () => void;
};

/**
 * Layout Component for the basic side menu + main application panel layout.
 * Widths of the panels are enforced within this component so that they are congruent on every page that uses this layout.
 * @returns
 */
function MaiaLayout({
  sidePanelContent,
  mainPanelContent,
  sidePanelOpenMobile,
  onOpenSidePanelMobile,
  onCloseSidePanelMobile,
}: MaiaLayoutProps) {
  return (
    <div className="flex h-full w-full flex-col md:flex-row">
      <WorkspaceSwitcher
        className="md:hidden"
        onMenuButtonClick={
          sidePanelOpenMobile ? onCloseSidePanelMobile : onOpenSidePanelMobile
        }
      />
      <Divider className="md:hidden" />
      <div
        className={`border-r-1 border-maia-border min-h-0 w-full min-w-0 flex-grow bg-white md:h-full md:w-80 md:flex-shrink-0 md:flex-grow-0 ${
          sidePanelOpenMobile ? '' : 'hidden md:block'
        }`}
      >
        {sidePanelContent}
      </div>
      <div
        className={`relative flex-grow overflow-auto bg-white md:h-full md:min-h-0 md:min-w-0 ${
          sidePanelOpenMobile ? 'hidden md:block' : ''
        }`}
      >
        <SubscriptionUpdateBanner />
        {mainPanelContent}
      </div>
    </div>
  );
}

export default MaiaLayout;
