import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import i18n from '../../i18n.js';
import { supportedLanguages } from '../../components/ChangeLanguage.js';
import { useTranslation } from 'react-i18next';

function LanguageSetting() {
  const { t } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(
    supportedLanguages.find((lang) => lang.short === i18n.language)
  );

  useEffect(() => {
    i18n.changeLanguage(currentLanguage?.short);
  }, [currentLanguage]);

  const setLanguage = (lang: (typeof supportedLanguages)[number]) => {
    setCurrentLanguage(lang);
    i18n.changeLanguage(lang.short);
  };
  return (
    <Menu matchWidth gutter={4}>
      {({ isOpen }) => (
        <>
          <MenuButton className="border-maia-border hover:border-maia-accent w-full rounded-md border bg-white px-3 py-1.5 text-left">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2">
                <div>
                  {currentLanguage?.full || t('searchPanel.dataChooser')}
                </div>
              </div>
              {isOpen ? (
                <FontAwesomeIcon
                  icon={faChevronUp}
                  className="text-maia-accent"
                />
              ) : (
                <FontAwesomeIcon icon={faChevronDown} />
              )}
            </div>
          </MenuButton>
          <MenuList className="flex flex-col gap-1 py-1.5 text-left shadow">
            {supportedLanguages.map((lang) => (
              <MenuItem
                key={lang.short}
                className="hover:bg-maia-blue-100 flex min-w-0 items-center gap-2"
                onClick={() => {
                  setLanguage(lang);
                }}
              >
                <span>{lang.full}</span>
              </MenuItem>
            ))}
          </MenuList>
        </>
      )}
    </Menu>
  );
}

export default LanguageSetting;
