import { Button, HStack, PinInput, PinInputField } from '@chakra-ui/react';
import { Database, ROUTES } from 'common-ts';
import { captureEvent, captureException, captureMessage } from '@sentry/react';
import { useEffect, useRef, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ResendEmail2FA from '../userSettings/securitySettings/ResendEmail2FA';
import { faLockKeyhole } from '@fortawesome/pro-regular-svg-icons';
import { fetchApi } from '../../utils/useApi';
import { navigateToDefaultWorkspace } from '../../utils/getDefaultWorkspace';
import { useBoundStore } from '../../store/useBoundStore.js';
import { useNavigate } from 'react-router-dom';
import { useToastManagerHook } from '../../general/useToastManagerHook';
import { useTranslation } from 'react-i18next';
import { useTypedState } from 'react-router-typesafe-routes/dom';
import { handleJoin } from '../../utils/inviteLink';

type MFAType = Database['public']['Enums']['mfa_type'];

type TwoFactorFormContent = { [key in MFAType]: { subtitle: string } };

export default function TwoFactorVerificationForm() {
  const {
    challengeId,
    email,
    password,
    type = 'NONE',
    inviteCode,
  } = useTypedState(ROUTES.AUTH.TWO_FACTOR);

  const supabase = useBoundStore((state) => state.supabase);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { showToast } = useToastManagerHook();

  const [code, setCode] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const [currentChallengeId, setCurrentChallengeId] =
    useState<string>(challengeId);

  const verifyButtonRef = useRef<HTMLButtonElement>(null);

  const twoFactorFormContent: TwoFactorFormContent = {
    AUTHENTICATOR: {
      subtitle: t('userSettings.auth.2FA.authenticatorSubtitle'),
    },
    EMAIL: {
      subtitle: t('userSettings.auth.2FA.emailSubtitle'),
    },
    NONE: {
      subtitle: '',
    },
  };

  const handleVerify = async () => {
    if (!currentChallengeId) return;
    setLoading(true);

    const res = await fetchApi(
      supabase,
      '/auth',
      '/two_factor',
      {
        method: 'POST',
        challengeId: currentChallengeId,
        email,
        password,
        code,
        type,
      },
      true
    );

    if (res.success) {
      const { accessToken, refreshToken } = res.data;
      const sessionRes = await supabase.auth.setSession({
        access_token: accessToken,
        refresh_token: refreshToken,
      });
      if (sessionRes.error) {
        captureException(sessionRes.error);
        showToast({
          title: t('general.reloadError'),
          status: 'error',
        });
      } else {
        setLoading(false);
        if (inviteCode) {
          handleJoin(inviteCode, supabase, t, navigate, showToast);
        } else {
          navigateToDefaultWorkspace({
            navigateFn: navigate,
            showToast,
            supabase,
            translationFn: t,
          });
        }
      }
      setLoading(false);
    } else {
      switch (res.status) {
        case 400:
          const error = res?.data?.error?.toLocaleLowerCase() || '';

          if (error.includes('invalid'))
            showToast({
              title: t('userSettings.security.invalidCodeError'),
              status: 'error',
            });
          if (error.includes('expired'))
            showToast({
              title: t(
                'userSettings.security.emailVerificationModal.otpExpiredError'
              ),
              status: 'error',
            });
          break;
        default:
          captureMessage(
            `Unhandled result status (${res.status}, ${res.error.message}) in two factor response.`,
            'error'
          );
          showToast({
            title: t('general.reloadError'),
            status: 'error',
          });
          break;
      }
      setLoading(false);
    }
  };

  const sendNewOtp = async () => {
    const authResponse = await fetchApi(
      supabase,
      '/auth',
      '/login',
      {
        method: 'POST',
        email,
        password,
      },
      true
    );
    if (
      authResponse.success &&
      authResponse.status === 242 &&
      'challengeId' in authResponse.data &&
      authResponse.data.type === 'EMAIL'
    ) {
      setCurrentChallengeId(authResponse.data.challengeId);
      showToast({
        title: t(
          'userSettings.security.emailVerificationModal.resendCodeSuccess'
        ),
        status: 'success',
      });
    } else {
      captureEvent({
        message: 'Error sending new otp',
        level: 'error',
        extra: {
          status: authResponse.status,
          authResponse2FType:
            authResponse.data && 'type' in authResponse.data
              ? authResponse.data.type
              : '',
        },
      });
      showToast({
        title: t('general.reloadError'),
        status: 'error',
      });
    }
  };
  useEffect(() => {
    if (!challengeId || !email || !password || type === 'NONE')
      navigate(ROUTES.AUTH.SIGN_IN.buildPath({}));

    // workaround for pin input enter keypress
    // pin input does not have a onKeyPress/up/down event
    const handleEnterKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && verifyButtonRef.current) {
        verifyButtonRef.current.click();
      }
    };
    document.addEventListener('keypress', handleEnterKeyPress);
    return () => {
      document.removeEventListener('keypress', handleEnterKeyPress);
    };
  }, []);

  return (
    <div className="text-maia-gray-700 flex w-56 max-w-none flex-col gap-y-2 rounded-md sm:w-max sm:max-w-sm">
      <div className="text-maia-purple-700 bg-maia-purple-100 mb-4 flex h-16 w-16 items-center justify-center self-center rounded-full p-4">
        <FontAwesomeIcon icon={faLockKeyhole} className="h-8 w-8" />
      </div>
      <div className="text-center font-semibold">
        {t('userSettings.auth.2FA.title')}
      </div>
      <div className="sm:text-normal text-center">
        {twoFactorFormContent[type].subtitle}
      </div>
      <HStack className="mt-8 self-center">
        <PinInput
          variant={'flushed'}
          focusBorderColor="maia-purple.500"
          onChange={(code) => {
            setCode(code);
          }}
          size={'sm'}
          autoFocus
          otp
        >
          <PinInputField />
          <PinInputField />
          <PinInputField />
          <PinInputField />
          <PinInputField />
          <PinInputField />
        </PinInput>
      </HStack>
      {type === 'EMAIL' && <ResendEmail2FA sendNewOtp={sendNewOtp} />}
      <Button
        ref={verifyButtonRef}
        className="mt-4 w-full self-center sm:w-max"
        isDisabled={code.length < 6}
        variant={'solid'}
        colorScheme="maia-purple"
        onClick={handleVerify}
        isLoading={loading}
      >
        {t('userSettings.auth.2FA.verifyButton')}
      </Button>
    </div>
  );
}
