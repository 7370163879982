import { LayoutItemType } from '../index.js';
/**
 * Formats a string of text as markdown, based on the blockType.
 * @param content The text that shall be formatted.
 * @param blockType The block type based on which the formatting is applied.
 * @returns content with added markdown formatting
 */
export function highlightWithMarkdown(content, blockType) {
    switch (blockType) {
        case LayoutItemType.Title:
            return `### ${content}`;
        case LayoutItemType.Footnote:
            return content;
        case LayoutItemType.PageFooter:
            return content;
        case LayoutItemType.PageHeader:
            return `# ${content}`;
        case LayoutItemType.Paragraph:
            return content;
        case LayoutItemType.SectionHeading:
            return `## ${content}`;
        case LayoutItemType.Table:
            return content;
        default:
            return content;
    }
}
/**
 * Converts an array of BasicLayoutItems to a continuous string of markdown formatted text.
 * @param layoutItems BasicLayoutItems to convert.
 * @returns Continuous string of markdown formatted text.
 */
export function layoutItemsToMarkdown(layoutItems) {
    let fullMarkdownText = '';
    for (const layoutItem of layoutItems) {
        fullMarkdownText += `${highlightWithMarkdown(layoutItem.content, layoutItem.type)}\n\n`;
    }
    return fullMarkdownText;
}
