import { Database } from 'common-ts';
import { Stripe } from '@stripe/stripe-js';
import { SupabaseClient } from '@supabase/supabase-js';
import { fetchApi } from './useApi.js';

export async function handleSubscription(
  plan: Exclude<
    Database['public']['Enums']['license_tier'],
    'FREE' | 'ENTERPRISE'
  >,
  workspaceId: string,
  stripe: Stripe,
  supabase: SupabaseClient<Database>,
  seats: number
) {
  const response = await fetchApi(supabase, '/payment', '/upgrade', {
    method: 'POST',
    plan,
    workspace_id: workspaceId,
    seats,
  });

  if (!response.success) {
    return false;
  }

  if (!stripe) {
    return false;
  }

  await stripe.redirectToCheckout({ sessionId: response.data.session_id });
  return true;
}

export async function handleWorkspaceRenew(
  workspaceId: string,
  stripe: Stripe,
  supabase: SupabaseClient
) {
  const response = await fetchApi(supabase, '/payment', '/renew_workspace', {
    method: 'POST',
    workspaceId,
  });

  if (!response.success) {
    return false;
  }

  await stripe.redirectToCheckout({
    sessionId: response.data.checkout_session_id,
  });
}

export async function handleBuyAdaPages(
  workspaceId: string,
  stripe: Stripe,
  supabase: SupabaseClient,
  redirectTo: string
) {
  const response = await fetchApi(supabase, '/payment', '/buy_ada_pages', {
    method: 'POST',
    workspaceId,
    redirectTo,
  });

  if (!response.success) {
    return false;
  }

  await stripe.redirectToCheckout({
    sessionId: response.data.checkoutSessionId,
  });
}

// export async function handleBuyPowerMode(
//   workspaceId: string,
//   stripe: Stripe,
//   supabase: SupabaseClient<Database>
// ) {
//   if (!stripe) {
//     return false;
//   }

//   const response = await fetchApi(supabase, '/payment', '/buy_powermode', {
//     method: 'POST',
//     workspaceId,
//   });

//   if (!response.success) {
//     return false;
//   }

//   await stripe.redirectToCheckout({
//     sessionId: response.data.checkoutSessionId,
//   });
//   return true;
// }
