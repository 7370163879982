import { useEffect, useRef } from 'react';

import { FREE_USER_REQUEST_LIMT } from '../../utils/userPlan.js';
import { useBoundStore } from '../../store/useBoundStore.js';
import { useToastManagerHook } from '../../general/useToastManagerHook.js';
import { useTranslation } from 'react-i18next';

function ChatRequestLimitToaster() {
  const { t } = useTranslation();
  const workspaceLicenseType = useBoundStore(
    (state) => state.workspaceLicenseType
  );
  const sentRequests = useBoundStore((state) => state.sentRequests);
  const { showToast } = useToastManagerHook();

  const prevRequestCountRef = useRef<number>();

  useEffect(() => {
    if (workspaceLicenseType === 'FREE') {
      if (
        sentRequests % 10 === 0 &&
        sentRequests > 0 &&
        sentRequests !== prevRequestCountRef.current
      ) {
        showToast({
          title: t('chat.messageLimitInfo', {
            used: sentRequests,
            total: FREE_USER_REQUEST_LIMT,
          }),
          status: 'info',
        });
        prevRequestCountRef.current = sentRequests;
      }
    }
  }, [sentRequests]);

  return <></>;
}

export default ChatRequestLimitToaster;
