import { AvailableModelEnum, isModelAvailableForPlan } from 'common-ts';

import { UserPlan } from './userPlan';

export const gptKey = '05aec42a-62fa-4710-aaf9-613479c5923e';

export function setLlmModel(model: AvailableModelEnum) {
  localStorage.setItem(gptKey, model);
}
export function getLlmModel(
  userPlan: UserPlan | undefined
): AvailableModelEnum {
  const model = localStorage.getItem(gptKey);

  if (!model || !isValueInEnum(model, AvailableModelEnum)) {
    return AvailableModelEnum.GPT_4;
  }

  if (
    !isModelAvailableForPlan(userPlan ?? 'FREE', model as AvailableModelEnum)
  ) {
    return AvailableModelEnum.GPT_3_5_TURBO_16K;
  }

  return model as AvailableModelEnum;
}

function isValueInEnum(value: string, enumeration: any): boolean {
  return Object.values(enumeration).includes(value);
}
