import { ReactNode } from 'react';
import { WorkspaceInfoSlice } from '../store/slices/createWorkspaceInfoSlice';
import { useBoundStore } from '../store/useBoundStore';

type AccessControlProps = {
  allowedLicenses?: WorkspaceInfoSlice['workspaceLicenseType'][];
  allowedRoles?: WorkspaceInfoSlice['workspaceUserType'][];
  children: ReactNode;
};

function AccessControl({
  allowedLicenses,
  allowedRoles,
  children,
}: AccessControlProps) {
  const license = useBoundStore((state) => state.workspaceLicenseType);
  const role = useBoundStore((state) => state.workspaceUserType);

  const show =
    (allowedRoles ? allowedRoles.includes(role) : true) &&
    (allowedLicenses ? allowedLicenses.includes(license) : true);
  return show ? children : null;
}

export default AccessControl;
