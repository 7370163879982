import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { Database } from 'common-ts';
import { useApi } from '../../utils/useApi';
import { useBoundStore } from '../../store/useBoundStore';
import { useTranslation } from 'react-i18next';

type TransferOwnerShipModalProps = {
  user:
    | Database['public']['Views']['workspace_users_admin_view']['Row']
    | undefined;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (userId: string | undefined) => void;
};

function TransferOwnerShipModal({
  user,
  isOpen,
  onClose,
  onConfirm,
}: TransferOwnerShipModalProps) {
  const { t } = useTranslation();
  const [emailConfirmationValue, setEmailConfirmationValue] = useState('');
  const supabase = useBoundStore((state) => state.supabase);
  const workspaceId = useBoundStore((state) => state.workspaceId);
  const { data: isStipeCustomer } = useApi(
    supabase,
    '/workspace',
    '/is_stripe_customer',
    {
      method: 'POST',
      workspaceId,
    }
  );

  useEffect(() => {
    setEmailConfirmationValue('');
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {t('workspaceSettings.transferOwnershipModal.title')}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody className="flex flex-col gap-4">
          <div>
            {t('workspaceSettings.transferOwnershipModal.transferInfo', {
              userName: user?.email,
            })}
            <br />
            {isStipeCustomer?.stripeId
              ? t('workspaceSettings.transferOwnershipModal.stripeInfo')
              : null}
            <br />
            {t('workspaceSettings.transferOwnershipModal.noUndo')}
          </div>
          <FormControl>
            <FormLabel>
              {t('workspaceSettings.transferOwnershipModal.enterEmailLabel')}
            </FormLabel>
            <Input
              value={emailConfirmationValue}
              onChange={(e) => {
                setEmailConfirmationValue(e.target.value);
              }}
              onKeyDown={(e) => {
                if (
                  e.key === 'Enter' &&
                  emailConfirmationValue === user?.email
                ) {
                  onConfirm(user.user_id ?? undefined);
                  onClose();
                }
              }}
            />
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <div className="flex items-center gap-2">
            <Button colorScheme="gray" onClick={onClose}>
              {t('general.cancelButton')}
            </Button>
            <Button
              colorScheme="maia-accent"
              mr={3}
              onClick={() => {
                onConfirm(user?.user_id ?? undefined);
                onClose();
              }}
              isDisabled={emailConfirmationValue !== user?.email}
            >
              {t('workspaceSettings.transferOwnershipModal.confirmButton')}
            </Button>
          </div>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default TransferOwnerShipModal;
