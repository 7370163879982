import Logo from '../../components/Logo/Logo';
import { useTranslation } from 'react-i18next';

export default function NotFoundPage() {
  const { t } = useTranslation();

  return (
    <div className="flex h-screen w-full flex-col items-center justify-center space-y-12">
      <span className="animate-bounce">
        <Logo size={80} />
      </span>
      <span className="text-maia-gray-500 text-xl font-bold">
        {t('iframe.notFoundError')}
      </span>
    </div>
  );
}
