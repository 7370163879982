import Backend from 'i18next-http-backend';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import de from './locales/de/translation.json';
import en from './locales/en/translation.json';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

export const defaultNs = 'translation';

// Eslint has a rule against using exported members of the default export like this (in this case {use}).
// eslint-disable-next-line
i18next
  .use(Backend)
  .use(I18nextBrowserLanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: false,
    resources: {
      de: {
        translation: de,
      },
      en: {
        translation: en,
      },
    },
    supportedLngs: ['en', 'de'],
  });

export default i18next;
