import { Database, ROUTES } from 'common-ts';
import { useRef, useState } from 'react';

import ChooseSeatAmount from './ChooseSeatAmount';
import ChooseWorkspaceName from './ChooseWorkspaceName';
import HowDidYouHearOfUs from './HowDidYouHearOfUs';
import InviteColleagues from './InviteColleagues';
import TellUsAboutYourself from './TellUsAboutYourself';
import { captureException } from '@sentry/react';
import { fetchApi } from '../../utils/useApi';
import { handleSubscription } from '../../utils/handleSubscription';
import { useBoundStore } from '../../store/useBoundStore';
import { useNavigate } from 'react-router-dom';
import { useToastManagerHook } from '../../general/useToastManagerHook';
import { useTranslation } from 'react-i18next';
import { useTypedState } from 'react-router-typesafe-routes/dom';

function OnboardingProcess() {
  const { isTrial, plan } = useTypedState(ROUTES.HOME.ONBOARDING);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const supabase = useBoundStore((state) => state.supabase);
  const stripe = useBoundStore((state) => state.stripe);
  const stripeLoading = useBoundStore((state) => state.stripeLoading);
  const workspaceId = useBoundStore((state) => state.workspaceId);
  const userInfo = useBoundStore((state) => state.userInfo);
  const setIsOnboarded = useBoundStore((state) => state.setIsOnboarded);
  const updateWorkspaceState = useBoundStore(
    (state) => state.updateWorkspaceState
  );

  const { showToast } = useToastManagerHook();

  const [currentStep, setCurrentStep] = useState<
    | 'heardOfUs'
    | 'aboutYou'
    | 'workspaceName'
    | 'workspaceSeats'
    | 'inviteColleagues'
  >('heardOfUs');

  const [firstName, setFirstName] = useState('');
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastName, setLastName] = useState('');
  const [jobTitle, setJobTitle] = useState<
    Database['public']['Enums']['job_description'] | undefined
  >(undefined);
  const [jobTitleOther, setJobTitleOther] = useState<string | undefined>(
    undefined
  );

  const [workspaceNameEmptyError, setWorkspaceNameEmptyError] = useState(false);
  const [workspaceName, setWorkspaceName] = useState('');

  const [seatsEmptyError, setSeatsEmptyError] = useState(false);
  const [seatsInvalidError, setSeatsInvalidError] = useState(false);
  const [seats, setSeats] = useState(1);

  const [tooManyEmailsError, setTooManyEmailsError] = useState(false);
  const emailsRef = useRef<string[]>([]);

  const [finishLoading, setFinishLoading] = useState(false);

  function handleContinueToAboutYou() {
    setCurrentStep('aboutYou');
  }

  function handleContinueToChooseName(
    firstName: string,
    lastName: string,
    jobTitle: Database['public']['Enums']['job_description'] | undefined,
    jobTitleOther: string | undefined
  ) {
    if (!firstName) {
      setFirstNameError(true);
      return;
    }

    setFirstNameError(false);
    setFirstName(firstName);
    setLastName(lastName);
    setJobTitle(jobTitle);
    if (jobTitle === 'OTHER') {
      setJobTitleOther(jobTitleOther);
    }
    setCurrentStep('workspaceName');
  }

  function handleContinueFromWorkspaceName(givenName: string) {
    if (!givenName) {
      setWorkspaceNameEmptyError(true);
      return;
    }
    setWorkspaceNameEmptyError(false);
    setWorkspaceName(givenName);

    if (plan === 'PROFESSIONAL') {
      setCurrentStep('workspaceSeats');
    } else {
      handleFinish();
    }
  }

  function handleContinueFromSeats(chosenSeats: number | undefined) {
    if (!chosenSeats) {
      setSeatsEmptyError(true);
      return;
    }
    if (typeof chosenSeats !== 'number') {
      setSeatsInvalidError(true);
      return;
    }
    if (chosenSeats % 1 !== 0) {
      setSeatsInvalidError(true);
      return;
    }

    setSeatsEmptyError(false);
    setSeatsInvalidError(false);
    setSeats(chosenSeats);

    if (chosenSeats > 1) {
      setCurrentStep('inviteColleagues');
    } else {
      handleFinish();
    }
  }

  function handleContinueFromInviteColleagues(chosenEmails: string[]) {
    if (chosenEmails.length > seats) {
      setTooManyEmailsError(true);
      return;
    }

    setTooManyEmailsError(false);
    emailsRef.current = chosenEmails;
    handleFinish();
  }

  async function handleFinish() {
    setFinishLoading(true);
    const updatedUserSettingsData: Database['public']['Tables']['user_setting']['Update'] =
      {
        first_name: firstName,
        last_name: lastName,
        position: jobTitle,
        position_other: jobTitleOther,
      };

    if (!userInfo?.userId) return;

    try {
      const result = await supabase
        ?.from('user_setting')
        .update(updatedUserSettingsData)
        .eq('user_id', userInfo.userId);

      if (result.error) {
        throw new Error(result.error.message);
      }
    } catch (err) {
      captureException(err);
      showToast({
        title: t('general.tryAgainError'),
        status: 'error',
      });

      return;
    }

    const nameWorkspaceRes = await fetchApi(supabase, '/workspace', '/name', {
      method: 'PUT',
      name: workspaceName,
      workspaceId,
    });

    if (!nameWorkspaceRes.success) {
      showToast({ title: t('general.tryAgainError'), status: 'error' });
    }

    if (emailsRef.current.length) {
      const addUsersRes = await fetchApi(
        supabase,
        '/workspace',
        '/add_users_intent',
        { method: 'POST', emails: emailsRef.current, workspaceId }
      );

      if (!addUsersRes.success) {
        showToast({
          title: t('createWorkspace.inviteUsersError'),
          status: 'error',
        });
      }
    }

    if (isTrial) {
      const startTrialRes = await fetchApi(
        supabase,
        '/payment',
        '/start_trial',
        {
          method: 'POST',
          plan,
          seats,
          workspaceId,
        }
      );

      if (!startTrialRes.success) {
        showToast({ title: t('general.tryAgainError'), status: 'error' });
        return;
      }
    } else {
      if (!stripe) {
        showToast({
          title: t('general.stripe.connectToStripeFailed'),
          status: 'error',
        });
        return;
      }
      const subscriptionSuccess = await handleSubscription(
        plan,
        workspaceId,
        stripe,
        supabase,
        seats
      );

      if (!subscriptionSuccess) {
        showToast({ title: t('general.tryAgainError'), status: 'error' });
        return;
      }
    }
    setIsOnboarded(true);
    await new Promise((r) => setTimeout(r, 3000));
    setFinishLoading(false);
    await updateWorkspaceState(workspaceId);
    navigate(ROUTES.HOME.CHAT.buildPath({ workspaceId }));
  }

  return (
    <div className="flex h-screen w-screen items-center justify-center bg-white">
      {currentStep === 'heardOfUs' ? (
        <HowDidYouHearOfUs onContinue={handleContinueToAboutYou} />
      ) : currentStep === 'aboutYou' ? (
        <TellUsAboutYourself
          onContinue={handleContinueToChooseName}
          firstNameError={firstNameError}
        />
      ) : currentStep === 'workspaceName' ? (
        <ChooseWorkspaceName
          licenseType={plan}
          nameEmptyError={workspaceNameEmptyError}
          onContinue={handleContinueFromWorkspaceName}
          userName={`${firstName}${lastName ? ` ${lastName}` : ''}`}
        />
      ) : currentStep === 'workspaceSeats' ? (
        <ChooseSeatAmount
          onContinue={handleContinueFromSeats}
          seatsEmptyError={seatsEmptyError}
          seatsInvalidError={seatsInvalidError}
          isTrial={isTrial}
          continueButtonLoading={finishLoading || (!isTrial && stripeLoading)}
        />
      ) : currentStep === 'inviteColleagues' ? (
        <InviteColleagues
          onContinue={handleContinueFromInviteColleagues}
          tooManyEmailsError={tooManyEmailsError}
          continueButtonLoading={finishLoading || (!isTrial && stripeLoading)}
        />
      ) : null}
    </div>
  );
}

export default OnboardingProcess;
