export function convertMimeTypeToReadable(mimeType: string): string {
  const mimeTypeMappings: Record<string, string> = {
    'application/pdf': 'pdf',
    'application/msword': 'word',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      'word',
    'text/plain': 'text',
    'application/vnd.ms-powerpoint': 'power point',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      'power point',
    'application/msexcel': 'excel',
    'application/vnd.ms-excel': 'excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      'excel',
  };

  return mimeTypeMappings[mimeType] || mimeType;
}
