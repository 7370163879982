import {
  Prompt,
  PromptCollection,
  PromptLibraryContent,
} from './promptLibraryTypes';
import {
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import {
  getExamplePromptLibraryContent,
  getYourPromptLibraryContent,
} from './promptLibraryRequests';
import { useEffect, useState } from 'react';

import { PromptNavigator } from './PromptNavigator/PromptNavigator';
import SearchBar from '../../../components/SearchBar';
import { captureException } from '@sentry/react';
import i18n from '../../../i18n';
import { supportedLanguages } from '../../../components/ChangeLanguage';
import { useBoundStore } from '../../../store/useBoundStore';
import { useTranslation } from 'react-i18next';

type PromptLibraryModalContentProps = {
  onSelectedPromptChange: (prompt: Prompt, isExample: boolean) => void;
};

export const PromptLibraryModalContent = ({
  onSelectedPromptChange,
}: PromptLibraryModalContentProps) => {
  const { t } = useTranslation();
  const supabase = useBoundStore((state) => state.supabase);
  const [yourCollectionsContent, setYourCollectionsContent] =
    useState<PromptLibraryContent>();
  const [examplesContent, setExamplesContent] =
    useState<PromptLibraryContent>();
  const userInfo = useBoundStore((state) => state.userInfo);
  const [currentLanguage] = useState(
    supportedLanguages.find((lang) => lang.short === i18n.language)
  );
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    /**
     * Fetches the prompt library content of the user and the example prompt library content.
     * The example prompt library content is fetched based on the current language of the user.
     */
    const initializeContent = async () => {
      if (!userInfo?.userId) {
        console.error('Error fetching prompt library content: No userId found');
        captureException(
          'Error fetching prompt library content: No userId found'
        );
        return;
      }

      if (!currentLanguage) {
        console.error(
          'Error fetching prompt library content: No current language found'
        );
        captureException(
          'Error fetching prompt library content: No current language found'
        );
        return;
      }

      // Fetch users prompt library content
      const { data: yourCollectionsContent, error } =
        await getYourPromptLibraryContent(userInfo.userId, supabase);
      if (error) {
        console.error(error.message);
        captureException(error);
        return;
      }
      if (!yourCollectionsContent) {
        // The users prompt library can be empty, therefore this is not an error
        return;
      }
      setYourCollectionsContent(yourCollectionsContent);

      // Fetch example prompt library content
      const { data: exampleContent, error: exampleError } =
        await getExamplePromptLibraryContent(
          userInfo.userId,
          currentLanguage.short,
          supabase
        );

      if (exampleError) {
        console.error(exampleError.message);
        captureException(exampleError);
        return;
      }

      if (!exampleContent) {
        // There should always be example content, therefore log an error if there is none
        console.error('No data found for example prompt library content');
        captureException(
          new Error('No data found for example prompt library content')
        );
        return;
      }

      setExamplesContent(exampleContent);
    };

    initializeContent();
  }, []);

  /**
   * Handles the addition of a new collection to the user's content.
   * @param collection - The new collection that was added.
   */
  const handleUserContentCollectionAdded = (collection: PromptCollection) => {
    if (!yourCollectionsContent) return;

    setYourCollectionsContent({
      ...yourCollectionsContent,
      collections: [collection, ...yourCollectionsContent.collections],
    });
  };

  /**
   * Handle the deletion of a collection from the user's content.
   * Removes the collection from the user's content.
   * Removes the prompts from the deleted collection from the favorites and recently used lists.
   * @param collection - The collection that was deleted.
   */
  const handleUserContentCollectionDeleted = (collection: PromptCollection) => {
    if (!yourCollectionsContent) return;
    const updatedCollections = yourCollectionsContent.collections.filter(
      (c) => c.id !== collection.id
    );

    const updatedFavorites = yourCollectionsContent.favorites.filter(
      (p) => p.collectionId !== collection.id
    );

    const updatedRecentlyUsed = yourCollectionsContent.recentlyUsed.filter(
      (p) => p.collectionId !== collection.id
    );

    setYourCollectionsContent({
      ...yourCollectionsContent,
      favorites: updatedFavorites,
      recentlyUsed: updatedRecentlyUsed,
      collections: updatedCollections,
    });
  };

  /**
   * Handles the renaming of a collection in the user's content.
   * Updates the name of the renamed collection in the user's content.
   * @param collection
   * @param newName
   */
  const handleUserContentCollectionRenamed = (
    collection: PromptCollection,
    newName: string
  ) => {
    if (!yourCollectionsContent) return;
    const updatedCollections = yourCollectionsContent.collections
      .map((c) =>
        c.id === collection.id ? { ...collection, name: newName } : c
      )
      .sort((a: PromptCollection, b: PromptCollection) =>
        a.name.localeCompare(b.name)
      );
    setYourCollectionsContent({
      ...yourCollectionsContent,
      collections: updatedCollections,
    });
  };

  /**
   * Handles the addition of a new prompt to a collection in the user's content.
   * Adds the new prompt to the collection in the user's content.
   * @param prompt - The new prompt that was added.
   * @param collectionId - The id of the collection the prompt was added to.
   */
  const handleUserContentPromptAdded = (
    prompt: Prompt,
    collectionId: string
  ) => {
    if (!yourCollectionsContent) return;
    const updatedCollections = yourCollectionsContent.collections.map(
      (collection) => {
        if (collection.id === collectionId) {
          return {
            ...collection,
            prompts: [prompt, ...collection.prompts],
          };
        }
        return collection;
      }
    );
    setYourCollectionsContent({
      ...yourCollectionsContent,
      collections: updatedCollections,
    });
  };

  /**
   * Handles the deletion of a prompt from the user's content.
   * Removes the prompt from parent collection, favorites and recently used lists.
   * @param prompt - The prompt that was deleted.
   */
  const handleUserContentPromptDeleted = (prompt: Prompt) => {
    if (!yourCollectionsContent) return;

    const updatedCollections = yourCollectionsContent.collections.map(
      (collection) => ({
        ...collection,
        prompts: collection.prompts.filter((p) => p.id !== prompt.id),
      })
    );

    const updatedFavorites = yourCollectionsContent.favorites.filter(
      (p) => p.id !== prompt.id
    );

    const updatedRecentlyUsed = yourCollectionsContent.recentlyUsed.filter(
      (p) => p.id !== prompt.id
    );

    setYourCollectionsContent({
      ...yourCollectionsContent,
      collections: updatedCollections,
      favorites: updatedFavorites,
      recentlyUsed: updatedRecentlyUsed,
    });
  };

  /**
   * Handles the saving of a prompt in the user's content.
   * Updates the prompt in the parent collection, favorites and recently used lists.
   * @param prompt - The prompt to save.
   */
  const handleUserContentPromptSaved = (prompt: Prompt) => {
    if (!yourCollectionsContent) return;

    const updatedCollections = yourCollectionsContent.collections
      .map((collection) => {
        if (collection.id === prompt.collectionId) {
          return {
            ...collection,
            prompts: collection.prompts
              .map((p) => (p.id === prompt.id ? prompt : p))
              .sort((a, b) => a.name.localeCompare(b.name)),
          };
        }
        return collection;
      })
      .sort((a, b) => a.name.localeCompare(b.name));

    const updatedFavorites = yourCollectionsContent.favorites
      .map((p) => (p.id === prompt.id ? prompt : p))
      .sort((a, b) => a.name.localeCompare(b.name));

    const updatedRecentlyUsed = yourCollectionsContent.recentlyUsed
      .map((p) => (p.id === prompt.id ? prompt : p))
      .sort((a, b) => a.name.localeCompare(b.name));

    setYourCollectionsContent({
      ...yourCollectionsContent,
      collections: updatedCollections,
      favorites: updatedFavorites,
      recentlyUsed: updatedRecentlyUsed,
    });
  };

  const handleUserContentFavoriteChanged = (prompt: Prompt) => {
    if (!yourCollectionsContent) return;

    const updatedCollections = yourCollectionsContent.collections.map(
      (collection) => ({
        ...collection,
        prompts: collection.prompts.map((p) =>
          p.id === prompt.id ? prompt : p
        ),
      })
    );

    // If the prompt is not in favorites, add it, otherwise remove it from favorites
    const updatedFavorites = prompt.isFavorite
      ? [...yourCollectionsContent.favorites, prompt]
      : yourCollectionsContent.favorites.filter((p) => p.id !== prompt.id);

    setYourCollectionsContent({
      ...yourCollectionsContent,
      collections: updatedCollections,
      favorites: updatedFavorites,
    });
  };

  /**
   * Handles the addition of a new prompt to a collection in the example content.
   * Adds the new prompt to the collection in the example content.
   * @param prompt
   */
  const handleExampleContentFavoriteChanged = (prompt: Prompt) => {
    if (!examplesContent) return;

    const updatedCollections = examplesContent.collections.map(
      (collection) => ({
        ...collection,
        prompts: collection.prompts.map((p) =>
          p.id === prompt.id ? prompt : p
        ),
      })
    );

    // If the prompt is not in favorites, add it, otherwise remove it from favorites
    const updatedFavorites = prompt.isFavorite
      ? [...examplesContent.favorites, prompt]
      : examplesContent.favorites.filter((p) => p.id !== prompt.id);

    setExamplesContent({
      ...examplesContent,
      collections: updatedCollections,
      favorites: updatedFavorites,
    });
  };

  return (
    <Tabs size="md" variant="unstyled" className="flex h-full min-h-0 flex-col">
      <div className="flex items-center justify-between">
        <TabList>
          <Tab className="border-maia-border value-maia-value-dark ml-4 border-b-2 text-sm font-semibold">
            {t('promptLibrary.yourCollection')}
          </Tab>
          <Tab className="border-maia-border text-maia-text-dark border-b-2 text-sm font-semibold">
            {t('general.examples')}
          </Tab>
        </TabList>
        <div className="pr-4">
          <SearchBar onSearchTermChange={setSearchTerm} />
        </div>
      </div>
      <div className="relative">
        <TabIndicator className="bg-maia-accent -mt-0.5" height={'2px'} />
      </div>
      <TabPanels className="min-h-0 flex-grow">
        <TabPanel className="m-0 h-full min-h-0 p-0">
          <PromptNavigator
            content={yourCollectionsContent}
            searchTerm={searchTerm}
            onResetSearchTerm={() => setSearchTerm('')}
            onSelectedPromptChange={(prompt) =>
              onSelectedPromptChange(prompt, false)
            }
            user_id={userInfo?.userId}
            isEditable
            onCollectionAdded={(collection) => {
              handleUserContentCollectionAdded(collection);
            }}
            onPromptAdded={(prompt, collectionId) => {
              handleUserContentPromptAdded(prompt, collectionId);
            }}
            onPromptSaved={(prompt) => {
              handleUserContentPromptSaved(prompt);
            }}
            onPromptFavorite={(prompt) => {
              handleUserContentFavoriteChanged(prompt);
            }}
            onPromptDeleted={(prompt) => {
              handleUserContentPromptDeleted(prompt);
            }}
            onCollectionDeleted={(collection) => {
              handleUserContentCollectionDeleted(collection);
            }}
            onCollectionRenamed={(collection, newName) => {
              handleUserContentCollectionRenamed(collection, newName);
            }}
          />
        </TabPanel>
        <TabPanel className="m-0 h-full min-h-0 p-0">
          <PromptNavigator
            content={examplesContent}
            searchTerm={searchTerm}
            onResetSearchTerm={() => setSearchTerm('')}
            onSelectedPromptChange={(prompt) =>
              onSelectedPromptChange(prompt, true)
            }
            user_id={userInfo?.userId}
            isEditable={false}
            onPromptFavorite={(prompt) => {
              handleExampleContentFavoriteChanged(prompt);
            }}
          />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};
