import { Button, Divider, Textarea } from '@chakra-ui/react';
import {
  Prompt,
  PromptCollection,
  PromptLibraryContent,
} from '../promptLibraryTypes';
import {
  addPromptCollection,
  addPromptToCollection,
  deleteExamplePromptFavorite,
  deletePrompt,
  deletePromptCollection,
  deletePromptFavorite,
  insertExamplePromptFavorite,
  insertPromptFavorite,
  renamePrompt,
  renamePromptCollection,
  updatePromptValue,
} from '../promptLibraryRequests';
import { useEffect, useState } from 'react';

import ConfirmDeletePromptCollectionModal from './ConfirmDeletePromptCollectionModal';
import ConfirmDeletePromptModal from './ConfirmDeletePromptModal';
import { Database } from 'common-ts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ListItem from '../../../../components/ListItem';
import PromptCollectionListItem from './PromptCollectionListItem';
import PromptListItem from './PromptListItem';
import { SupabaseClient } from '@supabase/supabase-js';
import { UnsavedChangesModal } from './UnsavedChangesModal';
import { faClock } from '@fortawesome/pro-regular-svg-icons';
import { faStar } from '@fortawesome/pro-solid-svg-icons';
import { useBoundStore } from '../../../../store/useBoundStore';
import { useTranslation } from 'react-i18next';

type PromptNavigatorProps = {
  content: PromptLibraryContent | undefined;
  onSelectedPromptChange: (prompt: Prompt) => void;
  onCollectionAdded?: (promptCollection: PromptCollection) => void;
  onCollectionRenamed?: (
    promptCollection: PromptCollection,
    newName: string
  ) => void;
  onCollectionDeleted?: (promptCollection: PromptCollection) => void;
  onPromptAdded?: (prompt: Prompt, collectionId: string) => void;
  onPromptSaved?: (prompt: Prompt) => void;
  onPromptFavorite: (prompt: Prompt) => void;
  onPromptDeleted?: (prompt: Prompt) => void;
  user_id: string | undefined;
  isEditable: boolean;
  searchTerm: string;
  onResetSearchTerm: () => void;
};

/**
 * Component that allows the user to navigate the prompt collections.
 * This is shown on the left side of the prompt library modal.
 * @param collections - The prompt collections to be displayed.
 * @param selectedCollection - The currently selected collection. Used to highlight the selected collection.
 * @param onCollectionClick - Callback function when a collection is clicked.
 * @param onRenameSaved - Callback function when a collection is renamed.
 * @param onDeleteClick - Callback function when a collection is deleted.
 * @param collectionToBeInitialNamed - The collection that should be initially named. Used to trigger the initial naming state.
 * @param initialNamingDone - Callback function when the initial naming is done.
 * @param isEditable - Flag to determine if the prompt library is editable. If true, the user can rename and delete collections.
 * @param searchTerm - The search term used to filter the collections. If the collection name or any prompt name or value includes the search term, the collection is displayed.
 */
const CollectionSelector = ({
  collections,
  selectedCollection,
  onCollectionClick,
  onRenameSaved,
  onDeleteClick,
  collectionToBeInitialNamed,
  initialNamingDone,
  isEditable,
  searchTerm,
}: {
  collections: PromptCollection[];
  selectedCollection: PromptCollection | undefined;
  onCollectionClick: (collection: PromptCollection) => void;
  onRenameSaved: (collection: PromptCollection, newName: string) => void;
  onDeleteClick: (collection: PromptCollection) => void;
  collectionToBeInitialNamed?: PromptCollection;
  initialNamingDone: () => void;
  isEditable: boolean;
  searchTerm: string;
}) => {
  const normalizedSearchTerm = searchTerm.toLowerCase();

  // Filter collections based on the search term
  const filteredCollections = collections.filter((collection) => {
    // Check if the collection name includes the search term
    const collectionNameMatch = collection.name
      .toLowerCase()
      .includes(normalizedSearchTerm);

    // Check if any prompt name or value includes the search term
    const promptMatch = collection.prompts.some(
      (prompt) =>
        prompt.name.toLowerCase().includes(normalizedSearchTerm) ||
        prompt.value.toLowerCase().includes(normalizedSearchTerm)
    );

    return collectionNameMatch || promptMatch;
  });

  return (
    <div className="w-full flex-1 overflow-auto pt-4">
      {filteredCollections.map((collection) => (
        <PromptCollectionListItem
          key={collection.id}
          collection={collection}
          isSelected={selectedCollection?.id === collection.id}
          onClick={onCollectionClick}
          onRenameSaved={(promptCollection, newName) => {
            onRenameSaved(promptCollection, newName);
          }}
          onDeleteClick={(promptCollection) => {
            onDeleteClick(promptCollection);
          }}
          isInitialNaming={collection.id === collectionToBeInitialNamed?.id}
          onInitialNamingOver={initialNamingDone}
          isEditable={isEditable}
          searchTerm={searchTerm}
        />
      ))}
    </div>
  );
};

/**
 * Component that allows the user to navigate the prompts.
 * This is shown in the middle of the prompt library modal.
 * @param prompts - The prompts to be displayed.
 * @param selectedPrompt - The currently selected prompt. Used to highlight the selected prompt.
 * @param onPromptClick - Callback function when a prompt is clicked.
 * @param onFavoriteClick - Callback function when the favorite icon is clicked.
 * @param onRenameSaved - Callback function when a prompt is renamed.
 * @param onDeleteClick - Callback function when a prompt is deleted.
 * @param promptToBeInitialNamed - The prompt that should be initially named. Used to trigger the initial naming state.
 * @param initialNamingDone - Callback function when the initial naming is done.
 * @param isEditable - Flag to determine if the prompt library is editable. If true, the user can rename and delete prompts.
 * @param searchTerm - The search term to filter prompts by.
 */
const PromptSelector = ({
  prompts,
  selectedPrompt,
  onPromptClick,
  onFavoriteClick,
  onRenameSaved,
  onDeleteClick,
  promptToBeInitialNamed,
  initialNamingDone,
  isEditable,
  searchTerm,
}: {
  prompts: Prompt[];
  selectedPrompt: Prompt | undefined;
  onPromptClick: (prompt: Prompt) => void;
  onFavoriteClick: (prompt: Prompt) => void;
  onRenameSaved: (prompt: Prompt, newName: string) => void;
  onDeleteClick: (prompt: Prompt) => void;
  promptToBeInitialNamed?: Prompt;
  initialNamingDone: () => void;
  isEditable: boolean;
  searchTerm: string;
}) => {
  const normalizedSearchTerm = searchTerm.toLowerCase();

  const filteredPrompts = prompts.filter((prompt) => {
    return (
      prompt.name.toLowerCase().includes(normalizedSearchTerm) ||
      prompt.value.toLowerCase().includes(normalizedSearchTerm)
    );
  });

  return (
    <div className="flex-1">
      {filteredPrompts.map((prompt) => (
        <PromptListItem
          key={prompt.id}
          prompt={prompt}
          onRenameSaved={onRenameSaved}
          isSelected={selectedPrompt?.id === prompt.id}
          onClick={onPromptClick}
          onFavoriteClick={(prompt) => onFavoriteClick(prompt)}
          onDeleteClick={onDeleteClick}
          isInitialNaming={prompt.id === promptToBeInitialNamed?.id}
          isInitialNamingDone={initialNamingDone}
          isEditable={isEditable}
          searchTerm={searchTerm}
        />
      ))}
    </div>
  );
};

/**
 * Component that displays the prompt value in a textarea.
 * This is shown on the right side of the prompt library modal.
 * @param selectedPrompt - The currently selected prompt.
 * @param currentPromptValue - The current value of the prompt that is displayed in the textarea.
 * @param onValueChange - Callback function when the value of the prompt changes.
 * @param isEditingPrompt - Flag to determine if the prompt is being edited.
 */
const PromptValueArea = ({
  selectedPrompt,
  currentPromptValue,
  onValueChange,
  isEditingPrompt,
}: {
  selectedPrompt: Prompt | undefined;
  currentPromptValue: string;
  onValueChange: (value: string) => void;
  isEditingPrompt: boolean;
}) => {
  const { t } = useTranslation();
  return (
    selectedPrompt && (
      <div className="flex h-full flex-col gap-4">
        <div className="flex-1">
          <Textarea
            className="text-maia-text-dark h-full cursor-text text-sm"
            value={currentPromptValue}
            resize="none"
            textColor={isEditingPrompt ? 'blue' : 'maia-text-dark'}
            disabled={!isEditingPrompt}
            onChange={(e) => onValueChange(e.target.value)}
            placeholder={
              isEditingPrompt
                ? ''
                : t('promptLibrary.promptValueTextAreaPlaceholder')
            }
            sx={{
              '&:disabled': {
                opacity: '0.7',
              },
            }}
          />
        </div>
      </div>
    )
  );
};

/**
 * Component that displays the prompt navigator.
 * Displays a three-column layout with the prompt collections on the left, prompts in the middle, and the prompt value on the right.
 * Also contains action buttons to add collections and prompts as well as to save and discard changes if the library is editable.
 * This component is responsible for calling the appropriate API requests when the user interacts with the prompt collections and prompts.
 * It does not handle any state for the prompt library. This is handled by the parent component.
 * @param content - The prompt library content to be displayed. This contains of favorites, recently used, and collections with prompts.
 * @param onSelectedPromptChange - Callback function when the selected prompt changes.
 * @param user_id - The user id of the current user.
 * @param onCollectionAdded - Callback function when a collection is added.
 * @param onPromptAdded - Callback function when a prompt is added.
 * @param onPromptSaved - Callback function when a prompt is saved.
 * @param onPromptFavorite - Callback function when a prompt is favorited.
 * @param onPromptDeleted - Callback function when a prompt is deleted.
 * @param onCollectionRenamed - Callback function when a collection is renamed.
 * @param onCollectionDeleted - Callback function when a collection is deleted.
 * @param isEditable - Whether the navigator is editable. If true, the user can add, rename, and delete collections and prompts.
 * @param searchTerm - Search term for filtering prompts collections and prompts.
 * @param onResetSearchTerm - Callback function for resetting the search term.
 */
export const PromptNavigator = ({
  content,
  onSelectedPromptChange,
  user_id,
  onCollectionAdded,
  onPromptAdded,
  onPromptSaved,
  onPromptFavorite,
  onPromptDeleted,
  onCollectionRenamed,
  onCollectionDeleted,
  isEditable,
  searchTerm,
  onResetSearchTerm,
}: PromptNavigatorProps) => {
  const { t } = useTranslation();
  const supabase = useBoundStore((state) => state.supabase);
  const [selectedCollection, setSelectedCollection] = useState<
    PromptCollection | undefined
  >();
  const [promptList, setPromptList] = useState<Prompt[] | undefined>();
  const [selectedPrompt, setSelectedPrompt] = useState<Prompt | undefined>();
  const [currentPromptValue, setCurrentPromptValue] = useState<string>('');
  const [isFavoritesSelected, setIsFavoritesSelected] =
    useState<boolean>(false);
  const [isRecentlyUsedSelected, setIsRecentlyUsedSelected] =
    useState<boolean>(false);
  const [isInitNavigationDone, setIsInitNavigationDone] =
    useState<boolean>(false);
  const [isEditingPrompt, setIsEditingPrompt] = useState<boolean>(false);
  const [originalPromptValue, setOriginalPromptValue] = useState<string>('');
  const [isUnsavedChangesAlertOpen, setIsUnsavedChangesAlertOpen] =
    useState(false);
  const [promptToBeInitialNamed, setPromptToBeInitialNamed] = useState<
    Prompt | undefined
  >();
  const [
    promptCollectionToBeInitialNamed,
    setPromptCollectionToBeInitialNamed,
  ] = useState<PromptCollection | undefined>();
  const [
    isConfirmDeletePromptCollectionOpen,
    setIsConfirmDeletePromptCollectionOpen,
  ] = useState<boolean>(false);
  const [isConfirmDeletePromptOpen, setIsConfirmDeletePromptOpen] =
    useState<boolean>(false);

  useEffect(() => {
    const selectedFavoritesOnLoad = () => {
      if (content && !isInitNavigationDone) {
        handleFavoritesClick();
        setIsInitNavigationDone(true);
      }
    };

    selectedFavoritesOnLoad();
  }, [content]);

  useEffect(() => {
    if (content?.collections && selectedCollection) {
      const updatedCollection = content.collections.find(
        (collection) => collection.id === selectedCollection.id
      );
      if (updatedCollection) {
        setSelectedCollection(updatedCollection);
        setPromptList(updatedCollection.prompts);
      }
    }
  }, [content?.collections]);

  useEffect(() => {
    // If the favorite or recently used section is selected, update the prompt list of the selected section
    let prompts;
    if (isFavoritesSelected) {
      prompts = content?.favorites;
    } else if (isRecentlyUsedSelected) {
      prompts = content?.recentlyUsed;
    }

    if (prompts) {
      setPromptList(prompts);
      if (prompts[0]) {
        handlePromptClick(prompts[0]);
      } else {
        updateSelectedPrompt(undefined);
      }
    }
  }, [content?.favorites, content?.recentlyUsed]);

  /**
   * Updates the selected prompt.
   * Updates the display value of the prompt.
   * @param prompt - The prompt that is selected.
   */
  const updateSelectedPrompt = (prompt: Prompt | undefined) => {
    setSelectedPrompt(prompt);
    if (prompt) {
      setCurrentPromptValue(prompt.value);
      onSelectedPromptChange(prompt);
    } else {
      setCurrentPromptValue('');
    }
  };

  /**
   * Handles the click event when the favorites section is clicked.
   * Results in the favorites section being selected and the prompt list being updated with the favorites.
   */
  const handleFavoritesClick = () => {
    if (isFavoritesSelected || !content) return;
    if (shouldShowUnsavedChangesAlert()) {
      setIsUnsavedChangesAlertOpen(true);
      return;
    }
    setIsEditingPrompt(false);
    setIsFavoritesSelected(true);
    setIsRecentlyUsedSelected(false);
    updateSelectedPrompt(undefined);
    setSelectedCollection(undefined);
    setPromptList(content?.favorites);
    if (content.favorites[0]) {
      handlePromptClick(content.favorites[0]);
    }
  };

  /**
   * Handles the click event when the recently used section is clicked.
   * Results in the recently used section being selected and the prompt list being updated with the recently used prompts.
   */
  const handleRecentlyUsedClick = () => {
    if (isRecentlyUsedSelected || !content) return;
    if (shouldShowUnsavedChangesAlert()) {
      setIsUnsavedChangesAlertOpen(true);
      return;
    }
    setIsEditingPrompt(false);
    setIsFavoritesSelected(false);
    setIsRecentlyUsedSelected(true);
    updateSelectedPrompt(undefined);
    setSelectedCollection(undefined);
    setPromptList(content?.recentlyUsed);
    if (content.recentlyUsed[0]) {
      handlePromptClick(content.recentlyUsed[0]);
    }
  };

  /**
   * Handles the click event when a collection is clicked.
   * Results in the selected collection being updated and the prompt list being updated with the prompts of the selected collection.
   * @param collection
   */
  const handleCollectionClick = (collection: PromptCollection) => {
    if (selectedCollection?.id === collection.id) return;
    if (shouldShowUnsavedChangesAlert()) {
      setIsUnsavedChangesAlertOpen(true);
      return;
    }
    setIsEditingPrompt(false);
    setIsFavoritesSelected(false);
    setIsRecentlyUsedSelected(false);
    updateSelectedPrompt(undefined);
    setSelectedCollection(collection);
    setPromptList(collection.prompts);
    if (collection.prompts[0]) {
      handlePromptClick(collection.prompts[0]);
    }
  };

  /**
   * Handles the click event when a prompt is clicked.
   * Results in the selected prompt being updated and the prompt value being displayed.
   * @param prompt
   */
  const handlePromptClick = (prompt: Prompt) => {
    if (shouldShowUnsavedChangesAlert()) {
      setIsUnsavedChangesAlertOpen(true);
      return;
    }
    setIsEditingPrompt(false);
    updateSelectedPrompt(prompt);
    onSelectedPromptChange(prompt);
  };

  /**
   * Handles the click event when the add collection button is clicked.
   * Only adds a new collection if the user is allowed to edit the prompt library.
   * Calls the API to add a new collection and updates the prompt list with the new collection.
   */
  const handleAddCollection = async () => {
    if (!content || !user_id || !isEditable) return;
    if (shouldShowUnsavedChangesAlert()) {
      setIsUnsavedChangesAlertOpen(true);
      return;
    }
    onResetSearchTerm();
    setIsEditingPrompt(false);

    const { data, error } = await addPromptCollection(
      t('promptLibrary.newCollectionNameInitial'),
      user_id,
      supabase
    );
    if (error) {
      console.error('Error adding prompt collection:', error);
      return;
    }
    if (!data) {
      console.error('No data found for adding prompt collection');
      return;
    }

    onCollectionAdded?.(data);
    handleCollectionClick(data);
    setPromptCollectionToBeInitialNamed(data);
  };

  /**
   * Handles the click event when the add prompt button is clicked.
   * Only adds a new prompt if the user is allowed to edit the prompt library.
   * Calls the API to add a new prompt and updates the selected prompt with the new prompt.
   */
  const handleAddPrompt = async () => {
    if (!selectedCollection || !user_id || !content || !isEditable) return;
    if (shouldShowUnsavedChangesAlert()) {
      setIsUnsavedChangesAlertOpen(true);
      return;
    }
    onResetSearchTerm();
    setIsEditingPrompt(false);

    const { data, error } = await addPromptToCollection(
      selectedCollection.id,
      user_id,
      t('promptLibrary.newPromptNameInitial'),
      undefined,
      supabase
    );
    if (error) {
      console.error('Error adding prompt:', error);
      return;
    }
    if (!data) {
      console.error('No data found for adding prompt');
      return;
    }

    handlePromptClick(data);
    onSelectedPromptChange(data);
    onPromptAdded?.(data, selectedCollection.id);
    setPromptToBeInitialNamed(data);
  };

  /**
   * Handles the click event when the save button is clicked.
   * Only saves the prompt if the user is allowed to edit the prompt library.
   * Calls the API to save the prompt and updates the selected prompt with the new prompt.
   * @param prompt - The prompt to be saved.
   * @param currentPromptValue - The current value of the prompt.
   */
  const handleSavePrompt = async (
    prompt: Prompt,
    currentPromptValue: string
  ) => {
    if (!content || !currentPromptValue || !user_id || !isEditable) return;
    const { data, error } = await updatePromptValue(
      prompt,
      currentPromptValue,
      user_id,
      supabase
    );

    if (error) {
      console.error('Error saving prompt:', error);
      return;
    }

    if (!data) {
      console.error('No data found for saving prompt');
      return;
    }

    onPromptSaved?.(data);
    updateSelectedPrompt(data);
    setIsEditingPrompt(false);
    setIsUnsavedChangesAlertOpen(false);
  };

  /**
   * Handles discarding the changes made to the prompt.
   */
  const handleDiscardChanges = () => {
    setCurrentPromptValue(originalPromptValue);
    setOriginalPromptValue(originalPromptValue);
    setIsEditingPrompt(false);
    setIsUnsavedChangesAlertOpen(false);
  };

  /**
   * Determines if the unsaved changes alert should be shown.
   */
  const shouldShowUnsavedChangesAlert = (): boolean => {
    return (
      !isUnsavedChangesAlertOpen &&
      isEditingPrompt &&
      currentPromptValue !== originalPromptValue
    );
  };

  /**
   * Handles the click event when the favorite icon is clicked.
   * Calls the API to insert or delete the prompt favorite record.
   * @param prompt - The prompt to be favorited.
   */
  const handlePromptFavorite = async (
    prompt: Prompt,
    supabase: SupabaseClient<Database, 'public'>
  ) => {
    if (!content || !user_id) return;
    prompt.isFavorite = !prompt.isFavorite;
    const isExamplePromptLibrary = !isEditable;

    const toggleFavorite = async (
      insertFn: (
        id: string,
        userId: string,
        supabase: SupabaseClient<Database, 'public'>
      ) => Promise<{ error: Error | null }>,
      deleteFn: (
        id: string,
        userId: string,
        supabase: SupabaseClient<Database, 'public'>
      ) => Promise<{ error: Error | null }>
    ) => {
      if (prompt.isFavorite) {
        const { error } = await insertFn(prompt.id, user_id, supabase);
        if (error) {
          console.error('Error inserting prompt favorite:', error);
          return;
        }
      } else {
        const { error } = await deleteFn(prompt.id, user_id, supabase);
        if (error) {
          console.error('Error deleting prompt favorite:', error);
          return;
        }
      }
    };

    if (isExamplePromptLibrary) {
      await toggleFavorite(
        insertExamplePromptFavorite,
        deleteExamplePromptFavorite
      );
    } else {
      await toggleFavorite(insertPromptFavorite, deletePromptFavorite);
    }
    onPromptFavorite(prompt);
  };

  /**
   * Handles the click event when the rename prompt button is clicked.
   * Calls the API to rename the prompt.
   * @param prompt - The prompt to be renamed.
   * @param newName - The new name of the prompt.
   */
  const handleRenamePrompt = async (prompt: Prompt, newName: string) => {
    if (!user_id || !isEditable) return;
    prompt.name = newName;
    const { error } = await renamePrompt(prompt, newName, user_id, supabase);
    if (error) {
      console.error('Error renaming prompt:', error);
      return;
    }
    onPromptSaved?.(prompt);
  };

  /**
   * Handles the click event when the delete prompt button is clicked.
   * Calls the API to delete the prompt.
   * @param prompt - The prompt to be deleted.
   */
  const handleDeletePrompt = async (prompt: Prompt) => {
    if (!selectedPrompt || !user_id || !onPromptDeleted || !isEditable) return;

    const { error } = await deletePrompt(prompt, user_id, supabase);
    if (error) {
      console.error('Error deleting prompt:', error);
      return;
    }
    onPromptDeleted(selectedPrompt);
  };

  /**
   * Handles the click event when the rename collection button is clicked.
   * Calls the API to rename the collection.
   * @param collection - The collection to be renamed.
   * @param newName - The new name of the collection.
   */
  const handleRenameCollection = async (
    collection: PromptCollection,
    newName: string
  ) => {
    if (!user_id || !onCollectionRenamed || !isEditable) return;
    collection.name = newName;
    const { error } = await renamePromptCollection(
      collection,
      newName,
      user_id,
      supabase
    );
    if (error) {
      console.error('Error renaming collection:', error);
      return;
    }
    onCollectionRenamed?.(collection, newName);
  };

  /**
   * Handles the click event when the delete collection button is clicked.
   * Calls the API to delete the collection.
   * @param collection - The collection to be deleted.
   */
  const handleDeleteCollection = async (collection: PromptCollection) => {
    if (!user_id || !onCollectionDeleted || !isEditable) return;

    const { error } = await deletePromptCollection(
      collection,
      user_id,
      supabase
    );
    if (error) {
      console.error('Error deleting collection:', error);
      return;
    }

    onCollectionDeleted(collection);
    setSelectedCollection(undefined);
    updateSelectedPrompt(undefined);
    handleFavoritesClick();
  };

  return (
    <div className="border-maia-border grid h-full flex-grow grid-cols-3 border-y ">
      <div className="border-maia-border col-span-1 flex h-full min-h-0 flex-col gap-4 border-r">
        <div className="flex h-full flex-col items-start p-4">
          <ListItem
            className="w-full"
            variant="left"
            selected={isFavoritesSelected}
            domElementProps={{
              onClick: () => {
                handleFavoritesClick();
              },
            }}
          >
            <div className="flex h-9 w-full items-center justify-start">
              <FontAwesomeIcon
                icon={faStar}
                className="text-maia-support-yellow mr-2"
              />
              {t('general.favorites')}
            </div>
          </ListItem>
          <ListItem
            className="w-full"
            variant="left"
            selected={isRecentlyUsedSelected}
            domElementProps={{
              onClick: () => {
                handleRecentlyUsedClick();
              },
            }}
          >
            <div className="flex h-9 w-full items-center justify-start">
              <FontAwesomeIcon
                icon={faClock}
                className="text-maia-text-dark mr-2"
              />
              {t('general.recentlyUsed')}
            </div>
          </ListItem>
          <Divider className="w-full py-2" />
          <div className="flex h-full w-full flex-col gap-4 overflow-y-auto">
            {content?.collections && (
              <CollectionSelector
                collections={content.collections}
                selectedCollection={selectedCollection}
                onCollectionClick={handleCollectionClick}
                isEditable={isEditable}
                onRenameSaved={(collection, newName) => {
                  handleRenameCollection(collection, newName);
                }}
                onDeleteClick={(collection) => {
                  setSelectedCollection(collection);
                  setIsConfirmDeletePromptCollectionOpen(true);
                }}
                collectionToBeInitialNamed={promptCollectionToBeInitialNamed}
                initialNamingDone={() =>
                  setPromptCollectionToBeInitialNamed(undefined)
                }
                searchTerm={searchTerm}
              />
            )}
            {isEditable ? (
              <Button
                className="mt-auto w-full flex-shrink-0"
                variant="outline"
                onClick={() => handleAddCollection()}
              >
                {t('promptLibrary.addCollection')}
              </Button>
            ) : (
              <div className="self-center text-xs">
                {t('promptLibrary.moreToCome')}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="border-maia-border col-span-1 flex h-full min-h-0 flex-col gap-4 border-r p-4">
        <div className="flex flex-col overflow-y-auto">
          {promptList && (
            <PromptSelector
              prompts={promptList}
              selectedPrompt={selectedPrompt}
              onPromptClick={handlePromptClick}
              isEditable={isEditable}
              onFavoriteClick={(prompt) => {
                handlePromptFavorite(prompt, supabase);
              }}
              onRenameSaved={(prompt, newName) => {
                handleRenamePrompt(prompt, newName);
                prompt.name = newName;
              }}
              onDeleteClick={(prompt) => {
                setSelectedPrompt(prompt);
                setIsConfirmDeletePromptOpen(true);
              }}
              promptToBeInitialNamed={promptToBeInitialNamed}
              initialNamingDone={() => setPromptToBeInitialNamed(undefined)}
              searchTerm={searchTerm}
            />
          )}
        </div>
        {selectedCollection && isEditable && (
          <Button
            className="mt-auto w-full flex-shrink-0"
            variant="outline"
            onClick={() => handleAddPrompt()}
          >
            {t('promptLibrary.addPrompt')}
          </Button>
        )}
      </div>
      <div className="col-span-1 gap-4 p-4">
        {selectedPrompt && (
          <div className="flex h-full flex-col gap-4">
            <PromptValueArea
              selectedPrompt={selectedPrompt}
              currentPromptValue={currentPromptValue}
              onValueChange={(value) => setCurrentPromptValue(value)}
              isEditingPrompt={isEditingPrompt}
            />
            <div className="flex flex-row gap-4">
              {!isEditingPrompt && (
                <Button
                  className="w-full"
                  variant="outline"
                  onClick={(event) => {
                    navigator.clipboard.writeText(currentPromptValue);
                    const b = event.target as HTMLButtonElement;
                    b.textContent = t('general.copied');
                    setTimeout(() => {
                      b.textContent = t('general.copy');
                    }, 1000);
                  }}
                >
                  {t('general.copy')}
                </Button>
              )}
              {isEditingPrompt && isEditingPrompt && (
                <Button
                  className="w-full"
                  variant="outline"
                  onClick={() => {
                    handleDiscardChanges();
                  }}
                >
                  {t('general.cancelButton')}
                </Button>
              )}
              {isEditable && (
                <Button
                  className="${isEditingPrompt ? 'font-semibold' : ''} w-full"
                  variant={isEditingPrompt ? 'solid' : 'outline'}
                  colorScheme={isEditingPrompt ? 'maia-purple' : 'gray'}
                  onClick={() => {
                    if (isEditingPrompt) {
                      handleSavePrompt(selectedPrompt, currentPromptValue);
                    }
                    setOriginalPromptValue(currentPromptValue);
                    setIsEditingPrompt(!isEditingPrompt);
                  }}
                >
                  {isEditingPrompt
                    ? t('general.saveButtons')
                    : t('general.edit')}
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
      <UnsavedChangesModal
        isOpen={isUnsavedChangesAlertOpen}
        onClose={() => setIsUnsavedChangesAlertOpen(false)}
        onDiscard={handleDiscardChanges}
        onSave={() => {
          if (selectedPrompt) {
            handleSavePrompt(selectedPrompt, currentPromptValue);
          }
        }}
      />
      <ConfirmDeletePromptCollectionModal
        isOpen={isConfirmDeletePromptCollectionOpen}
        onClose={() => {
          setIsConfirmDeletePromptCollectionOpen(false);
        }}
        onConfirm={() => {
          if (selectedCollection) {
            handleDeleteCollection(selectedCollection);
            setIsConfirmDeletePromptCollectionOpen(false);
          }
        }}
      />
      <ConfirmDeletePromptModal
        isOpen={isConfirmDeletePromptOpen}
        onClose={() => {
          setIsConfirmDeletePromptOpen(false);
        }}
        onConfirm={() => {
          if (selectedPrompt) {
            handleDeletePrompt(selectedPrompt);
            setIsConfirmDeletePromptOpen(false);
          }
        }}
      />
    </div>
  );
};
