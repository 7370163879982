import {
  Button,
  Divider,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  PinInput,
  PinInputField,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { fetchApi } from '../../../../utils/useApi';
import { useEffect, useRef, useState } from 'react';
import ResendEmail2FA from '../ResendEmail2FA';
import { useToastManagerHook } from '../../../../general/useToastManagerHook';
import { useBoundStore } from '../../../../store/useBoundStore.js';

type EmailVerificationModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export default function EmailVerificationModal({
  isOpen,
  onClose,
}: EmailVerificationModalProps) {
  const { t } = useTranslation();
  const supabase = useBoundStore((state) => state.supabase);
  const { showToast } = useToastManagerHook();

  const confirmButtonRef = useRef<HTMLButtonElement>(null);

  const [challengeId, setChallengeId] = useState<string>('');
  const [code, setCode] = useState<string>('');

  const setup2FA = async () => {
    // enroll in 2FA
    const { success, data } = await fetchApi(
      supabase,
      '/auth',
      '/email/enroll',
      {
        method: 'POST',
      }
    );
    if (success) {
      setChallengeId(data.challengeId);
    } else {
      showToast({
        title: t('userSettings.security.generalError'),
        status: 'error',
      });
    }
    return success;
  };

  const handle2FASetupConfirmation = async () => {
    if (!challengeId) return;

    // verify 2FA
    const res = await fetchApi(supabase, '/auth', '/email/enroll_verify', {
      method: 'POST',
      challengeId,
      otp: code,
    });
    if (res.success) {
      showToast({
        title: t('userSettings.security.emailVerificationModal.success'),
        status: 'success',
      });
      onClose();
    } else if (res.status === 400 && res.data?.error) {
      if (res.data.error === 'Invalid OTP') {
        showToast({
          title: t(
            'userSettings.security.emailVerificationModal.invalidOtpError'
          ),
          status: 'error',
        });
      } else if (res.data.error === 'OTP Expired') {
        showToast({
          title: t(
            'userSettings.security.emailVerificationModal.otpExpiredError'
          ),
          status: 'error',
        });
      }
    } else {
      showToast({
        title: t('userSettings.security.generalError'),
        status: 'error',
      });
    }
  };

  const sendNewOtp = async () => {
    const res = await setup2FA();
    res
      ? showToast({
          title: t(
            'userSettings.security.emailVerificationModal.resendCodeSuccess'
          ),
          status: 'success',
        })
      : showToast({
          title: t('userSettings.security.generalError'),
          status: 'error',
        });
  };

  useEffect(() => {
    setup2FA();

    // workaround for pin input enter keypress
    // pin input does not have a onKeyPress/up/down event
    const handleEnterKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && confirmButtonRef.current) {
        confirmButtonRef.current.click();
      }
    };
    document.addEventListener('keypress', handleEnterKeyPress);
    return () => {
      document.removeEventListener('keypress', handleEnterKeyPress);
    };
  }, []);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <div className="font-medium">
            {t('userSettings.security.emailVerificationModal.title')}
          </div>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody className="flex w-full flex-col items-center gap-y-4 pb-12">
          <div className="mb-4">
            {t('userSettings.security.emailVerificationModal.subtitle')}
          </div>
          <div className="flex w-full flex-col items-center gap-y-2">
            <span className="font-medium">
              {t(
                'userSettings.security.emailVerificationModal.enterAuthenticationCodePrompt'
              )}
            </span>
            <HStack>
              <PinInput
                variant={'flushed'}
                focusBorderColor="maia-purple.500"
                onChange={(code) => {
                  setCode(code);
                }}
                otp
                autoFocus
              >
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
              </PinInput>
            </HStack>
            <ResendEmail2FA sendNewOtp={sendNewOtp} />
          </div>
        </ModalBody>
        <Divider />
        <ModalFooter gap={2}>
          <Button
            size={'sm'}
            className="font-medium"
            variant="outline"
            onClick={onClose}
          >
            {t('userSettings.security.emailVerificationModal.secondaryButton')}
          </Button>
          <Button
            ref={confirmButtonRef}
            colorScheme="maia-purple"
            size={'sm'}
            onClick={handle2FASetupConfirmation}
            isDisabled={code.length < 6}
          >
            {t('userSettings.security.emailVerificationModal.primaryButton')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
